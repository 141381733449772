export const Casualsms = (templateNumber, { user, selectedOption }) => {
  switch (templateNumber) {
    case 1:
      return `
                Hi there! Loving your new ${selectedOption?.label}? Let us know with a quick review! It only takes a minute: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. Thanks! 🌟
            `;
    case 2:
      return `
                We hope your ${selectedOption?.label} is treating you well! 👍 Leave us a review and share your thoughts: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. Cheers!
            `;
    case 3:
      return `
                Hey! Got a sec? We'd love to hear how ${selectedOption?.label} is working for you! Share your experience here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. Thanks a bunch! 🙌
            `;
    case 4:
      return `
                Hi! Is ${selectedOption?.label} living up to your expectations? Spill the tea in a review! ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. We’re all ears! 👂
            `;
    case 5:
      return `
                Hey there! Help us help you! 😎 Leave a review on ${selectedOption?.label} and share the love: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}.
            `;
    case 6:
      return `
                Yay or nay? 🎢 We want to know how your recent purchase is treating you! Share a quick video review on ${selectedOption?.label} here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. 🎤
            `;
    default:
      return '';
  }
};
