import React, { useEffect, useState } from "react";
import Sentiment from "./insighttabs/Sentiment";
import Transcript from "./insighttabs/Transcript";
import Summary from "./insighttabs/Summary";

const Videoinsights = ({
  obj,
  setSummary,
  summary,
  sentimentreviews,
  setsentimentReview,
  transcript,
  setTranscript,
  showTranslation,
  setShowTranslation,
  handleTranslateClick,
}) => {
  const [tab, setTab] = useState("Sentiment");
  useEffect(() => {
    if (tab === "Sentiment") {
      setsentimentReview(true);
      setTranscript(false);
      setSummary(false);
    } else if (tab === "Transcript") {
      setTranscript(true);
      setsentimentReview(false);
      setSummary(false);
    } else if (tab === "summary") {
      setSummary(true);
      setsentimentReview(false);
      setTranscript(false);
    } else {
      setsentimentReview(false);
      setTranscript(false);
    }
  }, [tab]);
  return (
    <div>
      {" "}
      <div className="d-flex ">
        <ul
          className="nav nav-tabs insight"
          id="responsive-tabs-ssv"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ms-3 ps-0 ${
                tab === "Sentiment" ? "active" : ""
              }`}
              id="Sentiment-tab"
              data-bs-toggle="tab"
              data-bs-target="#Sentiment"
              type="button"
              role="tab"
              aria-controls="Sentiment"
              aria-selected={tab === "Sentiment"}
              onClick={() => setTab("Sentiment")}
            >
              Sentiment overview
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ms-3 ps-0 ${
                tab === "Transcript" ? "active" : ""
              }`}
              id="Transcript-tab"
              data-bs-toggle="tab"
              data-bs-target="#Transcript"
              type="button"
              role="tab"
              aria-controls="Transcript"
              aria-selected={tab === "Transcript"}
              onClick={() => setTab("Transcript")}
            >
              Transcript
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${tab === "summary" ? "active" : ""}`}
              id="summary-tab"
              data-bs-toggle="tab"
              data-bs-target="#summary"
              type="button"
              role="tab"
              aria-controls="summary"
              aria-selected={tab === "summary"}
              onClick={() => setTab("summary")}
            >
              Video summary
            </button>
          </li>
        </ul>
      </div>
      <div className="tab-content p-2 ms-1" id="responsive-tabs-content">
        {tab === "Sentiment" && (
          <div
            className="tab-pane fade show active h-100"
            id="Sentiment"
            role="tabpanel"
            aria-labelledby="Sentiment-tab"
          >
            <Sentiment
              obj={obj}
              showTranslation={showTranslation}
              setShowTranslation={setShowTranslation}
              handleTranslateClick={handleTranslateClick}
            />
          </div>
        )}
        {tab === "Transcript" && (
          <div
            className="tab-pane fade show active h-100"
            id="Transcript"
            role="tabpanel"
            aria-labelledby="Transcript-tab"
          >
            <Transcript
              showTranslation={showTranslation}
              setShowTranslation={setShowTranslation}
              handleTranslateClick={handleTranslateClick}
              obj={obj}
            />
          </div>
        )}
        {tab === "summary" && (
          <div
            className="tab-pane fade show active h-100"
            id="summary"
            role="tabpanel"
            aria-labelledby="summary-tab"
          >
            <Summary
              obj={obj}
              showTranslation={showTranslation}
              setShowTranslation={setShowTranslation}
              handleTranslateClick={handleTranslateClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Videoinsights;
