import React, { useState, useEffect } from 'react';
import img1 from '../../assets/images/carousal.png';
import img2 from '../../assets/images/pip.png';
import './VideoPlaylist.css';
const CreatePlaylist = ({
  title,
  disabled,
  nextStep,
  setTitle,
  layout,
  setLayout,
  orientation,
  setOrientation,
  setSelectedVideos,
}) => {
  // const [title, setTitle] = useState('');
  // const [layout, setLayout] = useState('layout1');

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 px-2 pe-4">
          <label className="mt-3 label-text">Playlist Title</label>
          <br />
          <input
            type="text"
            className="mt-2  d-flex form-control"
            placeholder="Enter title"
            name="playlistTitle"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label className="mt-3 label-text">Playlist orientation</label>
          <br />
          <div className="d-flex mt-2">
            <div className="orientation form-check me-3">
              <input
                type="radio"
                className="orientation form-check-input"
                name="orientationStyle"
                id="Portrait"
                value="Portrait"
                checked={orientation === 'Portrait'}
                onChange={(e) => {
                  setOrientation(e.target.value);
                  setSelectedVideos([]);
                }}
              />
              <label
                className="form-check-label mt-1 ms-1"
                htmlFor="Portrait"
                style={{
                  color: orientation === 'Portrait' ? '' : 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Portrait
              </label>
            </div>

            <div className="orientation form-check me-3">
              <input
                type="radio"
                className="orientation form-check-input"
                name="orientationStyle"
                id="Landscape"
                value="Landscape"
                checked={orientation === 'Landscape'}
                onChange={(e) => {
                  setOrientation(e.target.value);
                  setSelectedVideos([]);
                }}
              />
              <label
                className="form-check-label orientation mt-1 ms-1"
                htmlFor="Landscape"
                style={{
                  color:
                    orientation === 'Landscape' ? '' : 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Landscape
              </label>
            </div>
          </div>
          <label className="mt-3 label-text">Layout Style</label>
          <br />
          <div className="d-flex mt-2">
            <div className="form-check me-3">
              <input
                type="radio"
                className="layout form-check-input"
                name="layoutStyle"
                id="layout1"
                value="layout1"
                checked={layout === 'layout1'}
                onChange={(e) => setLayout(e.target.value)}
              />
              <label
                className="form-check-label mt-1 ms-1"
                htmlFor="layout1"
                style={{
                  color: layout === 'layout1' ? '' : 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Carousel
              </label>
            </div>
            {/* <div className='form-check'>
          <input
            type='radio'
            className='form-check-input'
            name='layoutStyle'
            id='layout2'
            value='layout2'
            checked={layout === 'layout2'}
            onChange={(e) => setLayout(e.target.value)}
          disabled/>
          <label className='form-check-label' htmlFor='layout2'>Story</label>
        </div> */}
            <div className="form-check">
              <input
                type="radio"
                className="layout form-check-input"
                name="layoutStyle"
                id="layout3"
                value="layout3"
                checked={layout === 'layout3'}
                onChange={(e) => setLayout(e.target.value)}
              />
              <label
                className="form-check-label mt-1"
                htmlFor="layout3"
                style={{
                  color: layout === 'layout3' ? '' : 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Picture in Picture
              </label>
            </div>
            {/* <div className='form-check'>
          <input
            type='radio'
            className='form-check-input'
            name='layoutStyle'
            id='layout4'
            value='layout4'
            checked={layout === 'layout4'}
            onChange={(e) => setLayout(e.target.value)}
          disabled/>
          <label className='form-check-label' htmlFor='layout4'>Floating</label>
        </div>
        <div className='form-check'>
          <input
            type='radio'
            className='form-check-input'
            name='layoutStyle'
            id='layout5'
            value='layout5'
            checked={layout === 'layout5'}
            onChange={(e) => setLayout(e.target.value)}
            disabled
          />
          <label className='form-check-label' htmlFor='layout5'>Grid</label> */}
            {/* </div> */}
          </div>
          <button
            type="button"
            className="btn btn-dark"
            style={{ marginTop: '25px' }}
            disabled={disabled}
            onClick={nextStep}
          >
            Next
          </button>
        </div>
        <div
          className="col-6 d-flex justify-content-center align-items-center py-5"
          style={{
            backgroundColor: '#F8F8FF',
            borderRadius: '10px',
            marginTop: '16px',
          }}
        >
          {layout === 'layout1' && (
            <img src={img1} alt="Image 1" style={{ maxHeight: '250px' }} />
          )}
          {layout === 'layout3' && (
            <img src={img2} alt="Image 2" style={{ maxHeight: '250px' }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatePlaylist;
