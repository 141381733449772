export const ProfessionalTemplate = (
  templateNumber,
  { user, selectedOption }
) => {
  switch (templateNumber) {
    case 1:
      return `
                Subject: Request for Your ${selectedOption?.label} Video Testimonial<br /><br />
                Dear Customer <br /><br />
                We wanted to express our sincere appreciation for your choice to work with us and for making us a part of your home entertainment experience. Your feedback matters significantly to us, and we value your insights.<br /><br />
                As part of our commitment to excellence, we are actively collecting video testimonials from our esteemed customers. Your genuine feedback will greatly assist potential buyers in making informed decisions.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you for being part of our family. We look forward to hearing from you soon!<br /><br />
                Best regards,<br />
                [Your Full Name] [Your Title]<br />
                The ${user?.brand_name} Team
            `;

    case 2:
      return `
                Subject: Share Your ${selectedOption?.label} Experience: Video UGC Request<br /><br />
                Hi Customer,<br /><br />
                As a proud <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a> owner, your opinion matters to us! We’d love to hear about your experience with your <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label} </a>. Would you be willing to create a short video review?
                 It’s simple and won’t take more than a few minutes.<br /><br />
                Submit your review here:  ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you for being part of our family!<br /><br />
                Warm regards,<br />
                [Your Name] [Your Title]<br />
                The ${user?.brand_name} Team
            `;

    case 3:
      return `
                Subject: Your ${selectedOption?.label} Review<br /><br />
                Dear Customer,<br /><br />
                We hope you're enjoying your new <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a><br /><br />
                To help us improve our products and services, we'd love to hear about your experience. Could you please take a few minutes to share a brief video review of <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a><br /><br />
                Your feedback is invaluable to us.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you,<br />
                The ${user?.brand_name} Team
            `;

    case 4:
      return `
                Subject: Your ${selectedOption?.label} Experience - We Value Your Feedback<br /><br />
                Dear Customer,<br /><br />
                We hope your <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a> is exceeding your expectations.<br /><br />
                To enhance our product offerings and customer satisfaction, we're inviting you to share your thoughts through a brief video review.<br /><br />
                Your insights will help us continue to provide exceptional viewing experiences.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you for your time and consideration.<br /><br />
                Sincerely,<br />
                The ${user?.brand_name} Team
            `;

    case 5:
      return `
                Subject: Share Your ${selectedOption?.label}Experience with Us!<br /><br />
                Dear Customer ,<br /><br />
                We hope you're enjoying your<a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a>! We'd love to hear about your experience with it. Your feedback helps us improve and create better products for you and others.<br /><br />
                Could you spare a few minutes to record a short video review sharing your thoughts on your ${selectedOption?.label}? We're interested in knowing what you like, dislike, and any features you find particularly useful.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you for your time and feedback! Your input is invaluable to us.<br /><br />
                Best regards,<br />
                [Your Name]<br />
                The ${user?.brand_name} Team
            `;

    case 6:
      return `
                Subject: Share Your ${selectedOption?.label} Experience with a Video Testimonial<br /><br />
                Dear customer,<br /><br />
                We hope this message finds you well! Thank you for choosing us and incorporating our product into your home entertainment setup. Your feedback is incredibly important to us and helps us continue to improve our offerings.<br /><br />
                To support others in making informed decisions, we are gathering video testimonials from our valued customers. We would be grateful if you could share your honest experience with our product.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                We appreciate your support and look forward to hearing your thoughts!<br /><br />
                Best regards,<br />
                [Your Full Name]<br />
                [Your Title]<br />
                The ${user?.brand_name} Team
            `;

    case 7:
      return `
                Subject: We’d Love Your  ${selectedOption?.label} Feedback: Quick Video UGC Request<br /><br />
                Hi customer,<br /><br />
                We hope you’re enjoying your <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a>! Your feedback is important to us, and we’d be thrilled if you could share your experience through a short video review. It’s quick and easy, and will only take a few minutes.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you for being a valued member of our community!<br /><br />
                Warm regards,<br />
                [Your Name]<br />
                [Your Title]<br />
                The ${user?.brand_name} Team
            `;

    case 8:
      return `
                Subject: Share Your Thoughts on Your  ${selectedOption?.label}<br /><br />
                Dear customer,<br /><br />
                We hope you're having a great time with your new <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a>!<br /><br />
                We’d greatly appreciate it if you could take a few moments to share a short video review of your experience. Your feedback will help us enhance our products and services.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you for your support!<br /><br />
                Best regards,<br />
                The ${user?.brand_name} Team
            `;

    case 9:
      return `
                Subject: We’d Love Your Feedback on Your  ${selectedOption?.label}<br /><br />
                Dear customer,<br /><br />
                We trust that your <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a> is delivering an exceptional experience!<br /><br />
                To help us improve and ensure we continue to meet your expectations, we invite you to share your thoughts in a short video review. Your feedback is essential in helping us provide outstanding products and services.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you for your valuable time and input.<br /><br />
                Sincerely,<br />
                The ${user?.brand_name} Team
            `;

    case 10:
      return `
                Subject: We’d Love to Hear About Your  ${selectedOption?.label} Experience!<br /><br />
                Dear customer,<br /><br />
                We hope you’re enjoying your <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}"> ${selectedOption?.label} !</a> We’re eager to hear about your experience and how it's performing for you. Your feedback is crucial in helping us enhance our products and services.<br /><br />
                Could you take a few minutes to record a brief video review? We’d love to know what you enjoy, any challenges you’ve faced, and which features you find most valuable.<br /><br />
                Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}<br /><br />
                Thank you for your time and valuable insights!<br /><br />
                Best regards,<br />
                [Your Name]<br />
                The ${user?.brand_name} Team
            `;
  }
};
