import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalContactSwirl = ({ show, handleClose, handleConfirm }) => {
  if (!show) return null;

  return (
    <Modal
      show={show}
      className="d-grid modal_video"
      size="lg"
      onHide={handleClose}
    >
      <Modal.Body className="p-3 pt-4 pb-2">
        <p>Please, Contact Swirl for Sub Domain</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-actions w-100 text-center">
          <button className="btn btn-dark" onClick={handleConfirm}>
            OK
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalContactSwirl;
