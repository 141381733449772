import React, { useEffect, useRef, useState } from "react";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import Reviewdetails from "./Reviewdetails";
import Videoinsights from "./Videoinsights";
import {
  FaCaretDown,
  FaPause,
  FaPlay,
  FaRegStar,
  FaStar,
} from "react-icons/fa";
import { TbStarOff } from "react-icons/tb";
import axios from "axios";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { pdpApiInstance } from "../../axiosInstances";

const CustomRating = ({ value, onChange, precision }) => {
  const handleClick = (index) => {
    if (onChange) onChange(index + 1);
  };

  const stars = [];
  for (let i = 0; i < 5; i++) {
    const isFilled = i < value;
    stars.push(
      <span
        key={i}
        onClick={() => handleClick(i)}
        style={{ cursor: "pointer", fontSize: "24px", verticalAlign: "middle" }}
      >
        {isFilled ? (
          // <FaStar  />
          <i
            className="ri-star-fill"
            style={{ color: "rgba(11, 137, 92, 1)" }}
          ></i>
        ) : (
          // <FaRegStar color="gray" />
          <i className="ri-star-line" style={{ color: "grey" }}></i>
        )}
      </span>
    );
  }

  return <div>{stars}</div>;
};

const Moderatemodal = ({
  setReviewVideos,
  reviewVideos,
  show,
  handleClose,
  videoUrl,
  el,
  fetchNotReviewedVideosCOunr,
}) => {
  const modalRef = useRef(null);
  const [rating, setRating] = useState(3);
  const [tab, setTab] = useState("Details");
  const [isClosing, setIsClosing] = useState(false);
  const [sentimentreview, setsentimentReview] = useState(false);
  const [transcript, setTranscript] = useState(false);
  const [summary, setSummary] = useState(false);
  const [fetchedVideoData, setFetchedVideoData] = useState({});
  const [play, setPlay] = useState(false);
  const colorPickereRef = useRef(null);
  const colorPickereRef2 = useRef(null);
  const [status, setStatus] = useState("");
  const handleStatusUpdate = async (id, status2) => {
    let st;

    if (status === "approved" && status2 === "published") {
      st = "publish";
    } else if (status === "approved" && status2 === "rejected") {
      st = "reject";
    } else if (status === "notreviewed" && status2 === "rejected") {
      st = "reject";
    } else if (status === "notreviewed" && status2 === "approved") {
      st = "approve";
    } else if (status === "notreviewed" && status2 === "published") {
      st = "approve and publish";
    } else if (status === "published" && status2 === "notreviewed") {
      st = "unpublish";
    }
    confirmAlert({
      message: `Are you sure you want to ${st} this Video UGC?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await pdpApiInstance
                .put(
                  `/api/moderator/video/${id}`,
                  {
                    status: status2,
                    isDeleted: false,
                  },
                  {
                    headers: {
                      Authorization: localStorage.getItem("token")
                        ? localStorage.getItem("token")
                        : "",
                    },
                  }
                )
                .then((res) => {
                  if (res.data) {
                    toast.success("Status updated successfully!");
                    setStatus(status2);
                    fetchNotReviewedVideosCOunr();
                  }
                })
                .catch((err) => {
                  toast.error("Something went wrong!");
                });

              // alert(`Video status updated to: ${status}`);
            } catch (error) {
              console.error("Error updating video status:", error);
              toast.error("Something went wrong!");
            }
          },
        },
        { label: "No", onClick: () => { } },
      ],
    });
  };

  // useEffect(() => {
  //     handleStatusUpdate()
  // }, [show])
  const [showTranslation, setShowTranslation] = useState(false);

  // Handler for click event on badge
  const handleTranslateClick = () => {
    setShowTranslation((prevState) => !prevState);
  };
  useEffect(() => {
    if (tab === "Details") {
      setsentimentReview(false);
      setTranscript(false);
      setSummary(false);
    }
  }, [tab]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (show) {
        modalElement.classList.add("slide-down");
        modalElement.classList.remove("slide-up");
      } else {
        modalElement.classList.add("slide-up");
        modalElement.classList.remove("slide-down");
      }
    }
  }, [show, handleClose]);

  // useEffect(() => {
  //     const handleClickOutside = (event) => {
  //         // Check for the first modal
  //         if (modalRef.current && !modalRef.current.contains(event.target)) {
  //             handleCloseed();
  //         }

  //         // Check for the color picker logic
  //         if (colorPickereRef.current && colorPickereRef2.current) {
  //             if (colorPickereRef.current.contains(event.target) && !colorPickereRef2.current.contains(event.target)) {
  //                 alert("sfdfs");
  //                 handleCloseed();
  //             }
  //         }
  //     };

  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //         document.removeEventListener('mousedown', handleClickOutside);
  //     };
  // }, []);

  const handleCloseed = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.classList.add("slide-up");

      setTimeout(() => {
        setIsClosing(false);
        handleClose();
      }, 500);
    }
  };
  const updateReviewVideoById = (updatedVideo) => {
    setReviewVideos((prevVideos) =>
      prevVideos.map((video) =>
        video.id === updatedVideo.id ? { ...video, ...updatedVideo } : video
      )
    );
  };

  const getVideoDataByVideoIdAndBrandId = async (id) => {
    try {
      await pdpApiInstance
        .get(`/api/moderator/video/${id}`, {
          headers: {
            Authorization: localStorage.getItem("token")
              ? localStorage.getItem("token")
              : "",
          },
        })
        .then((res) => {
          if (res.data) {
            setFetchedVideoData(res.data.data);
            setStatus(res.data.data.status);
            updateReviewVideoById(res.data.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  // tab removed
  useEffect(() => {
    if (el?.id && show) {
      getVideoDataByVideoIdAndBrandId(el.id);
    }
  }, [show, status]);
  useEffect(() => {
    const video = videoRef.current;
    if (play && video) {
      video.play();
    } else {
      if (video) {
        video.pause();
      }
    }
  }, [play]);
  const videoRef = useRef(null);

  if (!show && !isClosing) return null;

  return (
    <div className="review-modal-overlay">
      <div className="review-modal-content" ref={modalRef}>
        <button className="review-modal-close" onClick={handleCloseed}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 44 44"
            fill="none"
          >
            <rect width="44" height="44" rx="22" fill="white" />
            <g clipPath="url(#clip0_483_11622)">
              <path
                d="M22 20.586L26.95 15.636L28.364 17.05L23.414 22L28.364 26.95L26.95 28.364L22 23.414L17.05 28.364L15.636 26.95L20.586 22L15.636 17.05L17.05 15.636L22 20.586Z"
                fill="#454549"
              />
            </g>
            <defs>
              <clipPath id="clip0_483_11622">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div className="row">
          <div className="col-3" style={{ position: "relative" }}>
            <div
              className="hover-container"
              onClick={() => {
                setPlay(!play);
              }}
              style={{ cursor: "pointer", position: "relative" }}
            >
              <video
                style={{
                  height: "78vh",
                  width: "25vw",
                  borderRadius: "20px",
                  objectFit: "cover",
                }}
                src={videoUrl}
                alt="Video Thumbnail"
                loop
                autoPlay={false}
                className="img-fluid"
                ref={videoRef}
              />
              <div className="review-moderate-title">
                <span
                  title={fetchedVideoData.title}
                  style={{ color: "white", cursor: "pointer" }}
                >
                  {fetchedVideoData.title}
                </span>
              </div>

              {/* Audio Badge */}
              <div
                className="badge badge-success"
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "20px",
                  color: "rgba(93, 93, 96, 1)",
                  backgroundColor: "rgba(238, 238, 119, 1)", // Set background color to yellow
                  borderRadius: "50px",
                  padding: "5px 10px",
                  zIndex: 999,
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                <i className="ri-translate-2"></i>{" "}
                {fetchedVideoData.audioLanguage
                  ? `Audio-${fetchedVideoData.audioLanguage}`
                  : ""}
              </div>

              {/* Play/Pause Icon */}
              <div
                className="hover-content"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  color: "white",
                  zIndex: "999",
                  backgroundColor: "#FFFFFF",
                  height: "34px",
                  width: "34px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "50%",
                  opacity: play ? 0 : 1,
                  transition: "opacity 0.3s ease",
                }}
              >
                {play ? (
                  <FaPause
                    size={15}
                    className="ps-1"
                    style={{ marginLeft: "-4px" }}
                    color="#09121F"
                  />
                ) : (
                  <FaPlay
                    size={15}
                    className="ps-1 pointer_cursor"
                    color="#09121F"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-9">
            <Card className="card-moderator">
              <div className="d-flex ">
                <ul
                  className="nav nav-tabs model"
                  id="responsive-tabs-ssv"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ms-3 ps-0 ${tab === "Details" ? "active" : ""
                        }`}
                      id="Details-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Details"
                      type="button"
                      role="tab"
                      style={{ fontSize: "20px" }}
                      aria-controls="Details"
                      aria-selected={tab === "Details"}
                      onClick={() => setTab("Details")}
                    >
                      Details
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ms-3 ps-0 ${tab === "Videoinsights" ? "active" : ""
                        }`}
                      id="Videoinsights-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Videoinsights"
                      type="button"
                      role="tab"
                      aria-controls="Videoinsights"
                      style={{ fontSize: "20px" }}
                      aria-selected={tab === "Videoinsights"}
                      onClick={() => setTab("Videoinsights")}
                    >
                      Video Insights
                    </button>
                  </li>
                  {!fetchedVideoData.audioLanguage?.includes("nglish") && (
                    <div
                      className="badge badge-success"
                      onClick={handleTranslateClick}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        color: "black",
                        backgroundColor: "white",
                        border: "1px solid black",
                        borderRadius: "50px",
                        padding: "10px 20px",
                        fontSize: "13px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      <span>
                        <i className="ri-translate-2"></i>{" "}
                        {!showTranslation
                          ? "Translate to English"
                          : "Translate to Original"}
                      </span>
                    </div>
                  )}
                </ul>
              </div>
              <div
                className="tab-content p-2 ms-1"
                id="responsive-tabs-content"
              >
                {tab === "Details" && (
                  <div
                    className="tab-pane fade show active h-100"
                    id="Details"
                    role="tabpanel"
                    aria-labelledby="Details-tab"
                  >
                    <Reviewdetails
                      el={el}
                      obj={fetchedVideoData}
                      colorPickereRef={colorPickereRef}
                      colorPickereRef2={colorPickereRef2}
                      tab={tab}
                      showTranslation={showTranslation}
                      setShowTranslation={setShowTranslation}
                      handleTranslateClick={handleTranslateClick}
                    />
                  </div>
                )}
                {tab === "Videoinsights" && (
                  <div
                    className="tab-pane fade show active h-100"
                    id="Videoinsights"
                    role="tabpanel"
                    aria-labelledby="Videoinsights-tab"
                  >
                    <Videoinsights
                      summary={summary}
                      obj={fetchedVideoData}
                      setSummary={setSummary}
                      sentimentreview={sentimentreview}
                      setsentimentReview={setsentimentReview}
                      transcript={transcript}
                      setTranscript={setTranscript}
                      showTranslation={showTranslation}
                      setShowTranslation={setShowTranslation}
                      handleTranslateClick={handleTranslateClick}
                    />
                  </div>
                )}
              </div>
            </Card>
            <div
              className="row mt-4"
              style={{ display: tab === "Videoinsights" ? "" : "none" }}
            >
              {fetchedVideoData?.reviewText ? (
                <div className="col-6 d-flex">
                  <Card className="p-3 pt-4 flex-fill card-moderator">
                    <h1 className="mt-2 mb-0" style={{ fontSize: "24px" }}>
                      Reviews
                    </h1>
                    <div className="review-container">
                      <div className="d-flex gap-2 align-items-center">
                        <span
                          className="text mt-2"
                          style={{ fontSize: "16px" }}
                        >
                          {fetchedVideoData.nickname
                            ? fetchedVideoData.nickname
                            : "Anonymous"}
                        </span>
                        <CustomRating value={fetchedVideoData?.ratings} />
                      </div>
                      <div className="review-date">
                        Reviewed on {fetchedVideoData?.updatedAt?.split("T")[0]}
                      </div>
                    </div>
                    <Card
                      className="p-2 m-1"
                      style={{ height: "10vh", overflow: "auto" }}
                      dir={
                        !showTranslation
                          ? fetchedVideoData?.audioLanguage ===
                            "Arabic - Saudi Arabia"
                            ? "rtl"
                            : "ltr"
                          : "ltr"
                      }
                    >
                      {showTranslation ? (
                        fetchedVideoData?.translation?.summary ? (
                          fetchedVideoData?.translation?.summary
                        ) : (
                          <p> No Translation Available </p>
                        )
                      ) : (
                        fetchedVideoData?.reviewText
                      )}
                    </Card>
                  </Card>
                </div>
              ) : (
                <div className="col-6 d-flex">
                  <Card className="p-3 pt-4 flex-fill card-moderator">
                    <h1 className="mt-2" style={{ fontSize: "24px" }}>
                      Reviews
                    </h1>
                    <Card.Body>
                      <div className="review-container2">
                        <Card.Title
                          className="text mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          {fetchedVideoData?.nickname
                            ? fetchedVideoData?.nickname
                            : "Anonymous"}
                        </Card.Title>
                        <CustomRating value={fetchedVideoData?.ratings} />
                        <Card.Text className="review-date2">
                          Reviewed on{" "}
                          {fetchedVideoData?.updatedAt?.split("T")[0]}
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              )}
              {fetchedVideoData.status !== "rejected" && (
                <div className="col-6 d-flex">
                  <Card className="p-3 pt-4 flex-fill card-moderator">
                    <h1 className="mt-2" style={{ fontSize: "24px" }}>
                      Video Recommendation
                    </h1>
                    <span
                      className="mt-1 mb-1"
                      style={{
                        fontSize: "18px",
                        color: "rgba(69, 69, 73, 1)",
                      }}
                    >
                      {fetchedVideoData?.sentimentalScore > 40
                        ? " Publish this video UGC to the PDP"
                        : "Do not publish this video UGC to the PDP"}
                    </span>
                    <div className="d-flex gap-2 m-2 ms-0 mb-4">
                      {fetchedVideoData.status === "approved" && (
                        <button
                          className="btn sentiment_button"
                          onClick={() => handleStatusUpdate(el.id, "published")}
                        >
                          Publish
                        </button>
                      )}
                      {fetchedVideoData.status === "published" && (
                        <button
                          className="btn sentiment_button2"
                          onClick={() =>
                            handleStatusUpdate(el.id, "notreviewed")
                          }
                        >
                          Unpublish
                        </button>
                      )}
                      {fetchedVideoData.status === "notreviewed" && (
                        <DropdownButton
                          className="sentiment_button"
                          id="dropdown-basic-button"
                          title="Approve & Publish"
                          onSelect={(selectedStatus) =>
                            handleStatusUpdate(el.id, selectedStatus)
                          }
                        >
                          <Dropdown.Item eventKey="approved">
                            Approve Video UGC
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="published">
                            Approve and Publish Video UGC
                          </Dropdown.Item>
                        </DropdownButton>
                      )}
                      {(fetchedVideoData.status === "notreviewed" ||
                        fetchedVideoData.status === "approved") && (
                          <button
                            className="btn sentiment_button2"
                            onClick={() => handleStatusUpdate(el.id, "rejected")}
                          >
                            Reject
                          </button>
                        )}
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Moderatemodal;
