import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { GoKebabHorizontal } from "react-icons/go";
import { FaComment } from "react-icons/fa";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import ReviewVideosModal from "./ReviewVideosModal";
import { toast } from "react-toastify";
import EmailModal from "./EmailModal";
import SmsModal from "./SmsModal";
import EmbeddedModal from "./EmbeddedModal";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { pdpApiInstance } from "../../axiosInstances";

const Reviewtable = ({ fetchNotReviewedVideosCOunr }) => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalemail, setShowModalemail] = useState(false);
  const [reviewId, setReviewId] = useState("");
  const [count, setCount] = useState("");
  const [pid, setpid] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({
    label: "",
    value: "",
  });
  // const [enabledAI, setEnabledAi] = useState(false);

  const [Showmodalsms, setShowModalsms] = useState(false);
  const [showembedded, setShowembedded] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const fetchreviewdata = async () => {
    try {
      const response = await pdpApiInstance.get("/api/reviews", {
        headers: {
          Authorization: localStorage.getItem("token")
            ? localStorage.getItem("token")
            : "",
        },
      });
      const data = response.data.data;

      setProducts(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteReview = async (id) => {
    try {
      confirmAlert({
        message: "Are you sure you want to delete this review?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                const res = await pdpApiInstance.put(
                  `/api/reviews/delete/${id}`,
                  {},
                  {
                    headers: {
                      Authorization: localStorage.getItem("token")
                        ? localStorage.getItem("token")
                        : "",
                    },
                  }
                );
                if (res.data) {
                  setProducts((prevProducts) =>
                    prevProducts.filter((product) => product.id !== id)
                  );
                  toast.success("Review deleted successfully");
                }
              } catch (error) {
                console.error(error);
                toast.error("Something went wrong!");
              }
            },
          },
          { label: "No", onClick: () => { } },
        ],
      });
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  // useEffect(() => {
  //   handleDeleteReview();
  // }, [reviewId]);

  useEffect(() => {
    fetchreviewdata();
  }, [reviewId]);
  const handleAIchat = async (reviewId, currentStatus) => {
    // setEnabledAi((prevState) => !prevState);
    try {
      await pdpApiInstance
        .put(
          `/api/reviews/aivideosearch/${reviewId}`,
          {
            aiVideoSearch: !currentStatus,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token")
                ? localStorage.getItem("token")
                : "",
            },
          }
        )
        .then((res) => {
          if (res.data) {
            toast.success(`Ai chat ${currentStatus ? "disabled" : "enabled"}`);
            const updatedProducts = products.map((el) => {
              if (el.id === reviewId) {
                // Create a new object with updated aiVideoSearch field
                return { ...el, aiVideoSearch: !currentStatus };
              }
              return el; // Return the original object if no match
            });

            // Update the state with the new products array
            setProducts(updatedProducts);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const getDataforcount = async () => {
    try {
      const res = await pdpApiInstance.get(
        `/api/moderator/videos?page=1&pageSize=100&productIds=${reviewId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? localStorage.getItem("token")
              : "",
          },
        }
      );

      if (res.data) {
        const publishedVideos = res.data.data.records.filter(
          (video) => video.status === "published"
        );
        setCount(publishedVideos.length); // Use .length, not .length()
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDataforcount();
    // alert(count);
  }, [reviewId]);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F1F1EE", // Set background color for header cells
        color: "#000", // Set text color for header cells
        border: "1px solid #E0E0E0", // Add border to header cells
      },
    },
    cells: {
      style: {
        border: "1px solid #F1F1EE",
        padding: "10px",
        cursor: "default",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    tableWrapper: {
      style: {
        minHeight: "200px",
      },
    },
  };
  const columns = [
    {
      name: "Sr",
      selector: (row) => row.index + 1 || "N/A", // Use a fallback value
      sortable: true,
      width: "7%",
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={
            row.product?.productAsset[0]?.key
              ? ` https://cdn-product-images.goswirl.live/${row.product.productAsset[0].key}`
              : "placeholder.jpg"
          }
          alt={row.product?.productAsset[0]?.key || "No Image"}
          style={{ width: "50px", height: "50px" }}
        />
      ),
      width: "10%",
    },
    {
      name: "Product Title",
      selector: (row) => row.product?.name || "No Title", // Use a fallback value
      sortable: true,
      cell: (row) => (
        <span title={row.product?.name}>
          {`${row.product?.name.slice(0, 30)}... ` || "No Title"}
        </span>
      ),
      width: "20%",
    },
    {
      name: "Product Link",
      cell: (row) => (
        <a
          href={row.product?.link || "#"}
          target="_blank"
          rel="noopener noreferrer"
          title={row.product?.link}
          style={{ color: "rgba(51, 68, 153, 1)" }}
        >
          {`${row.product?.link.slice(0, 25)}...` || "No Link"}
        </a>
      ),
    },
    {
      name: "Video UGC Link",
      cell: (row) => (
        <a
          href={`${process.env.REACT_APP_REVIEW_CLIENT_URL}` + row?.product?.uuid || "#"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{ color: "rgba(51, 68, 153, 1)" }}>
            {" "}
            {`${process.env.REACT_APP_REVIEW_CLIENT_URL}` + row?.product?.uuid || "#"}
          </span>
        </a>
      ),
    },
    {
      name: "No. of Video UGC",
      selector: (row) => row.count || 0, // Use a fallback value
      sortable: true,
      width: "15%",
      cell: (row) => (
        <>
          <span
            style={{
              color: "rgba(51, 68, 153, 1)",
              cursor: row.count > 0 ? "pointer" : "default",
              borderBottom:
                row.count > 0 ? "1px solid rgba(51, 68, 153, 1)" : "none",
            }}
            onClick={() => {
              {
                if (row.count > 0) {
                  setReviewId(row.product.uuid);
                  handleShow();
                }
              }
            }}
          >
            {row.count || 0}
          </span>
        </>
      ),
    },
    {
      name: "Action",
      width: "10%",
      cell: (row) => (
        <div className="btn-group dropstart">
          <p
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="m-2 pointer_cursor"
            style={{ transform: "rotate(90deg)" }}
          >
            <span
              style={{
                display: "block",
                padding: "4px",
                height: "25px",
                width: "25px",
                backgroundColor: "rgba(232, 232, 232, 1)",
                borderRadius: "50%",
              }}
            >
              <GoKebabHorizontal size={16} />
            </span>
          </p>
          <ul
            className="dropdown-menu pointer_cursor"
            style={{ fontSize: "12px" }}
          >
            <li
              className="dropdown-item d-flex align-items-center gap-2"
              style={{ padding: "0px 10px", margin: "0" }}
              onClick={() =>
                copyLink(`${process.env.REACT_APP_REVIEW_CLIENT_URL}/${row?.product?.uuid}`)
              }
            >
              <i className="ri-link-m"></i>Copy Link
            </li>
            <li style={{ padding: "0px 10px", margin: "0" }}>
              <hr className="dropdown-divider" />
            </li>
            <li
              className="dropdown-item d-flex align-items-center gap-2"
              style={{ padding: "0px 10px", margin: "0" }}
              onClick={() => {
                setSelectedProduct({
                  label: row.product.name,
                  value: row.product.uuid,
                });
                handleEmailShow();
              }}
            >
              <i className="ri-mail-line"></i>Email
            </li>
            <li style={{ padding: "0px 10px", margin: "0" }}>
              <hr className="dropdown-divider" />
            </li>
            <li
              className="dropdown-item d-flex align-items-center gap-2"
              style={{ padding: "0px 10px", margin: "0" }}
              onClick={() => {
                setSelectedProduct({
                  label: row.product.name,
                  value: row.product.uuid,
                });
                handleSmsShow();
              }}
            >
              <i className="ri-chat-3-line"></i>SMS
            </li>
            <li style={{ padding: "0px 10px", margin: "0" }}>
              <hr className="dropdown-divider" />
            </li>
            <li
              style={{ padding: "0px 10px", margin: "0" }}
              className="dropdown-item d-flex align-items-center gap-2"
              onClick={() => {
                setpid(row.id);
                handleembeddedShow();
              }}
            >
              <i className="ri-code-line"></i>Embedded Code
            </li>
            {row?.brand?.aiVideoSearch && (
              <>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li
                  style={{ padding: "0px 10px", margin: "0" }}
                  className="dropdown-item d-flex align-items-center gap-2"
                  onClick={() => {
                    // setEnabledAi(row.aiVideoSearch);
                    handleAIchat(row.id, row.aiVideoSearch);
                  }}
                >
                  {row.aiVideoSearch ? (
                    <>
                      <i className="ri-sparkling-line"></i>Disable ai chat
                    </>
                  ) : (
                    <>
                      <i className="ri-sparkling-line"></i>Enable ai chat
                    </>
                  )}
                </li>
              </>
            )}
            {/* <li>
              <hr className="dropdown-divider" />
            </li>
            <li
              className="dropdown-item d-flex align-items-center gap-2"
              style={{
                padding: "0px 10px",
                margin: "0",
                color: "rgba(255, 61, 0, 1)",
              }}
              onClick={() => handleDeleteReview(row.id)}
            >
              <i className="ri-delete-bin-line"></i>Delete
            </li> */}
          </ul>
        </div>
      ),
    },
  ];

  const copyLink = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => toast.success("Link copied to clipboard!"))
      .catch((err) => console.error("Failed to copy link: ", err));
  };

  // Function to open the SMS client with a pre-filled message
  const smsLink = (url) => {
    window.location.href = `sms:?body=${url}`;
  };

  // // Function to generate the embedded code
  // const embedCode = (url) => {
  //     const code = `<iframe src="${url}" width="560" height="315" frameborder="0" allowfullscreen></iframe>`;
  //     navigator.clipboard.writeText(code)
  //         .then(() => toast.success('Embed code copied to clipboard!'))
  //         .catch(err => console.error('Failed to copy embed code: ', err));
  // };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleEmailShow = () => {
    setShowModalemail(true);
  };
  const handleCloseemail = () => {
    setShowModalemail(false);
  };
  const handleSmsShow = () => {
    setShowModalsms(true);
  };
  const handleClosesms = () => {
    setShowModalsms(false);
  };
  const handleembeddedShow = () => {
    setShowembedded(true);
  };
  const handleCloseembbeded = () => {
    setShowembedded(false);
  };
  return (
    <div
      className="container-fluid mt-4"
      style={{ height: "calc(100vh - 250px)", overflow: "auto" }}
    >
      <DataTable
        columns={columns}
        data={products.map((el, index) => ({
          ...el,
          index: index,
        }))}
        pagination
        pointerOnHover
        customStyles={customStyles}
      />

      <ReviewVideosModal
        show={showModal}
        handleClose={handleClose}
        id={reviewId}
        fetchNotReviewedVideosCOunr={fetchNotReviewedVideosCOunr}
        fetchreviewdata={fetchreviewdata}
      />
      <EmailModal
        emailshow={showModalemail}
        handleCloseemail={handleCloseemail}
        selectedProduct={selectedProduct}
      />
      <SmsModal
        smsshow={Showmodalsms}
        handleClosesms={handleClosesms}
        selectedProduct={selectedProduct}
      />
      <EmbeddedModal
        embeddedshow={showembedded}
        handleCloseembbeded={handleCloseembbeded}
        id={pid}
      />
    </div>
  );
};

export default Reviewtable;
