import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Card,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const PlaylistcodeModal = ({
  show,
  handleClose,
  playListData,
  getPlalists,
}) => {
  const [copied, setCopied] = useState({
    footer: false,
    body: false,
    pdp: false,
  });
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const handleCopy = (field) => {
    setCopied({ ...copied, [field]: true });
    // setTimeout(() => {
    //     setCopied({ ...copied, [field]: false });
    // }, 2000); // Reset after 2 seconds
  };

  // const codeSnippets = {
  //   footer:
  //     '<script src="https://apigoswirl.com/short_video/v13/short-video.min.js"></script>',
  //   body: `<div id="swirl-short-videos" data-code="${playListData.shortCode}" data-wt="1" data-playlist="${playListData.playlistCode}" data-pdp="product/,products/" data-playlist-name="Spatioplaylist2"></div>`,
  //   body: `<div class="swirl-short-videos-c-ssv13" data-code="${playListData.shortCode}" data-playlist="${playListData.playlistCode}" data-pdp="product/,products/,collection/" data-wt="${user.platform}"></div>`,
  //   pdp: `<div class="swirl-short-videos-c-ssv13" data-code="${user.short_code}" data-playlist="" data-pdp="product/,products/,collection/" data-wt="${user.platform}"></div>`,
  // };
  const codeSnippets =
    user?.is_vyrill === 0 || user.brand_id == 236633565
      ? {
          footer:
            '<script src="https://apigoswirl.com/short_video/v13/short-video.min.js"></script>',
          body: `<div class="swirl-short-videos-c-ssv13" data-code="${playListData.shortCode}" data-playlist="${playListData.playlistCode}" data-pdp="product/,products/,collection/" data-wt="${user?.platform}"></div>`,
          pdp: `<div class="swirl-short-videos-c-ssv13" data-code="${user.short_code}" data-playlist="" data-pdp="product/,products/,collection/" data-wt="${user?.platform}"></div>`,
        }
      : {
          footer:
            '<script async src="https://apigoswirl.com/short_video/vyrill/v13/short-video-v1.min.js"></script>',
          body: `<div id="swirl-short-videos" data-code="${playListData.shortCode}" data-wt="${user?.platform}" data-playlist="${playListData.playlistCode}" data-pdp="product/,products/" data-playlist-name="${playListData.playlistname}"></div>`,
          pdp: `<div class="swirl-short-videos" data-code="${user.short_code}" data-playlist="" data-pdp="product/,products/,collection/" data-wt="${user?.platform}"></div>`,
        };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied({
        footer: false,
        body: false,
        pdp: false,
      });
    }, 2000);

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts or copied state changes
  }, [copied]);
  console.log(playListData);
  const setCreateTable = (value) => {
    searchParams.set("createTable", value);
    navigate({ search: searchParams.toString() });
  };
  const setTableedit = (value) => {
    searchParams.set("Tableedit", value);
    navigate({ search: searchParams.toString() });
  };
  return (
    <Modal
      show={show}
      className="d-grid modal_video "
      size="lg"
      onHide={() => {
        handleClose();

        if (playListData.onCreatePlaylist) {
          setCreateTable(false);
          setTableedit(false);
          getPlalists();
        }
      }}
    >
      <Modal.Body>
        <Container>
          <div className="p-4">
            <Card.Header>
              {" "}
              <Card.Title
                className="mb-2"
                style={{ fontSize: "24px", fontWeight: "700px" }}
              >
                Embed Code
              </Card.Title>
              <IoClose
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "30px",
                  cursor: "pointer",
                  fontSize: "35px",
                }}
                onClick={() => {
                  handleClose();
                }}
              />
            </Card.Header>{" "}
            <hr />
            <Card.Body>
              <Card.Text
                className="mb-1"
                style={{
                  fontSize: "14px",
                  fontWeight: "450px",
                  color: "rgba(69, 69, 73, 1)",
                }}
              >
                Step-1: Add the below script in the footer
              </Card.Text>
              <InputGroup
                className="mb-4 "
                style={{
                  border: "1px solid rgba(209, 209, 210, 1)",
                  borderRadius: "8px",
                }}
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  readOnly
                  cols={120}
                  value={codeSnippets.footer}
                  className="coly_field"
                  style={{ border: "none" }}
                />
                <CopyToClipboard
                  text={codeSnippets.footer}
                  onCopy={() => handleCopy("footer")}
                >
                  <Button variant="">
                    {copied.footer ? <FaCheck /> : <FaRegCopy />}
                  </Button>
                </CopyToClipboard>
              </InputGroup>

              <Card.Text
                className="mt-2 mb-1"
                style={{
                  fontSize: "14px",
                  fontWeight: "450px",
                  color: "rgba(69, 69, 73, 1)",
                }}
              >
                Step-2: Add the below code anywhere in the body tag
              </Card.Text>
              <InputGroup
                className="mb-4"
                style={{
                  border: "1px solid rgba(209, 209, 210, 1)",
                  borderRadius: "8px",
                }}
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  readOnly
                  value={codeSnippets.body}
                  className="coly_field"
                  style={{ border: "none" }}
                />
                <CopyToClipboard
                  text={codeSnippets.body}
                  onCopy={() => handleCopy("body")}
                >
                  <Button variant="">
                    {copied.body ? <FaCheck /> : <FaRegCopy />}
                  </Button>
                </CopyToClipboard>
              </InputGroup>
              {(user.is_vyrill === 0 || user.brand_id == 236633565) && (
                <>
                  <Card.Text
                    className="mt-2 mb-1"
                    style={{
                      fontSize: "14px",
                      fontWeight: "450px",
                      color: "rgba(69, 69, 73, 1)",
                    }}
                  >
                    Step-3: Add the below code in the Product Details Page(PDP)
                  </Card.Text>
                  <InputGroup
                    className="mb-4"
                    style={{
                      border: "1px solid rgba(209, 209, 210, 1)",
                      borderRadius: "8px",
                    }}
                  >
                    <Form.Control
                      as="textarea"
                      rows={1}
                      readOnly
                      value={codeSnippets.pdp}
                      className="coly_field"
                      style={{ border: "none" }}
                    />
                    <CopyToClipboard
                      text={codeSnippets.pdp}
                      onCopy={() => handleCopy("pdp")}
                    >
                      <Button variant="">
                        {copied.pdp ? <FaCheck /> : <FaRegCopy />}
                      </Button>
                    </CopyToClipboard>
                  </InputGroup>
                </>
              )}
              <Button
                variant="dark"
                className="mt-3"
                onClick={() => {
                  handleClose();

                  if (playListData.onCreatePlaylist) {
                    setCreateTable(false);
                    setTableedit(false);
                    getPlalists();
                  }
                }}
              >
                Done
              </Button>
            </Card.Body>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PlaylistcodeModal;
