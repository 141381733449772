import React, { useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import img from '../../assets/images/newdeals.png';
import { FaTrophy, FaFireAlt, FaSpinner } from 'react-icons/fa';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FallingLines } from 'react-loader-spinner';
import { allApiInstance } from '../../axiosInstances';

const ProductUrl = ({
  productLink,
  setProductLink,
  productName,
  setProductName,
  productDescription,
  setProductDescription,
  mrp,
  setMrp,
  sellingPrice,
  setSellingPrice,
  productTag,
  setProductTag,
  productImage,
  setProductImage,
  productImageName,
  setProductImageName,
  productImagePreview,
  setProductImagePreview,
  productImageSize,
  setProductImageSize,
  brandid,
  setBrandid,
  setProductSku,
  productsku,
  productattribute,
  setProductAttribute,
  discount,
  setDiscount,
  setLoading,
  loading,
  fetchedImage,
  setFetchedImage,
}) => {
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setErorr] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const environment = process.env.REACT_APP_PDP_ENV
  const options = [
    {
      value: 1,
      label: (
        <div>
          <div
            className="badge"
            style={{
              borderRadius: '12px 0px',
              backgroundColor: '#CD6113',
              fontWeight: 'lighter',
            }}
          >
            {/* <FaTrophy className='pe-1' color="#F3CE58" size={20} /> */}
            <span>
              <svg
                width="13"
                height="13"
                viewBox="2 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.96167 5.445C1.87 5.445 1.76 5.42667 1.68667 5.37167C1.44833 5.24333 1.155 4.91333 1.155 4.03333C1.155 2.2 0.165 1.13667 0.165 1.13667L0 0.953333L0.861667 0L1.00833 0.22C1.02667 0.238333 1.485 0.898333 2.2 0.715L2.29167 1.19167C1.57667 1.375 1.06333 0.99 0.806667 0.715L0.623333 0.953333C0.935 1.35667 1.595 2.42 1.595 4.03333C1.595 4.51 1.68667 4.84 1.87 4.93167C1.99833 5.005 2.2 4.93167 2.38333 4.74833C2.86 4.27167 3.20833 3.09833 3.20833 3.09833L3.61167 3.245C3.59333 3.3 3.22667 4.565 2.65833 5.115C2.42 5.335 2.18167 5.445 1.96167 5.445ZM9.03833 5.445C9.13 5.445 9.24 5.42667 9.31333 5.37167C9.55167 5.24333 9.845 4.91333 9.845 4.03333C9.845 2.18167 10.8167 1.13667 10.835 1.11833L11 0.953333L10.1383 0L9.99167 0.22C9.97333 0.238333 9.515 0.898333 8.8 0.715L8.70833 1.19167C9.42333 1.375 9.93667 0.99 10.1933 0.715L10.4133 0.953333C10.1017 1.35667 9.44167 2.42 9.44167 4.03333C9.44167 4.51 9.35 4.84 9.16667 4.93167C9.03833 5.005 8.83667 4.93167 8.65333 4.74833C8.17667 4.27167 7.82833 3.09833 7.82833 3.09833L7.425 3.245C7.44333 3.3 7.81 4.565 8.37833 5.115C8.58 5.335 8.81833 5.445 9.03833 5.445ZM4.95 4.19833H6.06833V8.69H4.95V4.19833Z"
                  fill="#F2B200"
                />
                <path
                  d="M5.16992 4.19922H5.82992V8.69089H5.16992V4.19922Z"
                  fill="#FFCE31"
                />
                <path
                  d="M1.79688 0C2.10854 2.82333 3.64854 5.07833 5.50021 5.07833C7.35188 5.07833 8.89188 2.82333 9.20354 0H1.79688Z"
                  fill="#F2B200"
                />
                <path
                  d="M2.50977 0C2.76643 2.86 4.0131 5.13333 5.4981 5.13333C6.9831 5.13333 8.22976 2.86 8.48643 0H2.50977Z"
                  fill="#FFCE31"
                />
                <path
                  d="M8.35867 9.53281H2.63867C2.63867 9.53281 3.92201 7.88281 5.49867 7.88281C7.07534 7.88281 8.35867 9.53281 8.35867 9.53281Z"
                  fill="#F2B200"
                />
                <path
                  d="M7.68169 9.53237H3.31836C3.31836 9.53237 4.29003 7.8457 5.50003 7.8457C6.71003 7.8457 7.68169 9.53237 7.68169 9.53237Z"
                  fill="#FFCE31"
                />
                <path
                  d="M1.79688 9.90039H9.20354V11.0004H1.79688V9.90039Z"
                  fill="#BC845E"
                />
                <path
                  d="M2.63867 9.5332H8.37701V9.89987H2.63867V9.5332Z"
                  fill="#916140"
                />
                <path
                  d="M3.66602 10.1758H7.33268V10.7258H3.66602V10.1758Z"
                  fill="#F2B200"
                />
                <path
                  d="M1.79688 9.90039H2.16354V11.0004H1.79688V9.90039Z"
                  fill="#CE9C7A"
                />
                <path
                  d="M8.83594 9.90039H9.2026V11.0004H8.83594V9.90039Z"
                  fill="#916140"
                />
                <path
                  d="M3.84961 10.1758H7.14961V10.7258H3.84961V10.1758Z"
                  fill="#FFCE31"
                />
              </svg>
            </span>
            Best Seller
          </div>{' '}
          Best Seller{' '}
        </div>
      ),
    },
    {
      value: 2,
      label: (
        <div>
          <div
            className="badge"
            style={{
              color: '#FF9D33',
              borderRadius: '12px 2px',
              backgroundColor: '#FFF6DF',
              fontWeight: 'lighter',
            }}
          >
            <FaFireAlt className="pe-1" color="#F3CE58" size={20} /> Hot Deals
          </div>
          Hot Deals
        </div>
      ),
    },
    {
      value: 3,
      label: (
        <div>
          <div
            className="badge"
            style={{
              borderRadius: '12px 2px',
              backgroundColor: 'white',
              fontWeight: 'lighter',
              color: '#A2A2A4',
            }}
          >
            <img src={img} /> New
          </div>{' '}
          New
        </div>
      ),
    },
  ];

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setProductTag(selectedOption.value);
    } else {
      setProductTag([]);
    }
  };
  const fileInputRef = useRef(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFetchedImage('');
    if (file) {
      setProductImageName(file.name);
      setProductImageSize((file.size / 1024).toFixed(2) + ' KB');
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductImagePreview(reader.result);
        // setFetchedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setFetchedImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const triggerFileInput = () => {
    fileInputRef.current.click();
    clearSelection();
  };

  const clearSelection = () => {
    setProductImage(null);
    setProductImagePreview(null);
    setProductImageName(null);
    setProductImageName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input value
    }
  };

  const calculateDiscount = () => {
    if (mrp > 0 && sellingPrice > 0 && mrp >= sellingPrice) {
      const discountPercentage = ((mrp - sellingPrice) / mrp) * 100;
      setDiscount(discountPercentage.toFixed(2));
    } else {
      setDiscount(null);
    }
  };
  // async function getMetaData(link) {

  //   const url = `https://api.urlmeta.org/meta?url=${link}`;

  //   try {
  //     const response = await axios.get(url, {
  //       headers: {
  //         'Authorization': 'Basic bmFyZXNoLmdhcmdAZ29zd2lybC5saXZlOnZqME54TUtMQ3dVajA1d3RhTzhx'
  //       },
  //       // Note: If you're running this in a secure environment (HTTPS), you should not disable SSL verification.
  //       // The following two lines are only for development purposes.
  //       // httpsAgent: new https.Agent({ rejectUnauthorized: false })
  //     });

  //     const data = response.data;
  //     const metaData = {};

  //     if (data.result.status === 'OK') {
  //       metaData.title = data.meta.title ? data.meta.title.trim() : '';
  //       metaData.description = data.meta.description ? data.meta.description.trim() : '';
  //       metaData.image = data.meta.image || '';
  //       metaData.product_price = data.meta['price:amount'] ? data.meta['price:amount'].replace(/,/g, '') : '';
  //       metaData.selling_price = data.meta['price:amount'] ? data.meta['price:amount'].replace(/,/g, '') : '';

  //     }
  //   } catch (error) {

  //   }
  // }
  const getMetaData = async (link) => {
    if (productLink.includes('http')) {
      setLoadingOverlay(true);
      setErorr('');
      try {
        // // const formData = new FormData()
        // formData.append("productUrl", link)
        await allApiInstance
          .post(`/getProductMetadata`, {
            payload: {
              productUrl: link,
            },
          })
          .then((res) => {
            if (res.data) {
              if (res.data.product) {
                const obj = res.data.product;
                setLoading(false);
                setProductName(obj.title);
                setProductDescription(obj.description);
                setSellingPrice(obj.selling_price);
                setMrp(obj.product_price);
                setFetchedImage(obj.image);
                setProductImagePreview(null);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setLoadingOverlay(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoadingOverlay(false);
      }
    } else {
      setErorr('Please enter valid product url');
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (productLink.length > 0) {
        getMetaData(productLink);
      }
    }, 500);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [productLink]);
  const user = useSelector((state) => state.auth.user);

  const selectRef = useRef(null);

  const clearSelection2 = () => {
    if (selectRef.current) {
      selectRef.current.clearValue();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(file !== null || fetchedImage !== '')) {
      toast.warn('Please select image');
      // Your code here
    } else {
      setSubmitLoading(true);
      try {
        const formData = new FormData();
        formData.append('product_link', productLink);
        formData.append('product_title', productName);
        formData.append('product_desc', productDescription);
        formData.append('price', mrp);
        formData.append('selling_price', sellingPrice);
        formData.append('product_tag', productTag);
        formData.append('brand_product_id', brandid);
        formData.append('sku_code', productsku);
        formData.append('product_attribute', productattribute);
        if (fetchedImage.length === 0) {
          formData.append('image', file);
        } else if (fetchedImage.length > 0) {
          formData.append('product_image', fetchedImage);
        }
        // Assuming 'file' is a File object
        formData.append('brandId', user.brand_id);
        formData.append('environment', environment);
        await allApiInstance
          .post('/addProduct', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            if (res.data) {
              if (res.data.status == 1) {
                toast.success('Suceesfully product added');
                setProductLink('');
                setProductName('');
                setProductDescription('');
                setMrp('');
                clearSelection2();
                setSellingPrice('');
                setProductImage('');
                setProductImagePreview('');
                setProductImageSize('');
                setBrandid('');
                setProductSku('');
                setDiscount('');
                setProductAttribute('');
                setFetchedImage('');
                e.target.reset();
                setSubmitLoading(false);
                setProductImageName('');
                setFile(null);
                setFetchedImage('');
              } else {
                toast.warning(res.data.message);
                setSubmitLoading(false);
              }
            }
          })
          .catch((err) => {
            toast.error('Something went wrong!');
            console.log(err);
            setSubmitLoading(false);
          });
      } catch (error) {
        toast.error('Something went wrong!');
        console.log(error);
        setSubmitLoading(false);
      }
    }
  };
  return (
    <div className="container mt-3 row">
      {loadingOverlay && (
        <div className="overlay col-5">
          <div className="loader_comp_main_div mt-5">
            <FallingLines
              color="#EEEE77"
              width="100"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          </div>
        </div>
      )}
      <div
        className="col-12"
        style={{ pointerEvents: loading || loadingOverlay ? 'none' : 'auto' }}
      >
        <form
          onSubmit={(e) => handleSubmit(e)}
          style={{
            pointerEvents: submitLoading || loadingOverlay ? 'none' : 'auto',
            opacity: submitLoading || loadingOverlay ? '0.5' : '1',
          }}
        >
          <div className="row">
            <div className="form-group mt-2">
              <label className="label-product">
                Product Link<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter link"
                name="productLink"
                value={productLink}
                onChange={(e) => setProductLink(e.target.value)}
                required
              />
              {error.length > 0 && (
                <small className="text-danger">{error}</small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group mt-2">
              <label className="label-product">
                Product Name<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter product name"
                name="productName"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group mt-2">
              <label className="label-product">
                Product Description<sup className="text-danger">*</sup>
              </label>
              <textarea
                className="form-control mt-2"
                placeholder="Enter product description"
                name="productDescription"
                rows={3}
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group mt-2">
                <label className="label-product">
                  MRP<sup className="text-danger">*</sup>
                </label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Enter MRP"
                  name="mrp"
                  value={mrp}
                  onChange={(e) => setMrp(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mt-2">
                <label className="label-product">Selling Price</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Enter selling price"
                  name="sellingPrice"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4" style={{ zIndex: '10' }}>
              <label className="mt-2 label-product">Product Tag</label>

              <Select
                className="mt-2"
                value={options.find((option) => option.value === productTag)}
                onChange={handleChange}
                options={options}
                isClearable={true}
                placeholder="Choose Tag"
                ref={selectRef}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4">
              <div className="form-group">
                <label className="label-product">Product Brand id</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="product brand id"
                  name="brandid"
                  value={brandid}
                  onChange={(e) => setBrandid(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="label-product">Product SKU</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Product SKU"
                  name="productsku"
                  value={productsku}
                  onChange={(e) => setProductSku(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="label-product">Product Attribute</label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Product Attribute"
                  name="productattribute"
                  value={productattribute}
                  onChange={(e) => setProductAttribute(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group mt-2">
            {/* <label>Product Image<sup className='text-danger'>*</sup></label>
            <div className="input-group">
              <input
                type="text"
                className="form-control mt-2"
                placeholder="No file selected"
                value={fetchedImage.length > 0 ? fetchedImage : productImageName}
                readOnly
                required
              />
              <div className="input-group-append mt-2">
                <button
                  className="btn"
                  style={{ backgroundColor: '#E8E8E8' }}
                  type="button"
                  onClick={triggerFileInput}
                >
                  Browse
                </button>
              </div>
            </div>
            {/* <input
              type="file"
              id="productImage"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleImageChange}
            /> */}
            <label className="label-product">
              Product Image<sup className="text-danger">*</sup>
            </label>
            <div
              className="input-group ps-1 mt-2 mb-2"
              style={{
                border: '1px solid rgba(209, 209, 210, 1)',
                borderRadius: '8px',
              }}
            >
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                className="form-control mt-2"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={triggerFileInput}
              />
              <input
                type="text"
                className="form-control"
                style={{ border: 'none' }}
                placeholder={
                  fileInputRef?.current?.files[0]?.name || 'No file selected'
                }
                onClick={() => fileInputRef.current.click()}
                readOnly
              />
              <button
                className="btn m-1"
                type="button"
                style={{
                  border: 'none',
                  backgroundColor: 'rgba(232, 232, 232, 1)',
                  borderRadius: '8px',
                  padding: '0 13px',
                  fontSize: '12px',
                }}
                onClick={() => fileInputRef.current.click()}
              >
                Browse
              </button>
            </div>

            {productImagePreview && !(fetchedImage.length > 0) && (
              <div className="card p-1 mt-2">
                <div className="d-flex w-100">
                  <img
                    style={{
                      height: '50px',
                      width: '35px',
                      borderRadius: '3px',
                    }}
                    src={productImagePreview}
                    // ref={fileInputRef}
                    alt="Product Preview"
                  />
                  <div className="d-flex flex-column ps-2 pt-1">
                    <small className="text-muted">{productImageName}</small>
                    <small style={{ color: 'rgb(162, 162, 164)' }}>
                      {productImageSize} <b>.</b>{' '}
                      <span className="text-success">Uploaded</span>
                    </small>
                  </div>
                  <div className="ms-auto" onClick={clearSelection}>
                    <IoClose className="pointer_cursor" />
                  </div>
                </div>
              </div>
            )}

            {fetchedImage && (
              <>
                <div>
                  {' '}
                  <div className="col-5">
                    <div className="card mt-2 p-1">
                      <img src={fetchedImage} alt="fetched" />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row mt-2 ms-1">
              <button
                disabled={error.length > 0}
                type="submit"
                className="col-2 p-2 btn btn-dark"
              >
                Upload {submitLoading ? <FaSpinner className="spinning" /> : ''}
              </button>
            </div>
          </div>
          <input
            type="file"
            id="productImage"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleImageChange}
          />
        </form>
      </div>
    </div>
  );
};

export default ProductUrl;
