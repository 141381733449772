import React, { useState } from 'react';
import { GoKebabHorizontal } from 'react-icons/go';
import { CiEdit } from 'react-icons/ci';
import { BsDownload } from 'react-icons/bs';
import { BiTrash } from 'react-icons/bi';
import { FaComment, FaPause, FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setVideoDataForEdir } from '../../redux/apiSlice';
import { useDispatch } from 'react-redux';
let play = false;
const VideoCard = ({
  tab,
  setTab,
  setPlayinVideoId,
  playinVideoId,
  el,
  selectedCards,
  index,
  showCheckboxes,
  handleCheckboxChange,
  toggleCheckboxes,
  setLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [play, setPlay] = useState(false);
  async function downloadVideo(videoUrl) {
    try {
      const blob = await fetch(videoUrl).then((response) => {
        response.blob();
        setLoading(true);
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const arr = videoUrl.split('/');
      a.download = arr[arr.length - 1]; // Change the filename as needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Something went wrong');
    }
  }
  const handleEditPage = (videoId) => {
    dispatch(setVideoDataForEdir(el));
    navigate(`/admin/swirl-edit/${btoa(videoId)}`);
  };
  function formatVideoDuration() {
    const durationInSeconds = el.video_len;
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  const handlecomment = (videoId, product_title) => {
    // dispatch(setVideoDataForComment(el))
    const obj = {
      product_title,
      videoId,
    };
    const p = JSON.stringify(obj);

    navigate(`/admin/comment/${btoa(p)}`);
  };
  const handleCardClick = () => {
    if (showCheckboxes) {
      handleCheckboxChange(el.video_id);
    }
  };
  return (
    <>
      <div
        className="col-lg-3  col-4 col-sm-6 p-2"
        id={`videocard_${el.video_id}`}
        key={index}
      >
        <div
          className="card h-100"
          style={{ borderRadius: '10px' }}
          key={el.video_id}
          onClick={handleCardClick}
        >
          <div
            className="h-100"
            style={{
              position: 'relative',
              borderRadius: '15px',
              border:
                tab !== 'video'
                  ? selectedCards?.includes(el.video_id)
                    ? '5px solid #334499'
                    : 'none'
                  : 'none',
            }}
          >
            {play && playinVideoId == el.video_id ? (
              <video
                className="h-100 w-100"
                style={{ objectFit: 'cover', borderRadius: '10px' }}
                autoPlay
              >
                <source
                  src={
                    el.server_url !== null && el.server_url !== ''
                      ? el.server_url
                      : el.server_url_1
                  }
                />
              </video>
            ) : (
              <img
                className=" w-100"
                style={{
                  borderRadius: '10px 10px 0 0',
                  height: 'calc(100% - 30px)',
                }}
                loading="lazy"
                src={
                  el.cover_image !== null && el.cover_image !== ''
                    ? el.cover_image
                    : el.cover_image_1
                }
              />
            )}
            {/* <video className='h-100 w-100' style={{ objectFit: "cover", position: "absolute" }}>
                                            <source src='https://swirl.azureedge.net/swirl-short-videos/7857.mp4' />
                                        </video> */}
            <div style={{ position: 'absolute', right: '0', top: '0' }}>
              <div
                className={tab === 'video' ? 'video float-end' : 'playlist'}
                style={{
                  position: 'relative',
                  top: '0',
                  right: '0',
                  textAlign: 'right',
                }}
              >
                {showCheckboxes ? (
                  <input
                    type="checkbox"
                    className="float-end m-2"
                    // onChange={() => handleCheckboxChange(el.video_id)}
                    checked={selectedCards.includes(el.video_id)}
                    style={{
                      top: '0px',
                      right: '0px',
                      accentColor:
                        tab === 'video' ? ' rgba(238, 238, 119, 1)' : 'black',
                    }}
                  />
                ) : (
                  <>
                    <div className="btn-group dropstart">
                      <p
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="m-2 pointer_cursor"
                        style={{ transform: ' rotate(90deg)' }}
                      >
                        <span
                          style={{
                            display: 'block',
                            padding: '0px 4px',
                            height: '25px',
                            width: '25px',
                            backgroundColor: '#fff',
                            borderRadius: '50%',
                          }}
                        >
                          <GoKebabHorizontal size={16} />
                        </span>
                      </p>
                      <ul className="dropdown-menu pointer_cursor">
                        <li
                          className="dropdown-item d-flex align-items-center gap-2"
                          onClick={() => {
                            handleEditPage(el.video_id);
                          }}
                        >
                          <i className="ri-edit-2-line"></i>Edit
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li
                          className="dropdown-item d-flex align-items-center gap-2"
                          onClick={() => {
                            downloadVideo(el.server_url);
                          }}
                        >
                          <i className="ri-download-2-line"></i>Download
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li
                          className="dropdown-item d-flex align-items-center gap-2"
                          style={{ color: 'rgba(255, 61, 0, 1)' }}
                          onClick={() => {
                            toggleCheckboxes();
                            handleCheckboxChange(el.video_id);
                          }}
                        >
                          <i className="ri-delete-bin-line"></i>Delete
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li
                          className="dropdown-item  d-flex align-items-center gap-2"
                          onClick={() => {
                            handlecomment(el.video_id);
                          }}
                        >
                          <FaComment />
                          Comments
                        </li>{' '}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setPlayinVideoId(el.video_id);
                setPlay(!play);
              }}
              style={{
                position: 'absolute',
                top: '45%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                  height: '34px',
                  width: '34px',
                  display: 'grid',
                  placeItems: 'center',
                  borderRadius: '50%',
                }}
              >
                {play && playinVideoId == el.video_id ? (
                  <FaPause
                    size={15}
                    className="ps-1 pointer_cursor"
                    color="#09121F"
                    style={{ marginLeft: '-4px' }}
                  />
                ) : (
                  <FaPlay
                    size={15}
                    className="ps-1 pointer_cursor"
                    color="#09121F"
                  />
                )}
              </div>
            </div>
            <div
              className="badge position-absolute end-0 mb-4 me-1"
              style={{
                bottom: '15px',
                backgroundColor: 'rgba(23, 23, 28, 0.7)',
              }}
            >
              {formatVideoDuration(el.video_len)}
            </div>
            <div
              className="p-1 bg-white w-100 video_title_gallery"
              title={el.video_title}
              style={{
                position: 'absolute',
                bottom: '0',
                borderRadius: '0px 0px 10px 10px',
              }}
            >
              {el.video_title}
            </div>
          </div>
          {/* <div style={{ position: "relative", bottom: "0%",height:"" }}>123</div> */}
          {/* <div>{card.title}</div> */}
          {/* {showCheckboxes && (
                                        <input
                                            type="checkbox"
                                            className='float-end'
                                            onChange={() => handleCheckboxChange(card.id)}
                                            checked={selectedCards.includes(card.id)}
                                        />
                                    )} */}
        </div>
      </div>
    </>
  );
};

export default VideoCard;
