import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { useSelector } from 'react-redux';
import { setLoading } from '../../redux/apiSlice';
import { FaAngleDown, FaSearch, FaSpinner } from 'react-icons/fa';
import { Form, InputGroup } from 'react-bootstrap';
import { allApiInstance } from '../../axiosInstances';

const AddVideos = ({
  setSelectedVideos,
  selectedVideos,
  setPortraitOffset,
  setLandscapeOffset,
  landscameRowsCount,
  landScapeViewMoreLoading,
  setQueryText,
  fetchData,
  fetchData2,
  queryText,
  videoData,
  isPortrait,
  viewMoreLandscape,
  portraitRowsCount,
  viewMorePortrait,
  portraitViewMoreLoading,
  filterProduct,
  setFilterProduct,
  tab,
  setTab,
}) => {
  const [products, setProducts] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 220,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: '2',
    }),
  };

  // useEffect(() => {
  //     async function fetchDataAndData2() {
  //         await fetchData2();
  //         handleClearSelection();
  //     }
  //     fetchDataAndData2();
  // }, [filterProduct, queryText]);

  const fetchDataproduct = async () => {
    try {
      const [productsRes] = await Promise.all([
        allApiInstance.get(
          `/getProductsByBrandId?brandId=${user.brand_id}`
        ),
      ]);
      setProducts(productsRes.data.products || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataproduct();
  }, []);

  const formatVideoDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const toggleVideoSelection = (video) => {
    setSelectedVideos((prevSelected) => {
      if (prevSelected.includes(video)) {
        return prevSelected.filter((v) => v !== video);
      } else {
        return [...prevSelected, video];
      }
    });
  };

  const handleClearSelection = () => {
    setFilterProduct({ productId: '' });
    // const updatedSelectedVideos = [...videoData];
    // setSelectedVideos(updatedSelectedVideos);
  };
  const handleClick = () => {
    setTab('Upload');
  };
  return (
    <div>
      <form className="d-flex justify-content-end mb-1 mt-1">
        <Select
          className="justify-content-end"
          options={products}
          getOptionLabel={(option) => option.productTitle}
          getOptionValue={(option) => option.productId}
          placeholder="Search by product"
          styles={customStyles}
          isClearable={true}
          onChange={async (e) => {
            setFilterProduct(e);
            if (e === null) {
              await handleClearSelection();
            }
          }}
        />
        <div
          className="input-group"
          style={{ maxWidth: '230px', marginLeft: '10px' }}
        >
          {/* <input
                        type="search"
                        placeholder="🔍 Search video..."
                        className="form-control"
                        onChange={(e) => {
                            setPortraitOffset(1);
                            setLandscapeOffset(1);
                            setQueryText(e.target.value);
                        }}
                    /> */}
          {/* <InputGroup className="" style={{ border: "1px solid rgba(209, 209, 210, 1)", borderRadius: "7px" }}>
                        <InputGroup.Text id="basic-addon1" style={{ backgroundColor: "white", border: "none" }}><i class="ri-search-line"></i></InputGroup.Text>
                        <Form.Control
                            style={{ border: 'none' }}
                            type='search'
                            placeholder=" Search video..."
                            onChange={(e) => {
                                setPortraitOffset(1);
                                setLandscapeOffset(1);
                                setQueryText(e.target.value);
                            }}
                        />

                    </InputGroup> */}
          <InputGroup
            className="mb-3 no-space-group"
            style={{
              border: '1px solid rgba(209, 209, 210, 1)',
              borderRadius: '7px',
            }}
          >
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: 'white',
                border: 'none',
                padding: '6px',
              }}
            >
              <i className="ri-search-line"></i>
            </InputGroup.Text>
            <Form.Control
              className="form-control"
              type="search"
              placeholder="Search video..."
              onChange={(e) => {
                setPortraitOffset(1);
                setLandscapeOffset(1);
                setQueryText(e.target.value);
              }}
            />
          </InputGroup>
        </div>
      </form>

      <div style={{ height: 'calc(100vh - 390px)', overflowY: 'auto' }}>
        {videoData.length === 0 &&
          !(filterProduct?.productId || queryText.length > 0) ? (
          <>
            <div
              className="mt-2"
              style={{ textAlign: 'center', padding: '50px' }}
            >
              <h2 style={{ fontSize: '24px', fontWeight: '450' }}>
                Ready to make your debut on the digital stage?
              </h2>
              <h1 style={{ fontSize: '32px', fontWeight: '700' }}>
                Let's hit upload and unveil your masterpiece to the world!
              </h1>

              <button
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  color: '#fff',
                  backgroundColor: '#000',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={handleClick}
              >
                Upload video
              </button>
            </div>
          </>
        ) : (
          <>
            {videoData.length === 0 &&
              (filterProduct?.productId || queryText.length > 0) ? (
              <>
                <div className="container container_no_videos text-center my-5">
                  <div className="display-6">Nothing found!</div>
                  <div className="lead">
                    Looks like your search went on a video vacation
                  </div>
                </div>
              </>
            ) : (
              <>
                {isPortrait ? (
                  <div
                    className="d-flex flex-wrap p-0"
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px !important',
                    }}
                  >
                    {videoData.map((video) => (
                      <div
                        key={video.id}
                        className="col-2 mt-3"
                        style={{ position: 'relative' }}
                      >
                        <div className="p-2">
                          <label
                            className="playlist w-100"
                            style={{
                              boxShadow: '0.3px 0.3px 2px 1px #E8E8E8',
                              borderRadius: '10px',
                              borders: '5px 5px 0 5px',
                              outline: selectedVideos.some(
                                (el) => el.video_id === video.video_id
                              )
                                ? '5px solid #334499'
                                : 'none',
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedVideos.some(
                                (el) => el.video_id === video.video_id
                              )}
                              onChange={() => toggleVideoSelection(video)}
                              style={{ zIndex: '1' }}
                            />
                            <div className="position-relative">
                              <img
                                className="h-100"
                                src={video.cover_image}
                                style={{
                                  borderRadius: '10px 10px 0 0',
                                  objectFit: 'cover',
                                  width: '100%',
                                }}
                              />
                              <div
                                className="badge position-absolute mb-4 m-1 p-1"
                                style={{
                                  bottom: '18px',
                                  right: '2px',
                                  backgroundColor: 'rgba(23, 23, 28, 0.7)',
                                }}
                              >
                                {formatVideoDuration(video.video_len)}
                              </div>
                              <div
                                className="title_video_select"
                                style={{
                                  paddingLeft: '10px',
                                  paddingTop: '3px',
                                  paddingBottom: '6px',
                                }}
                              >
                                {video.video_title}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="d-flex flex-wrap p-0"
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px !important',
                    }}
                  >
                    {videoData.map((video) => (
                      <div
                        key={video.id}
                        className="col-3 mt-3"
                        style={{ position: 'relative' }}
                      >
                        <div className="p-2">
                          <label
                            className="playlist w-100"
                            style={{
                              boxShadow: '0.3px 0.3px 2px 1px #E8E8E8 ',
                              borderRadius: '10px',
                              borders: '5px 5px 0 5px',
                              outline: selectedVideos.some(
                                (el) => el.video_id === video.video_id
                              )
                                ? '5px solid #334499'
                                : 'none',
                              zIndex: '5',
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedVideos.some(
                                (el) => el.video_id === video.video_id
                              )}
                              onChange={() => toggleVideoSelection(video)}
                              style={{ zIndex: '1' }}
                            />
                            <div className="position-relative">
                              <img
                                className="h-100"
                                src={video.cover_image}
                                style={{
                                  borderRadius: '10px 10px 0 0',
                                  objectFit: 'cover',
                                  width: '100%',
                                }}
                              />
                              <div
                                className="badge position-absolute mb-5 m-1 me-2"
                                style={{
                                  bottom: '4px',
                                  right: '2px',
                                  backgroundColor: 'rgba(23, 23, 28, 0.7)',
                                }}
                              >
                                {formatVideoDuration(video.video_len)}
                              </div>
                              <div
                                className="title_video_select"
                                style={{
                                  paddingLeft: '10px',
                                  paddingTop: '3px',
                                  paddingBottom: '6px',
                                }}
                              >
                                {video.video_title}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* {isPortrait && videoData.length !== portraitRowsCount && videoData.length > 0 && (
                            <button
                                className="btn btn-dark"
                                onClick={viewMorePortrait}
                                style={{ margin: '1em', padding: '10px', marginLeft: '40%' }}
                            >
                                View More
                            </button>
                        )} */}
                {isPortrait
                  ? videoData.length < portraitRowsCount &&
                  videoData.length > 0 && (
                    <div className="text-center">
                      <button
                        className="btn btn-light  p-2"
                        style={{ color: 'rgba(51, 68, 153, 1)' }}
                        onClick={viewMorePortrait}
                      >
                        View More ({portraitRowsCount - videoData.length})
                        <FaAngleDown />
                        {portraitViewMoreLoading && (
                          <FaSpinner className="spinning" />
                        )}
                      </button>
                    </div>
                  )
                  : videoData.length < landscameRowsCount &&
                  videoData.length > 0 && (
                    <div className="text-center">
                      <button
                        className="btn btn-light p-2"
                        style={{ color: 'rgba(51, 68, 153, 1)' }}
                        onClick={viewMoreLandscape}
                      >
                        View More ({landscameRowsCount - videoData.length})
                        <FaAngleDown />
                        {landScapeViewMoreLoading && (
                          <FaSpinner className="spinning" />
                        )}
                      </button>
                    </div>
                  )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AddVideos;
