// import React from 'react';

// const Transcript = ({ obj }) => {
//     return (
//         <div style={{ position: 'relative' }}>
//             {!obj.audioLanguage?.includes('English') &&
//                 < div
//                     className='badge badge-success'
//                     style={{
//                         position: 'absolute',
//                         top: '-20px',
//                         right: '10px',
//                         color: 'black',
//                         backgroundColor: 'white',
//                         border: '1px solid black',
//                         borderRadius: '50px',
//                         padding: '10px 20px',

//                         fontSize: '13px',
//                         fontWeight: 'bold',
//                     }}
//                 >
//                     <span><i className="ri-translate-2"></i> Translate to English</span>
//                 </div>}
//             <div className='pt-3' dir={obj.audioLanguage === 'Arabic - Saudi Arabia' ? 'rtl' : ""} style={{ height: '25vh', overflow: 'auto' }}>
//                 {obj.transcript ? (
//                     obj.transcript.split('.').reduce((acc, cur, index) => {
//                         if (index % 3 === 0) {
//                             acc.push(cur);
//                         } else {
//                             acc[acc.length - 1] += `.${cur}`;
//                         }
//                         return acc;
//                     }, []).map((el, idx) => (
//                         <p key={idx}>{el.trim()}.</p>
//                     ))
//                 ) : (
//                     <p>No Audio</p>
//                 )}
//             </div>
//         </div >
//     );
// }

// export default Transcript;

import React, { useState } from "react";

const Transcript = ({
  obj,
  setShowTranslation,
  showTranslation,
  handleTranslateClick,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        className="pt-4"
        dir={
          obj.audioLanguage === "Arabic - Saudi Arabia"
            ? !showTranslation
              ? "rtl"
              : ""
            : ""
        }
        style={{ height: "25vh", overflow: "auto" }}
      >
        {showTranslation ? (
          obj?.translation?.transcript ? (
            obj.translation.transcript
              .split(".")
              .reduce((acc, cur, index) => {
                if (index % 3 === 0) {
                  acc.push(cur);
                } else {
                  acc[acc.length - 1] += `.${cur}`;
                }
                return acc;
              }, [])
              .map((el, idx) => <p key={idx}>{el.trim()}.</p>)
          ) : (
            <p>No Translation Available</p>
          )
        ) : obj.transcript ? (
          obj.transcript
            .split(".")
            .reduce((acc, cur, index) => {
              if (index % 3 === 0) {
                acc.push(cur);
              } else {
                acc[acc.length - 1] += `.${cur}`;
              }
              return acc;
            }, [])
            .map((el, idx) => <p key={idx}>{el.trim()}.</p>)
        ) : (
          <p>No Audio</p>
        )}
      </div>
    </div>
  );
};

export default Transcript;
