
import moment from 'moment';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { FaCode } from 'react-icons/fa';
import { GrTrash } from 'react-icons/gr';
import { MdOutlineModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { allApiInstance } from '../../axiosInstances';

const PDPTable = ({
  setLoading,
  setProductUrl,
  setEditMode,
  data,
  setPdpData,
  setModal,
}) => {
  const columns = [
    {
      name: 'Sr',
      selector: (row) => row.index + 1,
      width: '6%',
    },
    {
      name: 'Product URl',
      selector: (row) => row.productLink,
      cell: (row) => {
        return <p style={{ lineHeight: '20px' }}>{row.productLink}</p>;
      },
    },
    {
      name: 'Attached videos',
      selector: (row) => row.videoId.split(',')?.length,
      width: '150px',
    },

    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <div className="d-flex gap-3">
              {/* <button className='btn btn-sm btn-outline-dark' style={{ borderRadius: "100px" }} >Edit</button> */}
              <MdOutlineModeEdit
                className="pointer_cursor"
                size={20}
                onClick={() => {
                  setEditMode(true);
                  setProductUrl(row.productLink);
                }}
              />
              <FaCode
                className="pointer_cursor"
                onClick={() => {
                  pdpModalOpen(row);
                }}
                size={20}
              />
              <GrTrash
                className="pointer_cursor"
                onClick={() => {
                  handleDeletePdp(row.id);
                }}
                size={20}
              />
              {/* <button className='btn btn-sm btn-outline-dark' style={{ borderRadius: "100px" }} >Code</button>
                        <button className='btn btn-sm text-white' style={{ backgroundColor: "#FF3D00", borderRadius: "100px" }} >Delete</button> */}
            </div>
          </>
        );
      },
      width: '200px',
    },
  ];
  const user = useSelector((state) => state.auth.user);

  const handleDeletePdp = (id) => {
    confirmAlert({
      // title: `Do you really want to delete the playlist ${name}?`,
      message: `Do you really want to delete this pdp link?`,
      buttons: [
        {
          label: 'Yes, delete',
          onClick: async () => {
            await allApiInstance
              .patch(
                '/deletePdpInfoBySwirlShopifyPdpId',
                {
                  brandId: user.brand_id,
                  sspId: id,
                }
              )
              .then((res) => {
                if (res.data) {
                  setPdpData(data.filter((el) => el.id != id));
                  toast.success('Playlist deleted successfully!');
                }
              })
              .catch((Err) => {
                console.log(Err);
                toast.error('Something went wrong!');
              });
          },
        },
        {
          label: 'Cancel',
          // onClick: () => alert("Click No")
        },
      ],
    });
  };
  const pdpModalOpen = () => {
    setModal(true);
  };
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#F1F1EE', // Set background color for header cells
        color: '#000', // Set text color for header cells
        border: '1px solid #E0E0E0', // Add border to header cells
      },
    },
    cells: {
      style: {
        border: '1px solid #F1F1EE',
        padding: '10px',
      },
    },
    headRow: {
      style: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#f1f1f1',
        zIndex: 1,
      },
    },
    footer: {
      style: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#f1f1f1',
        zIndex: 1,
      },
    },
  };
  return (
    <div style={{ height: 'calc(100% - 100px)' }}>
      <DataTable
        columns={columns}
        className="card mt-3"
        // style={{ height: "calc(100% - 162px", overflow: "auto" }}
        data={data?.map((item, index) => ({ ...item, index }))}
        pagination
        customStyles={customStyles}
        fixedHeader
        fixedHeaderScrollHeight="36vh"
      // highlightOnHover
      />
    </div>
  );
};

export default PDPTable;
