import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FaPause, FaPlay } from 'react-icons/fa';

const ManageVideos = ({ selectedVideos, setSelectedVideos, isPortrait }) => {
  const [draggedVideo, setDraggedVideo] = useState(null);
  const [play, setPlay] = useState(false);
  const [playingVideoId, setPlayingVideoId] = useState('');

  const handleDragStart = (index) => {
    setDraggedVideo(index);
  };

  const handleDragEnter = (index) => {
    if (draggedVideo !== null) {
      const updatedVideos = [...selectedVideos];
      const draggedItem = updatedVideos.splice(draggedVideo, 1)[0];
      updatedVideos.splice(index, 0, draggedItem);
      setSelectedVideos(updatedVideos);
      setDraggedVideo(index);
    }
  };

  const handleDeleteVideo = (index) => {
    const updatedVideos = [...selectedVideos];
    updatedVideos.splice(index, 1);
    setSelectedVideos(updatedVideos);
  };

  const handlePlayPauseClick = (videoId) => {
    setPlayingVideoId(videoId);
    setPlay(!play);
  };
  const formatVideoDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div style={{ height: 'calc(100vh - 350px)', overflowY: 'auto' }}>
      <h5
        className="ps-2 mt-3"
        style={{ fontWeight: '600px', fontSize: '18px' }}
      >
        Change the sequence of videos placement by dragging and dropping
        positions.
      </h5>
      <div
        className="d-flex"
        style={{ flexWrap: 'wrap', borderRadius: '15px' }}
      >
        {selectedVideos.map((video, index) => (
          <div
            className={isPortrait ? 'col-2' : 'col-3'}
            key={video.video_id}
            style={{
              position: 'relative',
              borders: '5px 5px 0 5px',
              borderRadius: '10px',
              padding: '10px',
            }}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragEnter={() => handleDragEnter(index)}
            onDragOver={(e) => e.preventDefault()}
          >
            <div
              className="position-relative"
              style={{
                boxShadow: '0.3px 0.3px 2px 1px #E8E8E8',
                borderRadius: '10px',
              }}
            >
              {play && playingVideoId === video.video_id ? (
                <video
                  autoPlay
                  style={{
                    display: 'block',
                    borderRadius: '10px 10px 0 0',
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <source src={video.server_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={video.cover_image}
                  alt="Cover"
                  style={{
                    display: 'block',
                    borderRadius: '10px 10px 0 0',
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                />
              )}
              <div
                className=" badge position-absolute  mb-4 m-1"
                style={{
                  right: '3px',
                  bottom: '15px',
                  zIndex: '1',
                  backgroundColor: 'rgba(23, 23, 28, 0.7)',
                }}
              >
                {formatVideoDuration(video.video_len)}
              </div>
              <div
                className="title_video_select"
                style={{
                  paddingLeft: '10px',
                  paddingTop: '8px',
                  paddingBottom: '2px',
                }}
              >
                {video.video_title}
              </div>
              <div
                onClick={() => handlePlayPauseClick(video.video_id)}
                className="pointer_cursor"
                style={{
                  position: 'absolute',
                  top: '45%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    height: '34px',
                    width: '34px',
                    display: 'grid',
                    placeItems: 'center',
                    borderRadius: '50%',
                  }}
                >
                  {play && playingVideoId === video.video_id ? (
                    <FaPause
                      size={15}
                      className="ps-1"
                      style={{ marginLeft: '-4px' }}
                      color="#09121F"
                    />
                  ) : (
                    <FaPlay
                      size={15}
                      className="ps-1 pointer_cursor"
                      color="#09121F"
                    />
                  )}
                </div>
              </div>
              <button
                style={{
                  position: 'absolute',
                  top: '15px',
                  right: '15px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'red',
                  fontSize: '14px',
                  backgroundColor: 'white',
                  padding: '4px 7px',
                  borderRadius: '50%',
                  display: 'flex',
                }}
                onClick={() => handleDeleteVideo(index)}
              >
                <i
                  className="ri-delete-bin-line"
                  style={{ fontSize: '14px' }}
                ></i>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageVideos;
