import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
  apiKey: window.atob('QUl6YVN5QXVCWEJUb2NzaFU1a2V4T28tTzNqNW40SkZsblZReU9v'),
  authDomain: 'swirl-short-vido.firebaseapp.com',
  projectId: 'swirl-short-vido',
  storageBucket: 'swirl-short-vido.appspot.com',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
