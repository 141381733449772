import React, { useRef, useState, useEffect } from "react";
import { Card, Tab, Nav, Row, Col, Dropdown, Button } from "react-bootstrap";
import Select, { components } from "react-select";
import Reviewmodel from "./Reviewmodel";
import ReviewModal from "./Reviewmodel";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { ProfessionalTemplate } from "./EmailTemplates/Professional";
import { Casual } from "./EmailTemplates/Casual";
import { Whimsical } from "./EmailTemplates/Whimsical";
import { Professionalsms } from "./SmsTemplate/Professionalsms";
import { Casualsms } from "./SmsTemplate/Casualsms";
import { Whimsicalsms } from "./SmsTemplate/Whimsicalsms";
import { pdpApiInstance } from "../../axiosInstances";
// import { ProfessionalTemplate } from './EmailTemplates/Professional';

const Createreview = () => {
  const [showCard, setShowCard] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [cardContent, setCardContent] = useState(null);
  const [tab, setTab] = useState("professional");
  const [tabsms, setTabsms] = useState("professionals");
  const [copied, setCopied] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await pdpApiInstance.get("/api/products", {
          headers: {
            Authorization: localStorage.getItem("token")
              ? localStorage.getItem("token")
              : "",
          },
        });
        const products = response.data.data.map((product) => ({
          label: product.name,
          value: product.uuid,
        }));
        // Add the static "Add product" option
        // const addProductOption = {
        //     label: '+ Add product',
        //     value: 'add_product',
        //     className: "btn btn-dark",
        //     isAddButton: true
        // };

        // Combine API product options with the static "Add product" option
        setOptions([...products]);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProductData();
  }, []);

  useEffect(() => {
    if (showForm === true) {
      setCardContent("hii");
      setActiveTab("tab1");
    } else {
      setCardContent(null)
    }
  }, [showForm]);

  // console.log(options);

  // const options = [
  //     { label: 'iCruze Comfy Wireless Headphone (Black)', value: 'product1' },
  //     { label: 'iCruze Carnival BT Party Speaker', value: 'product2' },
  //     { label: 'iCruze Pronto Vista BT Calling Smart watch (Blue)', value: 'product3' },
  //     { label: 'iCruze Modpack Travel Backpack (Russet Red)', value: 'product4' },
  //     { label: '+ Add product', value: 'add_product', className: "btn btn-dark", isAddButton: true } // Special option for "Add product"
  // ];
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;

    return data.isAddButton ? (
      <div
        ref={innerRef}
        {...innerProps}
        className="custom-add-product-option "
        onClick={() => setShowModal(true)}
      >
        <div
          className="text-center mt-1"
          style={{ color: "rgba(162, 162, 164, 1)" }}
        >
          {options.length > 1 ? "or" : "No products added"}
        </div>
        <Button
          variant="dark"
          className="mt-2 ms-1"
          style={{ position: "fixed" }}
        >
          {data.label}
        </Button>
      </div>
    ) : (
      <components.Option {...props} />
    );
  };
  const MenuList = (props) => {
    return (
      <>
        {options.length > 0 && (
          <components.MenuList {...props}>
            {/* Ensure that scrolling is enabled for the options */}
            <div>{props.children}</div>
          </components.MenuList>
        )}

        <div
          className="text-center mt-1"
          style={{ color: "rgba(162, 162, 164, 1)" }}
        >
          {options.length > 0 ? (
            "or"
          ) : (
            <span className="p-1">No products added</span>
          )}
        </div>

        <button
          className="btn btn-dark custom-add-product-option m-2"
          onClick={() => setShowModal(true)}
        >
          Add product
        </button>
      </>
    );
  };

  const handleCopyClick = () => {
    const textToCopy = document.getElementById("text-to-copy").value;

    navigator.clipboard
      .writeText(textToCopy)

      .then(() => {
        toast.success("Link copied to clipboard!");
        setCopied(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const textRef = useRef(null);
  // const handleCopyClick23 = () => {

  //     if (textRef.current) {
  //         // Extract text content from the div
  //         const textToCopy = textRef.current.innerText;

  //         // Copy text to clipboard
  //         navigator.clipboard.writeText(textToCopy)
  //             .then(() => {
  //                 toast.success("Mail copied to clipboard!");
  //             })
  //             .catch(err => {
  //                 toast.error('Failed to copy text: ', err);
  //             });
  //     }
  // };

  const handleCopyClick2 = () => {
    const range = document.createRange();
    range.selectNodeContents(textRef.current);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Failed to copy", err);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      // Call your function here when the selection is cleared
      setSelectedOption(null)
      setShowForm(false)
    } else {
      if (selectedOption && selectedOption.isAddButton) {
        setShowModal(true);
      } else {
        setSelectedOption(selectedOption);
        setShowForm(true);
      }
    }

  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleIconClick = (content) => {
    setCardContent(content); // Update the card content based on clicked icon
  };

  const customStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        padding: 10,
        backgroundColor: state.isSelected
          ? "rgba(238, 238, 119, 1)" // Yellow background when selected
          : state.isFocused
            ? "rgba(255, 255, 204, 0.6)"
            : "white",
        color: "rgba(162, 162, 164, 1)",
        borderBottom: "1px solid rgba(209, 209, 210, 1)",
        position: state.data.value === "last" ? "sticky" : "static",
        cursor: "pointer",
        // Option styling
      };
    },
    menu: (provided) => ({
      ...provided,
      border: "1px solid rgba(209, 209, 210, 1)",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(69, 69, 73, 1)",
      cursor: "pointer",
    }),
  };

  const [selectedTemplate, setSelectedTemplate] = useState();
  const [previousIndex, setPreviousIndex] = useState(null);

  const templateNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const templateNumber2 = [1, 2, 3, 4, 5];
  const templateNumbersms = [1, 2, 3, 4, 5, 6];
  const templateNumbersms2 = [1, 2, 3, 4, 5, 6, 7];
  const selectRandomTemplate = () => {
    let randomIndex;
    if (tab === "professional") {
      do {
        randomIndex = Math.floor(Math.random() * templateNumbers.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumbers[randomIndex];
      const templateContent = ProfessionalTemplate(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    } else if (tab === "playful") {
      do {
        randomIndex = Math.floor(Math.random() * templateNumber2.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumber2[randomIndex];
      const templateContent = Casual(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    } else {
      do {
        randomIndex = Math.floor(Math.random() * templateNumber2.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumber2[randomIndex];
      const templateContent = Whimsical(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    }
  };
  const selectRandomTemplate2 = () => {
    let randomIndex;
    if (tabsms === "professionals") {
      do {
        randomIndex = Math.floor(Math.random() * templateNumbersms.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumbersms[randomIndex];
      const templateContent = Professionalsms(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    } else if (tabsms === "playfuls") {
      do {
        randomIndex = Math.floor(Math.random() * templateNumbersms.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumbersms[randomIndex];
      const templateContent = Casualsms(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    } else {
      do {
        randomIndex = Math.floor(Math.random() * templateNumbersms2.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumbersms2[randomIndex];
      const templateContent = Whimsicalsms(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    }
  };

  useEffect(() => {
    if (activeTab == "tab1") {
      selectRandomTemplate();
    } else {
      selectRandomTemplate2();
    }
  }, [selectedOption, tab, tabsms, activeTab]);

  return (
    <div style={{ overflow: "auto", height: "calc(98vh - 180px)" }}>
      <div className="review-form">
        <form>
          {/* <label htmlFor="reviewName" className="m-2" style={{ fontSize: "14px", color: "rgba(69, 69, 73, 1)", lineHeight: "20px", fontWeight: '450' }}>Enter PDP URL</label> */}
          <div className="form-group">
            <label
              htmlFor="productDropdown"
              className="mt-1 mb-2 ms-1"
              style={{ fontSize: "14px", color: "rgba(69, 69, 73, 1)" }}
            >
              Select product
            </label>
            <Select
              id="productDropdown"
              options={options}
              onChange={handleSelectChange}
              components={{ MenuList }}
              styles={customStyles}
              placeholder="Select product or Enter PDP URL"
              isClearable={true}
            />
          </div>
          <small
            className="p-0 m-0"
            style={{ fontSize: "12px", color: "rgba(69, 69, 73, 1)" }}
          >
            Generating a Link for Video UGC Compilation
          </small>
        </form>
      </div>

      {showForm && (
        <div className="" style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1, position: "relative" }}>
            <label
              className="ms-1 mt-4"
              style={{ fontSize: "14px", color: "rgba(69, 69, 73, 1)" }}
            >
              Video review link
            </label>
            <input
              type="text"
              id="text-to-copy"
              className="form-control mt-2"
              value={
                selectedOption
                  ? `${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}`
                  : ""
              }
              placeholder="Enter the link"
              style={{ width: "98%" }}
            />
            <span
              className="position-absolute"
              style={{
                right: "28px",
                top: "80%",
                transform: "translateY(-50%)",
              }}
            >
              <i
                className="ri-file-copy-line"
                style={{ cursor: "pointer" }}
                onClick={handleCopyClick}
              ></i>
            </span>
          </div>

          <div className="mt-4 " style={{ display: "flex", gap: "1rem" }}>
            <i
              className="ri-mail-line mt-4"
              style={{
                backgroundColor:
                  activeTab === "tab1"
                    ? "rgba(187, 221, 255, 1)"
                    : "rgba(232, 232, 232, 1)",
                borderRadius: "50%",
                padding: "9px 12px 8px",
                color: "rgba(69, 69, 73, 1)",
                cursor: "pointer",
              }}
              onClick={() => {
                handleIconClick("hii");
                setActiveTab("tab1");
              }}
            ></i>
            <i
              className="ri-chat-3-line mt-4"
              style={{
                backgroundColor:
                  activeTab === "tab2"
                    ? "rgba(187, 221, 255, 1)"
                    : "rgba(232, 232, 232, 1)",
                borderRadius: "50%",
                padding: "9px 12px 0px",
                color: "rgba(69, 69, 73, 1)",
                cursor: "pointer",
              }}
              onClick={() => {
                handleIconClick("bye");
                setActiveTab("tab2");
              }}
            ></i>
          </div>
        </div>
      )}
      {cardContent && (
        <Card className="mt-5">
          <Card.Body>
            {cardContent === "hii" ? (
              <>
                <div>
                  <div className="col p-3">
                    <h4
                      className="ms-2 p-1"
                      style={{ fontSize: "24px", fontWeight: "500" }}
                    >
                      Emailer Template
                    </h4>
                    <div className="d-flex ">
                      <ul
                        className="nav nav-tabs mail"
                        id="responsive-tabs-ssv"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ms-3 ps-0 ${tab === "professional" ? "active" : ""
                              }`}
                            id="professional-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#professional"
                            type="button"
                            role="tab"
                            aria-controls="professional"
                            aria-selected={tab === "professional"}
                            onClick={() => setTab("professional")}
                          >
                            Professional
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${tab === "playful" ? "active" : ""
                              }`}
                            id="playful-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#playful"
                            type="button"
                            role="tab"
                            aria-controls="playful"
                            aria-selected={tab === "playful"}
                            onClick={() => setTab("playful")}
                          >
                            Casual
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${tab === "Relaxed" ? "active" : ""
                              }`}
                            id="Relaxed-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Relaxed"
                            type="button"
                            role="tab"
                            aria-controls="Relaxed"
                            aria-selected={tab === "Relaxed"}
                            onClick={() => setTab("Relaxed")}
                          >
                            Whimsical
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="tab-content p-2 ms-1"
                      id="responsive-tabs-content"
                    >
                      {tab === "professional" && (
                        <div
                          className="tab-pane fade show active h-100"
                          id="professional"
                          role="tabpanel"
                          aria-labelledby="professional-tab"
                        >
                          <Card
                            className="mt-3 p-4"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}
                          >
                            <Card.Body style={{ flex: 1 }}>
                              <div
                                id="text-to-copy"
                                ref={textRef}
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(102, 112, 133, 1)",
                                  marginTop: "20px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    selectedTemplate ||
                                    "Click the button to generate an email template.",
                                }}
                              />
                            </Card.Body>
                            <div
                              style={{
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <i
                                className="ri-file-copy-line"
                                onClick={handleCopyClick2}
                                style={{
                                  fontSize: "15px",
                                  backgroundColor: "rgba(232, 232, 232, 1)",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                  padding: "10px 14px",
                                }}
                              ></i>
                              <i
                                className="ri-refresh-line"
                                onClick={selectRandomTemplate}
                                style={{
                                  fontSize: "20px",
                                  backgroundColor: "rgba(232, 232, 232, 1)",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                  padding: "5px 9px",
                                }}
                              ></i>
                            </div>
                          </Card>
                        </div>
                      )}
                      {tab === "playful" && (
                        <div
                          className="tab-pane fade show active h-100"
                          id="playful"
                          role="tabpanel"
                          aria-labelledby="playful-tab"
                        >
                          <Card
                            className="mt-3 p-4"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}
                          >
                            <Card.Body style={{ flex: 1 }}>
                              <div
                                id="text-to-copy"
                                ref={textRef}
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(102, 112, 133, 1)",
                                  marginTop: "20px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    selectedTemplate ||
                                    "Click the button to generate an email template.",
                                }}
                              />
                            </Card.Body>
                            <div
                              style={{
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <i
                                className="ri-file-copy-line"
                                onClick={handleCopyClick2}
                                style={{
                                  fontSize: "15px",
                                  backgroundColor: "rgba(232, 232, 232, 1)",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                  padding: "10px 14px",
                                }}
                              ></i>
                              <i
                                className="ri-refresh-line"
                                onClick={selectRandomTemplate}
                                style={{
                                  fontSize: "20px",
                                  backgroundColor: "rgba(232, 232, 232, 1)",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                  padding: "5px 9px",
                                }}
                              ></i>
                            </div>
                          </Card>
                        </div>
                      )}
                      {tab === "Relaxed" && (
                        <div
                          className="tab-pane fade show active h-100"
                          id="Relaxed"
                          role="tabpanel"
                          aria-labelledby="Relaxedtab"
                        >
                          <Card
                            className="mt-3 p-4"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}
                          >
                            <Card.Body style={{ flex: 1 }}>
                              <div
                                id="text-to-copy"
                                ref={textRef}
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(102, 112, 133, 1)",
                                  marginTop: "20px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    selectedTemplate ||
                                    "Click the button to generate an email template.",
                                }}
                              />
                            </Card.Body>
                            <div
                              style={{
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <i
                                className="ri-file-copy-line"
                                onClick={handleCopyClick2}
                                style={{
                                  fontSize: "15px",
                                  backgroundColor: "rgba(232, 232, 232, 1)",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                  padding: "10px 14px",
                                }}
                              ></i>
                              {/* <i className="ri-refresh-line" style={{ fontSize: '20px', backgroundColor: 'rgba(232, 232, 232, 1)', cursor: 'pointer', borderRadius: '50%', padding: "5px 9px" }}></i> */}
                              <i
                                className="ri-refresh-line"
                                onClick={selectRandomTemplate}
                                style={{
                                  fontSize: "20px",
                                  backgroundColor: "rgba(232, 232, 232, 1)",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                  padding: "5px 9px",
                                }}
                              ></i>
                            </div>
                          </Card>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div className="col p-3">
                  <h4
                    className="ms-2 p-1"
                    style={{ fontSize: "24px", fontWeight: "500" }}
                  >
                    SMS Template
                  </h4>
                  <div className="d-flex ">
                    <ul
                      className="nav nav-tabs sms"
                      id="responsive-tabs-ssv"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ms-3 ps-0 ${tabsms === "professionals" ? "active" : ""
                            }`}
                          id="professionals-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#professionals"
                          type="button"
                          role="tab"
                          aria-controls="professionals"
                          aria-selected={tabsms === "professionals"}
                          onClick={() => setTabsms("professionals")}
                        >
                          Professional
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${tabsms === "playfuls" ? "active" : ""
                            }`}
                          id="playfuls-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#playfuls"
                          type="button"
                          role="tab"
                          aria-controls="playfuls"
                          aria-selected={tabsms === "playfuls"}
                          onClick={() => setTabsms("playfuls")}
                        >
                          Casual
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${tab === "Relaxeds" ? "active" : ""
                            }`}
                          id="Relaxeds-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Relaxeds"
                          type="button"
                          role="tab"
                          aria-controls="Relaxeds"
                          aria-selected={tabsms === "Relaxeds"}
                          onClick={() => setTabsms("Relaxeds")}
                        >
                          Whimsical
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content p-2 ms-1"
                    id="responsive-tabs-content"
                  >
                    {tabsms === "professionals" && (
                      <div
                        className="tab-pane fade show active h-100"
                        id="professionals"
                        role="tabpanel"
                        aria-labelledby="professionals-tab"
                      >
                        <Card
                          className="mt-3 p-4"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // height: "355px",
                          }}
                        >
                          <div
                            id="text-to-copy"
                            ref={textRef}
                            style={{
                              fontSize: "16px",
                              color: "rgba(102, 112, 133, 1)",
                              marginTop: "20px",
                              paddingRight: "100px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                selectedTemplate ||
                                "Click the button to generate an email template.",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                              display: "flex",
                              gap: "1rem",
                            }}
                          >
                            <i
                              className="ri-file-copy-line"
                              onClick={handleCopyClick2}
                              style={{
                                fontSize: "15px",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                cursor: "pointer",
                                borderRadius: "50%",
                                padding: "10px 14px",
                              }}
                            ></i>
                            <i
                              className="ri-refresh-line"
                              onClick={selectRandomTemplate2}
                              style={{
                                fontSize: "20px",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                cursor: "pointer",
                                borderRadius: "50%",
                                padding: "5px 9px",
                              }}
                            ></i>
                          </div>
                        </Card>
                      </div>
                    )}
                    {tabsms === "playfuls" && (
                      <div
                        className="tab-pane fade show active h-100"
                        id="playfuls"
                        role="tabpanel"
                        aria-labelledby="playfuls-tab"
                      >
                        <Card
                          className="mt-3 p-4"
                          style={{
                            display: "flex",
                            flexDirection: "column",

                            // height: "355px",
                          }}
                        >
                          <div
                            id="text-to-copy"
                            ref={textRef}
                            style={{
                              fontSize: "16px",
                              color: "rgba(102, 112, 133, 1)",
                              marginTop: "20px",
                              paddingRight: "100px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                selectedTemplate ||
                                "Click the button to generate an email template.",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                              display: "flex",
                              gap: "1rem",
                            }}
                          >
                            <i
                              className="ri-file-copy-line"
                              onClick={handleCopyClick2}
                              style={{
                                fontSize: "15px",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                cursor: "pointer",
                                borderRadius: "50%",
                                padding: "10px 14px",
                              }}
                            ></i>
                            <i
                              className="ri-refresh-line"
                              onClick={selectRandomTemplate2}
                              style={{
                                fontSize: "20px",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                cursor: "pointer",
                                borderRadius: "50%",
                                padding: "5px 9px",
                              }}
                            ></i>
                          </div>
                        </Card>
                      </div>
                    )}
                    {tabsms === "Relaxeds" && (
                      <div
                        className="tab-pane fade show active h-100"
                        id="Relaxeds"
                        role="tabpanel"
                        aria-labelledby="Relaxeds-tab"
                      >
                        <Card
                          className="mt-3 p-4"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // height: "355px",
                          }}
                        >
                          <div
                            id="text-to-copy"
                            ref={textRef}
                            style={{
                              fontSize: "16px",
                              color: "rgba(102, 112, 133, 1)",
                              marginTop: "20px",
                              paddingRight: "100px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                selectedTemplate ||
                                "Click the button to generate an email template.",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                              display: "flex",
                              gap: "1rem",
                            }}
                          >
                            <i
                              className="ri-file-copy-line"
                              onClick={handleCopyClick2}
                              style={{
                                fontSize: "15px",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                cursor: "pointer",
                                borderRadius: "50%",
                                padding: "10px 14px",
                              }}
                            ></i>
                            <i
                              className="ri-refresh-line"
                              onClick={selectRandomTemplate2}
                              style={{
                                fontSize: "20px",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                cursor: "pointer",
                                borderRadius: "50%",
                                padding: "5px 9px",
                              }}
                            ></i>
                          </div>
                        </Card>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      )}

      {showModal && (
        <ReviewModal
          isOpen={showModal}
          setSelectedOption={setSelectedOption}
          onClose={handleCloseModal}
          setShowForm={setShowForm}
        />
      )}
    </div>
  );
};

export default Createreview;
