import React, { useState } from 'react';
import Select from 'react-select';
const ProductSync = () => {
  const [websiteurl, setWebsiteurl] = useState();
  const [accesstoken, setAccessToken] = useState();
  const [storeurl, setStoreurl] = useState();
  const [collectionid, setCollectionId] = useState();
  const [limit, setLimit] = useState('');
  return (
    <div className="container mt-3 row">
      <div className="col-12">
        <div className="row mt-2">
          <div className="form-group">
            <label>
              Website url<sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Enter Website url"
              name="websiteurl"
              value={websiteurl}
              onChange={(e) => setWebsiteurl(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mt-2">
            <label>
              Access Token<sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Enter Access Token"
              name="accesstoken"
              value={accesstoken}
              onChange={(e) => setAccessToken(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mt-2">
            <label>
              Store Url<sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Enter Shopify Store url"
              name="storeurl"
              value={storeurl}
              onChange={(e) => setStoreurl(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mt-2">
            <label> Collection Id</label>
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Enter Collection Id"
              name="collection id"
              value={collectionid}
              onChange={(e) => setCollectionId(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mt-2">
            <label htmlFor="exampleFormControlSelect1">Limit</label>
            <select
              className="form-control mt-2"
              id="exampleFormControlSelect1"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
            >
              <option value="">Choose Limit</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-2">
            <button className="btn btn-dark">Sync</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSync;
