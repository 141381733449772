import React, { useState, useEffect } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { loginSuccess } from '../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import { allApiInstance } from '../../axiosInstances';

const AccessToken = () => {
  const [sync, setsync] = useState(false);
  const [synccode, setsynccode] = useState('tgmwb');
  const [id, setId] = useState('');
  // const [brandId, setBrandId] = useState("");
  // const [uploadKey, setUploadKey] = useState('');
  // const [customization, setCustomization] = useState('');
  const [copied, setCopied] = useState({
    synccode: false,
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [fetchedProfileDataat, setFetchedProfileDataat] = useState('');
  const [accesstoken, SetaccessToken] = useState({
    brandId: '',
    uploadKey: '',
    customization: '',
    mixpanelId: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    SetaccessToken((prevInput) => {
      const updatedInp = {
        ...prevInput,
        [name]: value,
      };
      return updatedInp;
    });
  };
  const handleSyncChange = (e) => {
    setsync(e.target.checked);
  };

  const handleCopy = (field) => {
    setCopied({ ...copied, [field]: true });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied({
        synccode: false,
      });
    }, 2000);

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts or copied state changes
  }, [copied]);

  const fetchaccestokenProfileData = async () => {
    try {
      const response = await allApiInstance.get(
        `/getVyrillInfoByBrandId?brandId=${user.brand_id}`
      );

      if (response.data) {
        const data = response.data;
        setFetchedProfileDataat(data);
        const vyrillKeyInfo = data.response;
        SetaccessToken({
          brandId: vyrillKeyInfo?.vyrillBrandId,
          uploadKey: vyrillKeyInfo?.xApiKey,
          customization: vyrillKeyInfo?.authKey,
          mixpanelId: vyrillKeyInfo?.mixPanelId,
        });
        setId(vyrillKeyInfo?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchaccestokenProfileData();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await allApiInstance
        .post('/insertOrUpdateVyrillInfoByBrandId', {
          vyrillKeyInfo: {
            brandId: user.brand_id,
            vyrillBrandId: accesstoken.brandId,
            xApiKey: accesstoken.uploadKey,
            authKey: accesstoken.customization,
            mixPanelId: accesstoken.mixpanelId,
            id: id,
          },
        })
        .then((res) => {
          if (res.data) {
            toast.success('Updated');
            dispatch(
              loginSuccess({
                ...user,
                vyrill_brand_id: accesstoken.brandId,
                xApiKey: accesstoken.uploadKey,
                fetchKey: accesstoken.customization,
                mix_panel_id: accesstoken.mixpanelId,
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  return (
    <div className="d-flex">
      <div className="col-12">
        {/* <div className='card row m-2'>
                    <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
                        <div>
                            <label className="fs-18 form-check-label ps-3 p-3" htmlFor="sync">
                                Shopify Sync
                            </label>
                        </div>
                        <div>
                            <input
                                className="form-check-input switch-lg"
                                type="checkbox"
                                role="switch"
                                id="sync"
                                checked={sync}
                                onChange={handleSyncChange}
                            />
                        </div>
                    </div>
                    {sync &&
                        <div className='row ms-2'>
                            <div className="form-group mt-2">
                                <label>Code</label>
                                <CopyToClipboard text={synccode} onCopy={() => handleCopy('synccode')}>
                                    <div className='input-group mb-2'>
                                        <input
                                            type="text"
                                            className="form-control mt-2 "
                                            name="synccode"
                                            value={synccode}
                                            onChange={(e) => setsynccode(e.target.value)}
                                            readOnly
                                        />
                                        <button
                                            className="btn btn-outline-secondary mt-2 input-group-text"

                                        >
                                            {copied.synccode ? <FaCheck /> : <FaCopy />}
                                        </button>
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </div>}
                </div> */}

        <div className="card row mt-3 m-2">
          <h3 className="fs-18 ms-2 mt-2">Brand API Credentials</h3>
          <form onSubmit={handleSubmit}>
            <div className="row ms-2">
              <div>
                <label className="fs-14 form-group mt-2" htmlFor="brandId">
                  Brand ID <sup className="text-danger">*</sup>
                </label>
              </div>
              <div>
                <input
                  placeholder="Brand ID"
                  className="form-control mt-2"
                  id="brandId"
                  name="brandId"
                  value={accesstoken.brandId}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="row ms-2">
              <div className="fs-14 form-group mt-2">
                <label>
                  Upload Key <sup className="text-danger">*</sup>
                </label>
                <input
                  placeholder="Upload Key"
                  className="form-control mt-2"
                  name="uploadKey"
                  value={accesstoken.uploadKey}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="row ms-2">
              <div className="fs-14 form-group mt-2 mb-3">
                <label>
                  Authorization key <sup className="text-danger">*</sup>
                </label>
                <input
                  className="form-control mt-2"
                  name="customization"
                  placeholder="Authorization Key"
                  value={accesstoken.customization}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="row ms-2">
              <div>
                <label className="fs-14 form-group" htmlFor="brandId">
                  Mix Panel Project ID <sup className="text-danger">*</sup>
                </label>
              </div>
              <div>
                <input
                  placeholder="Mix Panel Project ID"
                  className="form-control mt-2"
                  id="mixpanelId"
                  name="mixpanelId"
                  value={accesstoken.mixpanelId}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div>
              <button type="submit" className="btn btn-dark m-3">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccessToken;
