import React, { useEffect, useState } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';
import ModalContactSwirl from './Modal'; // Import the Modal component
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { loginSuccess } from '../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import { allApiInstance } from '../../axiosInstances';
const Profileproducts = () => {
  const [Shoppable, SetShoppable] = useState(false);
  const [code, setCode] = useState('');
  const [livestream, setlivestream] = useState(false);
  const [code2] = useState('ABC');
  const [superadmin, setSuperadmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState({
    code: false,
    code2: false,
  });

  const handleChange = (e) => {
    SetShoppable(e.target.checked);
  };

  const handleChange2 = (e) => {
    setlivestream(e.target.checked);
  };

  const handleChangeadmin = (e) => {
    setSuperadmin(e.target.checked);
  };

  const handleCopy = (field) => {
    setCopied({ ...copied, [field]: true });
  };

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmModal = () => {
    setShowModal(false);
    // Perform your sub domain creation logic here
    console.log('Sub Domain Created');
  };
  const isVerified = false;
  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied({
        code: false,
        code2: false,
      });
    }, 2000);

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts or copied state changes
  }, [copied]);

  const user = useSelector((state) => state.auth.user);
  const shortCode = useSelector((state) => state.auth.user.short_code)
  // const getShortCodeData = async () => {
  //   try {
  //     await allApiInstance
  //       .get(
  //         `/getShortCodeForProfile?brandId=${user.brand_id}`
  //       )
  //       .then((res) => {
  //         if (res.data) {
  //           const data = res.data[0];
  //           SetShoppable(data.status == 1 ? true : false);
  //           setCode(data.code);
  //         }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  useEffect(() => {

    if (user.short_code_status === 0) {
      SetShoppable(false)
    } else {
      SetShoppable(true)
    }
    if (shortCode) {
      // SetShoppable(true)
      setCode(shortCode)
    }
  }, [shortCode, user])


  function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    const length = 8;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }

    return randomString;
  }
  const dispatch = useDispatch()
  const updateShortCode = async () => {
    try {

      if (shortCode) {
        await allApiInstance
          .patch('/updateStatusForShoppableVideo', {
            payload: {
              brandId: user.brand_id,
              status: user.short_code_status === 1 ? 0 : 1,
            },
          })
          .then((res) => {
            if (res.data) {
              toast.success('Updated ');

              dispatch(loginSuccess({
                ...user,
                short_code_status: user.short_code_status === 1 ? 0 : 1
              }))
            }
          });
      } else {
        await allApiInstance
          .post('/createOrUpdateShortCode', {

            shortCode: generateRandomString(),
            brandId: user.brand_id,

          })
          .then((res) => {
            if (res.data) {
              toast.success('Updated');
              // console.log("res.data", res.data.sscResponse.code);
              dispatch(
                loginSuccess({
                  ...user,
                  short_code: res.data.sscResponse.code,
                  short_code_status: 1
                })
              );
              setCode(res.data.sscResponse.code)
              SetShoppable(true)
              // getShortCodeData();
            }
          });
      }

    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  // useEffect(() => {
  //   getShortCodeData();
  // }, [user]);


  return (
    <div>
      <div className="card row mb-3 m-1">
        <div className="col-12 p-3">
          <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
            <div>
              <label
                className="fs-18 form-check-label ps-2"
                htmlFor="Shoppable"
              >
                Shoppable Video
              </label>
            </div>
            <div>
              <input
                className="form-check-input switch-lg"
                type="checkbox"
                role="switch"
                id="Shoppable"
                checked={Shoppable}
                onChange={updateShortCode}
              />
            </div>
          </div>
          {Shoppable && (
            <div className="row ms-2">
              <div className="form-group mt-2">
                <div>
                  <label className="me-2">Code</label>
                </div>

                <CopyToClipboard text={code} onCopy={() => handleCopy('code')}>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control mt-2"
                      name="code"
                      value={code}
                      readOnly
                    />
                    <button className="btn btn-outline-secondary mt-2 input-group-text">
                      {copied.code ? <FaCheck /> : <FaCopy />}
                    </button>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className='card row m-1'>
                <div className='col-12 p-3'>
                    <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
                        <div>
                            <label className="fs-18 form-check-label ps-3" htmlFor="livestream">
                                LiveStream
                            </label>
                        </div>
                        <div>
                            <input
                                className="form-check-input switch-lg"
                                type="checkbox"
                                role="switch"
                                id="livestream"
                                checked={livestream}
                                onChange={handleChange2}
                            />
                        </div>
                    </div>
                    {livestream &&
                        <div className='row ms-2'>
                            <div className="form-group mt-2">
                                <label className='me-2'>Code</label>
                                <CopyToClipboard text={code2} onCopy={() => handleCopy('code2')}>
                                    <div className='input-group'>
                                        <input
                                            type="text"
                                            className="form-control mt-2"
                                            name="code2"
                                            value={code2}
                                            readOnly
                                        />
                                        <button
                                            className="btn btn-outline-secondary mt-2 input-group-text"

                                        >
                                            {copied.code2 ? <FaCheck /> : <FaCopy />}
                                        </button>
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </div>}
                </div>
            </div> */}
      <div className="card row mt-3 m-1 p-2">
        <div
          className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0"
          onClick={() => {
            if (!isVerified) {
              setShowModal(true);
            }
          }}
        >
          <div>
            <label className="fs-18 form-check-label ps-3" htmlFor="superadmin">
              Super admin
              <br />
              <div className="fs-14">
                You can create sub-admin roles to delegate important tasks.
              </div>
            </label>
          </div>
          <div>
            <input
              className="form-check-input switch-lg"
              type="checkbox"
              role="switch"
              id="superadmin"
              checked={superadmin}
              onChange={handleChangeadmin}
              disabled={!isVerified}
            />
          </div>
        </div>
        {superadmin && isVerified && (
          <div>
            <button
              className="btn btn-dark mt-2 mb-2"
              onClick={handleButtonClick}
            >
              Create sub Domain
            </button>
          </div>
        )}
      </div>
      <ModalContactSwirl
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
      />
    </div>
  );
};

export default Profileproducts;
