const countryCodes = [
  { country: 'Afghanistan', code: 'AF', phoneCode: '+93' },
  { country: 'Albania', code: 'AL', phoneCode: '+355' },
  { country: 'Algeria', code: 'DZ', phoneCode: '+213' },
  { country: 'Andorra', code: 'AD', phoneCode: '+376' },
  { country: 'Angola', code: 'AO', phoneCode: '+244' },
  { country: 'Antigua and Barbuda', code: 'AG', phoneCode: '+1' },
  { country: 'Argentina', code: 'AR', phoneCode: '+54' },
  { country: 'Armenia', code: 'AM', phoneCode: '+374' },
  { country: 'Australia', code: 'AU', phoneCode: '+61' },
  { country: 'Austria', code: 'AT', phoneCode: '+43' },
  { country: 'Azerbaijan', code: 'AZ', phoneCode: '+994' },
  { country: 'Bahamas', code: 'BS', phoneCode: '+1' },
  { country: 'Bahrain', code: 'BH', phoneCode: '+973' },
  { country: 'Bangladesh', code: 'BD', phoneCode: '+880' },
  { country: 'Barbados', code: 'BB', phoneCode: '+1' },
  { country: 'Belarus', code: 'BY', phoneCode: '+375' },
  { country: 'Belgium', code: 'BE', phoneCode: '+32' },
  { country: 'Belize', code: 'BZ', phoneCode: '+501' },
  { country: 'Benin', code: 'BJ', phoneCode: '+229' },
  { country: 'Bhutan', code: 'BT', phoneCode: '+975' },
  { country: 'Bolivia', code: 'BO', phoneCode: '+591' },
  { country: 'Bosnia and Herzegovina', code: 'BA', phoneCode: '+387' },
  { country: 'Botswana', code: 'BW', phoneCode: '+267' },
  { country: 'Brazil', code: 'BR', phoneCode: '+55' },
  { country: 'Brunei', code: 'BN', phoneCode: '+673' },
  { country: 'Bulgaria', code: 'BG', phoneCode: '+359' },
  { country: 'Burkina Faso', code: 'BF', phoneCode: '+226' },
  { country: 'Burundi', code: 'BI', phoneCode: '+257' },
  { country: 'Cambodia', code: 'KH', phoneCode: '+855' },
  { country: 'Cameroon', code: 'CM', phoneCode: '+237' },
  { country: 'Canada', code: 'CA', phoneCode: '+1' },
  { country: 'Cape Verde', code: 'CV', phoneCode: '+238' },
  { country: 'Central African Republic', code: 'CF', phoneCode: '+236' },
  { country: 'Chad', code: 'TD', phoneCode: '+235' },
  { country: 'Chile', code: 'CL', phoneCode: '+56' },
  { country: 'China', code: 'CN', phoneCode: '+86' },
  { country: 'Colombia', code: 'CO', phoneCode: '+57' },
  { country: 'Comoros', code: 'KM', phoneCode: '+269' },
  { country: 'Congo', code: 'CG', phoneCode: '+242' },
  { country: 'Costa Rica', code: 'CR', phoneCode: '+506' },
  { country: 'Croatia', code: 'HR', phoneCode: '+385' },
  { country: 'Cuba', code: 'CU', phoneCode: '+53' },
  { country: 'Cyprus', code: 'CY', phoneCode: '+357' },
  { country: 'Czech Republic', code: 'CZ', phoneCode: '+420' },
  { country: 'Denmark', code: 'DK', phoneCode: '+45' },
  { country: 'Djibouti', code: 'DJ', phoneCode: '+253' },
  { country: 'Dominica', code: 'DM', phoneCode: '+1' },
  { country: 'Dominican Republic', code: 'DO', phoneCode: '+1' },
  { country: 'East Timor', code: 'TL', phoneCode: '+670' },
  { country: 'Ecuador', code: 'EC', phoneCode: '+593' },
  { country: 'Egypt', code: 'EG', phoneCode: '+20' },
  { country: 'El Salvador', code: 'SV', phoneCode: '+503' },
  { country: 'Equatorial Guinea', code: 'GQ', phoneCode: '+240' },
  { country: 'Eritrea', code: 'ER', phoneCode: '+291' },
  { country: 'Estonia', code: 'EE', phoneCode: '+372' },
  { country: 'Ethiopia', code: 'ET', phoneCode: '+251' },
  { country: 'Fiji', code: 'FJ', phoneCode: '+679' },
  { country: 'Finland', code: 'FI', phoneCode: '+358' },
  { country: 'France', code: 'FR', phoneCode: '+33' },
  { country: 'Gabon', code: 'GA', phoneCode: '+241' },
  { country: 'Gambia', code: 'GM', phoneCode: '+220' },
  { country: 'Georgia', code: 'GE', phoneCode: '+995' },
  { country: 'Germany', code: 'DE', phoneCode: '+49' },
  { country: 'Ghana', code: 'GH', phoneCode: '+233' },
  { country: 'Greece', code: 'GR', phoneCode: '+30' },
  { country: 'Grenada', code: 'GD', phoneCode: '+1' },
  { country: 'Guatemala', code: 'GT', phoneCode: '+502' },
  { country: 'Guinea', code: 'GN', phoneCode: '+224' },
  { country: 'Guinea-Bissau', code: 'GW', phoneCode: '+245' },
  { country: 'Guyana', code: 'GY', phoneCode: '+592' },
  { country: 'Haiti', code: 'HT', phoneCode: '+509' },
  { country: 'Honduras', code: 'HN', phoneCode: '+504' },
  { country: 'Hungary', code: 'HU', phoneCode: '+36' },
  { country: 'Iceland', code: 'IS', phoneCode: '+354' },
  { country: 'India', code: 'IN', phoneCode: '+91' },
  { country: 'Indonesia', code: 'ID', phoneCode: '+62' },
  { country: 'Iran', code: 'IR', phoneCode: '+98' },
  { country: 'Iraq', code: 'IQ', phoneCode: '+964' },
  { country: 'Ireland', code: 'IE', phoneCode: '+353' },
  { country: 'Israel', code: 'IL', phoneCode: '+972' },
  { country: 'Italy', code: 'IT', phoneCode: '+39' },
  { country: 'Jamaica', code: 'JM', phoneCode: '+1' },
  { country: 'Japan', code: 'JP', phoneCode: '+81' },
  { country: 'Jordan', code: 'JO', phoneCode: '+962' },
  { country: 'Kazakhstan', code: 'KZ', phoneCode: '+7' },
  { country: 'Kenya', code: 'KE', phoneCode: '+254' },
  { country: 'Kiribati', code: 'KI', phoneCode: '+686' },
  { country: 'Korea, North', code: 'KP', phoneCode: '+850' },
  { country: 'Korea, South', code: 'KR', phoneCode: '+82' },
  { country: 'Kuwait', code: 'KW', phoneCode: '+965' },
  { country: 'Kyrgyzstan', code: 'KG', phoneCode: '+996' },
  { country: 'Laos', code: 'LA', phoneCode: '+856' },
  { country: 'Latvia', code: 'LV', phoneCode: '+371' },
  { country: 'Lebanon', code: 'LB', phoneCode: '+961' },
  { country: 'Lesotho', code: 'LS', phoneCode: '+266' },
  { country: 'Liberia', code: 'LR', phoneCode: '+231' },
  { country: 'Libya', code: 'LY', phoneCode: '+218' },
  { country: 'Liechtenstein', code: 'LI', phoneCode: '+423' },
  { country: 'Lithuania', code: 'LT', phoneCode: '+370' },
  { country: 'Luxembourg', code: 'LU', phoneCode: '+352' },
  { country: 'Macedonia', code: 'MK', phoneCode: '+389' },
  { country: 'Madagascar', code: 'MG', phoneCode: '+261' },
  { country: 'Malawi', code: 'MW', phoneCode: '+265' },
  { country: 'Malaysia', code: 'MY', phoneCode: '+60' },
  { country: 'Maldives', code: 'MV', phoneCode: '+960' },
  { country: 'Mali', code: 'ML', phoneCode: '+223' },
  { country: 'Malta', code: 'MT', phoneCode: '+356' },
  { country: 'Marshall Islands', code: 'MH', phoneCode: '+692' },
  { country: 'Mauritania', code: 'MR', phoneCode: '+222' },
  { country: 'Mauritius', code: 'MU', phoneCode: '+230' },
  { country: 'Mexico', code: 'MX', phoneCode: '+52' },
  { country: 'Micronesia', code: 'FM', phoneCode: '+691' },
  { country: 'Moldova', code: 'MD', phoneCode: '+373' },
  { country: 'Monaco', code: 'MC', phoneCode: '+377' },
  { country: 'Mongolia', code: 'MN', phoneCode: '+976' },
  { country: 'Montenegro', code: 'ME', phoneCode: '+382' },
  { country: 'Morocco', code: 'MA', phoneCode: '+212' },
  { country: 'Mozambique', code: 'MZ', phoneCode: '+258' },
  { country: 'Myanmar', code: 'MM', phoneCode: '+95' },
  { country: 'Namibia', code: 'NA', phoneCode: '+264' },
  { country: 'Nauru', code: 'NR', phoneCode: '+674' },
  { country: 'Nepal', code: 'NP', phoneCode: '+977' },
  { country: 'Netherlands', code: 'NL', phoneCode: '+31' },
  { country: 'New Zealand', code: 'NZ', phoneCode: '+64' },
  { country: 'Nicaragua', code: 'NI', phoneCode: '+505' },
  { country: 'Niger', code: 'NE', phoneCode: '+227' },
  { country: 'Nigeria', code: 'NG', phoneCode: '+234' },
  { country: 'Norway', code: 'NO', phoneCode: '+47' },
  { country: 'Oman', code: 'OM', phoneCode: '+968' },
  { country: 'Pakistan', code: 'PK', phoneCode: '+92' },
  { country: 'Palau', code: 'PW', phoneCode: '+680' },
  { country: 'Panama', code: 'PA', phoneCode: '+507' },
  { country: 'Papua New Guinea', code: 'PG', phoneCode: '+675' },
  { country: 'Paraguay', code: 'PY', phoneCode: '+595' },
  { country: 'Peru', code: 'PE', phoneCode: '+51' },
  { country: 'Philippines', code: 'PH', phoneCode: '+63' },
  { country: 'Poland', code: 'PL', phoneCode: '+48' },
  { country: 'Portugal', code: 'PT', phoneCode: '+351' },
  { country: 'Qatar', code: 'QA', phoneCode: '+974' },
  { country: 'Romania', code: 'RO', phoneCode: '+40' },
  { country: 'Russia', code: 'RU', phoneCode: '+7' },
  { country: 'Rwanda', code: 'RW', phoneCode: '+250' },
  { country: 'Saint Kitts and Nevis', code: 'KN', phoneCode: '+1' },
  { country: 'Saint Lucia', code: 'LC', phoneCode: '+1' },
  { country: 'Saint Vincent and the Grenadines', code: 'VC', phoneCode: '+1' },
  { country: 'Samoa', code: 'WS', phoneCode: '+685' },
  { country: 'San Marino', code: 'SM', phoneCode: '+378' },
  { country: 'Sao Tome and Principe', code: 'ST', phoneCode: '+239' },
  { country: 'Saudi Arabia', code: 'SA', phoneCode: '+966' },
  { country: 'Senegal', code: 'SN', phoneCode: '+221' },
  { country: 'Serbia', code: 'RS', phoneCode: '+381' },
  { country: 'Seychelles', code: 'SC', phoneCode: '+248' },
  { country: 'Sierra Leone', code: 'SL', phoneCode: '+232' },
  { country: 'Singapore', code: 'SG', phoneCode: '+65' },
  { country: 'Slovakia', code: 'SK', phoneCode: '+421' },
  { country: 'Slovenia', code: 'SI', phoneCode: '+386' },
  { country: 'Solomon Islands', code: 'SB', phoneCode: '+677' },
  { country: 'Somalia', code: 'SO', phoneCode: '+252' },
  { country: 'South Africa', code: 'ZA', phoneCode: '+27' },
  { country: 'Spain', code: 'ES', phoneCode: '+34' },
  { country: 'Sri Lanka', code: 'LK', phoneCode: '+94' },
  { country: 'Sudan', code: 'SD', phoneCode: '+249' },
  { country: 'Suriname', code: 'SR', phoneCode: '+597' },
  { country: 'Swaziland', code: 'SZ', phoneCode: '+268' },
  { country: 'Sweden', code: 'SE', phoneCode: '+46' },
  { country: 'Switzerland', code: 'CH', phoneCode: '+41' },
  { country: 'Syria', code: 'SY', phoneCode: '+963' },
  { country: 'Taiwan', code: 'TW', phoneCode: '+886' },
  { country: 'Tajikistan', code: 'TJ', phoneCode: '+992' },
  { country: 'Tanzania', code: 'TZ', phoneCode: '+255' },
  { country: 'Thailand', code: 'TH', phoneCode: '+66' },
  { country: 'Togo', code: 'TG', phoneCode: '+228' },
  { country: 'Tonga', code: 'TO', phoneCode: '+676' },
  { country: 'Trinidad and Tobago', code: 'TT', phoneCode: '+1' },
  { country: 'Tunisia', code: 'TN', phoneCode: '+216' },
  { country: 'Turkey', code: 'TR', phoneCode: '+90' },
  { country: 'Turkmenistan', code: 'TM', phoneCode: '+993' },
  { country: 'Tuvalu', code: 'TV', phoneCode: '+688' },
  { country: 'Uganda', code: 'UG', phoneCode: '+256' },
  { country: 'Ukraine', code: 'UA', phoneCode: '+380' },
  { country: 'United Arab Emirates', code: 'AE', phoneCode: '+971' },
  { country: 'United Kingdom', code: 'GB', phoneCode: '+44' },
  { country: 'United States', code: 'US', phoneCode: '+1' },
  { country: 'Uruguay', code: 'UY', phoneCode: '+598' },
  { country: 'Uzbekistan', code: 'UZ', phoneCode: '+998' },
  { country: 'Vanuatu', code: 'VU', phoneCode: '+678' },
  { country: 'Vatican City', code: 'VA', phoneCode: '+379' },
  { country: 'Venezuela', code: 'VE', phoneCode: '+58' },
  { country: 'Vietnam', code: 'VN', phoneCode: '+84' },
  { country: 'Yemen', code: 'YE', phoneCode: '+967' },
  { country: 'Zambia', code: 'ZM', phoneCode: '+260' },
  { country: 'Zimbabwe', code: 'ZW', phoneCode: '+263' },
];

module.exports = countryCodes;
