const Currency = [
  {
    currencyId: '1',
    CurrencyName: 'USD',
    currencySymbols: '0x24',
    CountryName: 'United States',
    created_date: '2021-08-05 11:07:31',
    modified_date: '2021-08-05 11:07:54',
  },
  {
    currencyId: '2',
    CurrencyName: 'EUR',
    currencySymbols: '0xe282ac',
    CountryName: 'European Union',
    created_date: '2021-08-05 11:08:18',
    modified_date: '2021-08-05 11:09:09',
  },
  {
    currencyId: '3',
    CurrencyName: 'INR',
    currencySymbols: '0x2623383337373b',
    CountryName: 'India',
    created_date: '2021-08-05 11:08:18',
    modified_date: '2021-08-05 11:09:09',
  },
  {
    currencyId: '4',
    CurrencyName: 'NGN',
    currencySymbols: '0x2623383335383b',
    CountryName: 'Nigeria',
    created_date: '2021-08-17 10:13:51',
    modified_date: '2021-08-17 10:14:21',
  },
  {
    currencyId: '5',
    CurrencyName: 'GBP',
    currencySymbols: '0xc2a3',
    CountryName: 'United Kingdom ',
    created_date: '2021-10-15 10:50:07',
    modified_date: '2021-10-15 10:51:57',
  },
  {
    currencyId: '6',
    CurrencyName: 'SGD',
    currencySymbols: '0x5324',
    CountryName: 'Singapore',
    created_date: '2021-10-15 10:50:07',
    modified_date: '2021-10-15 10:51:57',
  },
  {
    currencyId: '8',
    CurrencyName: 'USD',
    currencySymbols: '0x24',
    CountryName: 'American Samoa',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '9',
    CurrencyName: 'EUR',
    currencySymbols: '0xe282ac',
    CountryName: 'Aland Islands',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '10',
    CurrencyName: 'JPY',
    currencySymbols: '0xc2a5',
    CountryName: 'Japan',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '11',
    CurrencyName: 'BRL',
    currencySymbols: '0x5224',
    CountryName: 'Brazil',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '12',
    CurrencyName: 'AUD',
    currencySymbols: '0x4124',
    CountryName: 'Australia',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '13',
    CurrencyName: 'CAD',
    currencySymbols: '0x4324',
    CountryName: 'Canada',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '14',
    CurrencyName: 'CHF',
    currencySymbols: '0x434846',
    CountryName: 'Liechtenstein ',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '15',
    CurrencyName: 'CNY',
    currencySymbols: '0x26233136353b',
    CountryName: 'China',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '16',
    CurrencyName: 'HKD',
    currencySymbols: '0x484b24',
    CountryName: 'Hong Kong',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '17',
    CurrencyName: 'NZD',
    currencySymbols: '0x4e5a24202f2024',
    CountryName: 'New Zealnd',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '18',
    CurrencyName: 'RUB',
    currencySymbols: '0x3f',
    CountryName: 'Russia',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '19',
    CurrencyName: 'AED',
    currencySymbols: '0x2623783632663b26237832653b2623783632353b',
    CountryName: 'United Arab Emirates',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '20',
    CurrencyName: 'ILS',
    currencySymbols: '0x3f',
    CountryName: 'Isarel',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '21',
    CurrencyName: 'ZAR',
    currencySymbols: '0x52',
    CountryName: 'South Africa',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '22',
    CurrencyName: 'IDR',
    currencySymbols: '0x5270',
    CountryName: 'Indonesia',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '23',
    CurrencyName: 'SAR',
    currencySymbols: '0x3f',
    CountryName: 'Saudi Arabia',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '24',
    CurrencyName: 'TWD',
    currencySymbols: '0x4e5424',
    CountryName: 'Taiwan',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '25',
    CurrencyName: 'DKK',
    currencySymbols: '0x6b72',
    CountryName: 'Denmark',
    created_date: '0000-00-00 00:00:00',
    modified_date: '2021-11-10 09:42:55',
  },
  {
    currencyId: '26',
    CurrencyName: 'THB',
    currencySymbols: '0x2623333634373b',
    CountryName: 'Thailand',
    created_date: '2021-11-29 06:44:28',
    modified_date: '2021-11-29 06:45:05',
  },

  {
    currencyId: '29',
    CurrencyName: 'AED',
    currencySymbols: '0x414544',
    CountryName: 'UAE',
    created_date: '2022-09-20 11:56:25',
    modified_date: '2022-09-20 11:57:46',
  },
  {
    currencyId: '30',
    CurrencyName: 'PKR',
    currencySymbols: '0x504b52',
    CountryName: 'Pakistan',
    created_date: '2022-09-23 12:10:17',
    modified_date: '2022-09-23 12:10:37',
  },
  {
    currencyId: '31',
    CurrencyName: 'NPR',
    currencySymbols: '0x52732e',
    CountryName: 'Nepal',
    created_date: '2023-06-12 09:10:26',
    modified_date: '2023-06-12 09:10:43',
  },
];
module.exports = Currency;
