import React, { useRef, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import img from '../assets/images/newdeals.png';
import Select from 'react-select';
import { MdAddShoppingCart } from 'react-icons/md';
import { LuPictureInPicture } from 'react-icons/lu';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { FaRegComments } from 'react-icons/fa6';
import { MdReviews } from 'react-icons/md';
import { VscUnmute } from 'react-icons/vsc';
import { FaShare, FaTrophy, FaFireAlt } from 'react-icons/fa';
import { RiImageAddFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { useParams, useLocation } from 'react-router-dom';
import Products from './Products';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { allApiInstance } from '../axiosInstances';
const ProductEdit = ({ existingProductData, products, setProducts }) => {
  // Initialize state variables with default values or empty strings
  const [productData, setProductData] = useState({
    productLink: '',
    productName: '',
    productDescription: '',
    mrp: '',
    sellingPrice: '100',
    productTag: '',
    productImage: null,
    productImageName: '',
    productImagePreview: null,
    productImageSize: '',
    brandid: '',
    productsku: '',
    productattribute: '',
    discount: '',
  });
  const [file, setFile] = useState(null);
  const [product, setProduct] = useState({});
  const [error, setErorr] = useState('');
  const tagOptions = [
    {
      value: '1',
      label: (
        <div>
          <div
            className="badge"
            style={{
              borderRadius: '12px 0px',
              backgroundColor: '#CD6113',
              fontWeight: 'lighter',
            }}
          >
            <FaTrophy className="pe-1" color="#F3CE58" size={20} /> Best Seller
          </div>{' '}
        </div>
      ),
    },
    {
      value: '2',
      label: (
        <div>
          <div
            className="badge"
            style={{
              color: '#FF9D33',
              borderRadius: '12px 2px',
              backgroundColor: '#FFF6DF',
              fontWeight: 'lighter',
            }}
          >
            <FaFireAlt className="pe-1" color="#F3CE58" size={20} /> Hot Deals
          </div>
        </div>
      ),
    },
    {
      value: '3',
      label: (
        <div>
          <div
            className="badge"
            style={{
              borderRadius: '12px 2px',
              backgroundColor: 'white',
              fontWeight: 'lighter',
              color: '#A2A2A4',
            }}
          >
            <img src={img} /> New
          </div>{' '}
        </div>
      ),
    },
  ];
  const fileInputRef = useRef(null);
  const location = useLocation();
  const urlarray = location.pathname.split('/');
  const product_id = atob(urlarray[urlarray.length - 1]);

  const navigate = useNavigate();

  useEffect(() => {
    if (existingProductData) {
      setProductData(existingProductData);
    }
  }, [existingProductData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (!productData.productLink.includes('http')) {
      setErorr('Please enter valid product url');
    } else {
      setErorr('');
    }
  }, [productData.productLink]);

  const handleTagChange = (selectedOption) => {
    setProductData((prevState) => ({
      ...prevState,
      productTag: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setProductData((prevState) => ({
        ...prevState,
        productImageName: file.name,
        productImageSize: (file.size / 1024).toFixed(2) + ' KB',
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductData((prevState) => ({
          ...prevState,
          productImagePreview: reader.result,
        }));
      };
      setFile(file);
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const clearSelection = () => {
    setProductData((prevState) => ({
      ...prevState,
      productImage: null,
      productImagePreview: null,
      productImageName: '',
    }));
    setFile(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const calculateDiscount = () => {
    if (
      productData.mrp > 0 &&
      productData.sellingPrice > 0 &&
      productData.mrp >= productData.sellingPrice
    ) {
      const discountPercentage =
        ((productData.mrp - productData.sellingPrice) / productData.mrp) * 100;
      setProductData((prevState) => ({
        ...prevState,
        discount: discountPercentage.toFixed(2),
      }));
    } else {
      setProductData((prevState) => ({
        ...prevState,
        discount: null,
      }));
    }
  };

  useEffect(() => {
    calculateDiscount();
  }, [productData.mrp, productData.sellingPrice]);
  // setProductData({
  //     productLink: 'abv',
  //     productName: 'm,dshcj',
  //     productDescription: '',
  //     mrp: '',
  //     sellingPrice: '100',
  //     productTag: '',
  //     productImage: null,
  //     productImageName: '',
  //     productImagePreview: null,
  //     productImageSize: '',
  //     brandid: '',
  //     productsku: '',
  //     productattribute: '',
  //     discount: ''
  // })

  const user = useSelector((state) => state.auth.user);
  const getSingleProductById = async () => {
    try {
      await allApiInstance
        .get(
          `/getProductByProductIdAndBrandId?productId=${product_id}&brandId=${user.brand_id}`
        )
        .then((res) => {
          if (res.data) {
            setProduct(res.data.product);
            const product = res.data.product;
            setProductData({
              productLink: product.externalLinks,
              productName: product.productTitle,
              productDescription: product.productDesc,
              mrp: product.productPrice,
              sellingPrice: product.productSellPrice,
              productTag: product.productTags,
              productImage: product.image,
              productImageName: product.productImgUrl,
              productImagePreview: product.image,
              // productImageSize: product.externalLinks,
              brandid: product.brandProductId,
              productsku: product.skuCode,
              productattribute: product.productAttr,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSingleProductById();
  }, [product_id]);
  const environment = process.env.REACT_APP_PDP_ENV
  const handleUpdateProduct = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('product_link', productData.productLink);
      formData.append('product_title', productData.productName);
      formData.append('product_desc', productData.productDescription);
      formData.append('price', productData.mrp);
      formData.append('selling_price', productData.sellingPrice);
      formData.append('product_tag', productData.productTag);
      formData.append('brand_product_id', productData.brandid);
      formData.append('sku_code', productData.productsku);
      formData.append('product_attribute', productData.productattribute);
      formData.append('brandId', user.brand_id);
      formData.append('productId', product_id);

      if (file) {
        formData.append('image_name', file.name);
        formData.append('image', file);
        formData.append('product_image', product.image);
      } else {
        formData.append('product_image', product.image);
        formData.append('image_name', product.productImgUrl.split('.')[0]);
      }
      formData.append('environment', environment);

      // image_name
      // p_image
      await allApiInstance
        .patch('/editProduct', formData)
        .then((res) => {
          if (res.data) {
            toast.success('Product updated');
          }
        })
        .catch((err) => {
          toast.error('Something went wrong!');
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }
  };
  return (
    <div className="container-fluid mt-3 row">
      <Helmet>
        <title>SWIRL | Product edit</title>
      </Helmet>
      <div>
        <p className="d-flex align-items-center gap-2">
          <BiArrowBack
            title="back"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/admin/products')}
            size={20}
          />
          Edit Product
        </p>
      </div>
      <div
        className="col-7 ps-5"
        style={{ height: '75vh', overflowY: 'auto', overflowX: 'hidden' }}
      >
        <form
          onSubmit={handleUpdateProduct}
        // style={{ pointerEvents: loading ? "none" : "auto", opacity: loading ? "0.5" : "1" }}
        >
          <div className="row">
            <div className="form-group mt-2">
              <label className="label-product">
                Product Link<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter link"
                name="productLink"
                value={productData.productLink}
                onChange={handleChange}
              />
              {error.length > 0 && (
                <small className="text-danger">{error}</small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group mt-2">
              <label className="label-product">
                Product Name<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter product name"
                name="productName"
                value={productData.productName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group mt-2">
              <label className="label-product">
                Product Description<sup className="text-danger">*</sup>
              </label>
              <textarea
                className="form-control mt-2"
                placeholder="Enter product description"
                name="productDescription"
                value={productData.productDescription}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group mt-2">
                <label className="label-product">
                  MRP<sup className="text-danger">*</sup>
                </label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Enter MRP"
                  name="mrp"
                  value={productData.mrp}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mt-2">
                <label className="label-product">Selling Price</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Enter selling price"
                  name="sellingPrice"
                  value={productData.sellingPrice}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-4" style={{ zIndex: '10' }}>
              <label className="mt-2 label-product">Product Tag</label>
              <Select
                className="mt-2"
                value={tagOptions.find(
                  (option) => option.value == productData.productTag
                )}
                onChange={handleTagChange}
                options={tagOptions}
                isClearable={true}
                placeholder="Choose Tag"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4">
              <div className="form-group">
                <label className="label-product">Product Brand id</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="product brand id"
                  name="brandid"
                  value={productData.brandid}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="label-product">Product SKU</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Product SKU"
                  name="productsku"
                  value={productData.productsku}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="label-product">Product Attribute</label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Product Attribute"
                  name="productattribute"
                  value={productData.productattribute}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group mt-2">
            <label className="label-product">
              Product Image<sup className="text-danger">*</sup>
            </label>
            <div
              className="input-group ps-1 mt-1"
              style={{
                border: '1px solid rgba(209, 209, 210, 1)',
                borderRadius: '8px',
              }}
            >
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                className="form-control mt-2"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={triggerFileInput}
              />
              <input
                type="text"
                className="form-control"
                style={{ border: 'none' }}
                placeholder={
                  fileInputRef?.current?.files[0]?.name || 'No file selected'
                }
                onClick={() => fileInputRef.current.click()}
                readOnly
              />
              <button
                className="btn m-1"
                type="button"
                style={{
                  border: 'none',
                  backgroundColor: 'rgba(232, 232, 232, 1)',
                  borderRadius: '8px',
                  padding: '0 13px',
                  fontSize: '12px',
                }}
                onClick={() => fileInputRef.current.click()}
              >
                Browse
              </button>
            </div>
            <input
              type="file"
              placeholder="select"
              id="asfsdfsdf"
              ref={fileInputRef}
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />

            {/* {productData.productImagePreview && (
                            <div className='card p-1 mt-2'>
                                <div className='d-flex w-100'>
                                    <img
                                        style={{ height: "50px", width: "35px", borderRadius: "3px" }}
                                        src={productData.productImagePreview}
                                        alt="Product Preview"
                                    />
                                    <div className='d-flex flex-column ps-2 pt-1'>
                                        <small className='text-muted'>{productData.productImageName}</small>
                                        <small style={{ color: "rgb(162, 162, 164)" }}>
                                            {productData.productImageSize} <b>.</b> <span className='text-success'>Uploaded</span>
                                        </small>
                                    </div>
                                    <div className='ms-auto ' style={{ cursor: "pointer" }} onClick={clearSelection}>
                                        <IoClose className='pointer_cursor' />
                                    </div>
                                </div>
                            </div>
                        )} */}
            {productData.productImagePreview && (
              <div className="card p-1 mt-2">
                <div className="d-flex w-100">
                  <img
                    style={{
                      height: '50px',
                      width: '35px',
                      borderRadius: '3px',
                    }}
                    src={productData.productImagePreview}
                  />
                  <div className="d-flex flex-column ps-2 pt-1">
                    {/* <small className='text-muted'>{fileInputRef?.current?.files[0]?.name}NAME</small>
                                        <small style={{ color: "rgb(162, 162, 164)" }}>{`${(fileInputRef?.current?.files[0]?.size / 1024).toFixed(2)}KB`} <b>.</b> <span className='text-success'>Uploaded</span></small> */}
                  </div>
                  <div className="ms-auto" onClick={() => clearSelection()}>
                    <IoClose className="pointer_cursor" />
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-3 ms-1">
              <button
                disabled={error.length > 0}
                className="col-2 p-2 btn btn-dark"
              >
                Update
              </button>
            </div>
          </div>
          {/* <input
                        type="file"
                        placeholder='select'
                        id="asfsdfsdf"
                        ref={fileInputRef}
                        accept='image/*'
                        style={{ display: "none" }}
                        onChange={handleImageChange} /> */}
        </form>
      </div>
      <div className="col-5">
        <div
          className="container-fluid position-relative"
          style={{ backgroundColor: '#F8F8FF', width: '100%', height: '75vh' }}
        >
          <div className="row justify-content-center">
            <div className="col-12 col-md-7 pt-3">
              <div
                className="card mb-4 position-relative"
                style={{ borderRadius: '20px' }}
              >
                <video
                  className="card-img-top"
                  style={{ borderRadius: '20px' }}
                  autoPlay
                  loop
                  muted
                >
                  <source
                    src="https://d1g3m9ml694eqp.cloudfront.net/1715162670.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <div className="position-absolute end-0 d-flex flex-row align-items-end mt-2 me-2 gap-2">
                  <LuPictureInPicture color="white" size={22} />
                  <HiOutlineShoppingCart color="white" size={22} />
                  <IoClose color="white" size={22} />
                </div>
                <div
                  className="position-absolute end-0 d-flex flex-column align-items-end"
                  style={{ marginRight: '5%', marginTop: '80%' }}
                >
                  <VscUnmute color="white" size={22} className="mb-3" />
                  <FaRegComments color="white" size={22} className="mb-3" />
                  <MdReviews color="white" size={22} className="mb-3" />
                  <FaShare color="white" size={22} className="mb-2" />
                </div>

                <div
                  className="card-body position-absolute justify-content-center ms-1"
                  style={{
                    borderRadius: '8px',
                    marginBottom: '7px',
                    background: 'rgba(255, 255, 255, 0.9)',
                    width: '97%',
                    bottom: '0',
                    height: '110px',
                  }}
                >
                  <div
                    className="d-flex"
                    style={{ zIndex: '445', position: 'relative' }}
                  >
                    {productData.productTag == '1' && (
                      <span
                        className="badge position-absolute start-0 p-2  tags1"
                        style={{
                          borderRadius: '12px 12px 2px 2px',
                          backgroundColor: '#CD6113',
                          fontWeight: 'lighter',
                          marginTop: '-11.2%',
                          zIndex: '446',
                          width: '37%',
                        }}
                      >
                        <FaTrophy className="pe-1" color="#F3CE58" size={15} />
                        Best Seller
                      </span>
                    )}
                    {productData.productTag == '2' && (
                      <span
                        className="badge position-absolute start-0 p-2  tags"
                        style={{
                          color: '#FF9D33',
                          borderRadius: '12px 12px 2px 2px',
                          backgroundColor: '#FFF6DF',
                          fontWeight: 'lighter',
                          marginTop: '-11%',
                          zIndex: '446',
                          width: '30%',
                        }}
                      >
                        <FaFireAlt className="pe-1" color="#F3CE58" size={12} />
                        Hot Deals
                      </span>
                    )}
                    {productData.productTag == '3' && (
                      <span
                        className="badge position-absolute d-flex gap-2 start-0 p-2  tags"
                        style={{
                          borderRadius: '12px 12px 2px 2px',
                          backgroundColor: 'white',
                          fontWeight: 'lighter',
                          marginTop: '-11%',
                          color: '#A2A2A4',
                          zIndex: '446',
                          width: '30%',
                        }}
                      >
                        <img height={12} src={img} />
                        New
                      </span>
                    )}
                    <div
                      className="d-flex w-100"
                      style={{
                        borderRadius: `${productData.productTag ? '0' : '10'}px 10px 10px 10px`,
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{ width: '30%', overflow: 'hidden' }}
                        onClick={triggerFileInput}
                      >
                        {productData.productImagePreview ? (
                          <img
                            src={productData.productImagePreview}
                            style={{
                              height: '100%',
                              width: '100%',
                              overflow: 'hidden',
                            }}
                            alt="video thumbnail"
                          />
                        ) : (
                          <div
                            style={{
                              display: 'grid',
                              placeItems: 'center',
                              height: '100%',
                            }}
                          >
                            <RiImageAddFill
                              style={{ zIndex: '5', cursor: 'pointer' }}
                              size={32}
                            />
                          </div>
                        )}
                      </div>
                      <div style={{ width: '70%' }}>
                        <div className="d-flex flex-column ps-2">
                          <div
                            className="m-0 p-0"
                            style={{ fontSize: '12px', textAlign: 'right' }}
                          >
                            <span
                              className="badge text-muted p-2"
                              style={{ backgroundColor: '#FCFCE4' }}
                            >
                              Rating: 4.5
                            </span>
                          </div>
                          <span
                            className="product_title_preview"
                            style={{ fontSize: '14px' }}
                          >
                            {productData?.productName?.length === 0
                              ? 'Product title'
                              : productData.productName}
                          </span>
                          <span style={{ fontSize: '12px', padding: '2px' }}>
                            {!productData?.sellingPrice ? (
                              <>
                                {productData?.mrp.length === 0
                                  ? 'mrp'
                                  : `$${productData?.mrp} `}
                                {/* <del className="text-muted">${mrp.length === 0 ? "mrp" : mrp}</del> */}
                              </>
                            ) : (
                              <>
                                {productData?.sellingPrice ==
                                  productData?.mrp ? (
                                  <>
                                    {productData?.mrp.length === 0
                                      ? 'mrp'
                                      : `$${productData?.mrp} `}
                                  </>
                                ) : (
                                  <>
                                    $
                                    {productData?.sellingPrice.length === 0
                                      ? 'sell '
                                      : `${productData?.sellingPrice} `}
                                    <del className="text-muted">
                                      $
                                      {productData?.mrp.length === 0
                                        ? 'mrp'
                                        : productData?.mrp}
                                    </del>
                                    <span className="badge_discount bg-success text-white fw-light p-1 rounded ms-1">
                                      <small>
                                        {productData.discount
                                          ? `${productData.discount}%`
                                          : '0%'}
                                      </small>
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </span>
                          {/* <span style={{ fontSize: "12px", padding: "2px" }}>${productData?.sellingPrice?.length === 0 ? "SELLING " : `${productData.sellingPrice} `}<del className="text-muted">${productData?.mrp?.length === 0 ? "MRP" : productData.mrp}</del><span className='badge_discount bg-success text-white fw-light p-1 rounded ms-1'>
                                                        <small>{productData.discount ? `${productData.discount}%` : "DISCOUNT"}</small>
                                                    </span></span> */}
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="btn btn-sm btn-dark mt-2 buynow-button mb-1"
                              style={{
                                fontSize: '12px',
                                width: '98%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              Buy now
                            </button>
                            <MdAddShoppingCart size={30} className=" mt-1" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEdit;
