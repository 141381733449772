import React, { useEffect, useLayoutEffect, useState } from 'react';
import './login.css';
import { FaUser } from 'react-icons/fa';
import { CgKey } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import { loginSuccess, loginFailure, setLoading } from '../redux/apiSlice';
import { useLoginMutation, useTokenGenerateMutation } from '../redux/authApi';
import { Helmet } from 'react-helmet';

const Login = () => {
  const [showPassWord, setShowPassWord] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [tokenGenerate] = useTokenGenerateMutation();
  const [userData, setUserData] = useState({
    password: '',
    mobile: '',
  });
  const [errors, setErrors] = useState({
    password: '',
    mobile: '',
    loginError: '',
  });

  const handleInputChange = (fieldName, value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: value,
    }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      dispatch(setLoading(true));

      try {
        const { data } = await login({ ...userData });
        if (data) {
          if (data.status === 1) {
            const response = await tokenGenerate(userData);
            if (response.data?.token) {
              localStorage.setItem('token', response.data?.token);
              dispatch(loginSuccess({ ...data.data, brand_id: data.data.id }));
              setTimeout(() => {
                dispatch(setLoading(false));
              }, 2000);
            }
          } else {
            setErrors({
              password: '',
              mobile: '',
              loginError: data.message,
            });
          }
        }
      } catch (error) {
        dispatch(loginFailure(error.message));
        dispatch(setLoading(false));
        console.log(error);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate mobile number
    if (!userData.mobile.trim()) {
      newErrors.mobile = 'User ID is required';
      isValid = false;
    }
    // Validate password
    if (!userData.password.trim()) {
      newErrors.password = 'Password is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // const authState = useSelector((state) => state.auth);
  const queryParams = new URLSearchParams(window.location.search);

  // Get the value of the 'yourQueryParam' parameter
  const userName = queryParams.get('username');
  const key = queryParams.get('key');
  const autoLoginFunction = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await login({
        password: atob(key),
        mobile: userName,
      });

      if (data) {
        if (data.status === 1) {
          // Token generation logic added here
          const response = await tokenGenerate({
            password: atob(key),
            mobile: userName,
          });

          if (response.data?.token) {
            localStorage.setItem('token', response.data?.token);
            dispatch(loginSuccess({ ...data.data, brand_id: data.data.id }));
            setTimeout(() => {
              dispatch(setLoading(false));
              navigate('/admin');
            }, 2000);
          }
        } else {
          setErrors({
            password: '',
            mobile: '',
            loginError: data.message,
          });
          dispatch(setLoading(false)); // Stop loading if there's an error
        }
      }
    } catch (error) {
      dispatch(loginFailure(error.message));
      dispatch(setLoading(false));
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (userName && key) {
      try {
        dispatch(setLoading(true));
        setUserData({
          password: atob(key),
          mobile: userName,
        });
        autoLoginFunction();
      } catch (error) {
        console.log(error);
      }
    }
  }, [userName, key]);

  return (
    <>
      <Helmet>
        <title>SWIRL | Login</title>
      </Helmet>
      <div className="d-flex">
        {/* Left Column */}
        <div className="login_page_col_left">
          <div className="left_layer">
            <div
              className="top_left_logo"
              style={{ marginTop: '50px', marginLeft: '50px' }}
            >
              <img
                alt="logo"
                style={{ width: '120px' }}
                src="https://assets-global.website-files.com/63f0e6822ce56e6262917200/645e226dea7561daaabcd74f_Logo.svg"
              />
            </div>
            <div className="bottom_text p-3 text-center mb-5">
              <h3 style={{ fontSize: '36px', lineHeight: '44px' }}>
                Live streaming effortlessly bridges the gap between nations,
                uniting the world in a virtual embrace.
              </h3>
              <div className="underline_bottom_text"></div>
            </div>
          </div>
        </div>
        {/* Right Column */}
        <div className="login_page_col_right">
          <div className="login_box">
            <div className=" card col-6 border-0">
              <h2 className="welcome_text">Welcome back</h2>
              <p
                className="text-muted m-0 mt-1 p-0 mb-3"
                style={{ fontSize: '24px' }}
              >
                Please log in to your account.
              </p>
              {/* Login Form */}
              <form className="mt-3" onSubmit={handleLogin}>
                {/* User ID Field */}
                <label
                  style={{
                    color: 'rgba(116, 116, 119, 1)',
                    marginBottom: '8px',
                  }}
                >
                  User ID
                </label>
                <div className="input-group input-group">
                  <span
                    className="input-group-text border-end-0"
                    style={{
                      borderRadius: '8px 0 0 8px',
                      backgroundColor: 'white',
                    }}
                  >
                    <i
                      className="ri-user-3-fill"
                      style={{ opacity: '50%' }}
                    ></i>
                    {/* <FaUser color='#09121F' style={{ opacity: "50%" }} /> */}
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0 login-placeholder"
                    aria-label="Sizing example input"
                    aria-describedby="Enter user id"
                    name="mobile"
                    placeholder="Enter user id"
                    value={userData.mobile}
                    onChange={(e) =>
                      handleInputChange('mobile', e.target.value)
                    }
                    style={{ borderRadius: '0px 8px 8px 0px' }}
                  />
                </div>
                {errors.mobile && (
                  <div className="invalid-feedback d-block text-danger ps-1">
                    {errors.mobile}
                  </div>
                )}
                {/* Password Field */}
                <label
                  style={{ color: 'rgba(116, 116, 119, 1)', marginTop: '6px' }}
                >
                  Password
                </label>
                <div className="input-group" style={{ marginTop: '8px' }}>
                  <span
                    className="input-group-text border-end-0"
                    style={{
                      borderRadius: '8px 0 0 8px',
                      backgroundColor: 'white',
                    }}
                  >
                    {' '}
                    <i className="ri-key-line" style={{ opacity: '50%' }}></i>
                  </span>
                  <input
                    type={showPassWord ? 'text' : 'password'}
                    className="form-control border-start-0 border-end-0 login-placeholder"
                    aria-label="Enter password"
                    placeholder="Enter password"
                    value={userData.password}
                    name="password"
                    onChange={(e) =>
                      handleInputChange('password', e.target.value)
                    }
                  />

                  <span
                    className="input-group-text border-start-0"
                    style={{
                      cursor: 'pointer',
                      borderRadius: '0px 8px 8px 0px',
                      backgroundColor: 'white',
                    }}
                    onClick={() => {
                      setShowPassWord(!showPassWord);
                    }}
                  >
                    {showPassWord ? (
                      <i
                        className="ri-eye-off-line"
                        style={{ opacity: '50%' }}
                      ></i>
                    ) : (
                      <i className="ri-eye-line" style={{ opacity: '50%' }}></i>
                    )}
                  </span>
                </div>
                {errors.password && (
                  <span className="invalid-feedback text-danger d-block mt-0 ps-1">
                    {errors.password}
                  </span>
                )}
                {/* Login Button */}
                <button
                  type="submit"
                  className="btn  w-100 login_btn mt-4"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span style={{ fontSize: '18px', alignContent: 'center' }}>
                      {' '}
                      <FaSpinner className="spinner" /> Signing In...
                    </span>
                  ) : (
                    <span style={{}}>Sign In</span>
                  )}
                </button>
                {errors.loginError && (
                  <div
                    className="ps-3 mt-3"
                    style={{ color: 'red', borderRadius: '15px' }}
                  >
                    {errors.loginError && (
                      <div className="invalid-feedback d-block text-danger ps-1">
                        {errors.loginError}
                      </div>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
