import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap';
import Companyinfo from '../components/profile/Companyinfo';
import './Pages.css';
import Profileproducts from '../components/profile/Profileproducts';
import AccessToken from '../components/profile/AccessToken';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { loginSuccess } from '../redux/apiSlice';
import { allApiInstance } from '../axiosInstances';

const Profile = () => {
  const [activeTab, setActiveTab] = useState('companyinfo');
  const [profile, setProfile] = useState();
  const [fetchedProfileData, setFetchedProfileData] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [switches, setSwitches] = useState({
    option: false,
  });
  const dispatch = useDispatch();
  const [state, setState] = useState({
    companyname: '',
    location: '',
    StoreUrl: '',
    currency: '',
    productUrl: '',
    website: '',
    countryCodes: '',
    phoneNumber: '',
    email: '',
    logo: 'https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png',
    imageFile: null,
  });

  const [loading, setLoading] = useState();
  const fetchProfileData = async () => {
    try {
      const response = await allApiInstance.get(
        `/getBrandProfileInfoByBrandId?brandId=${user.brand_id}`
      );
      if (response.data) {
        const data = response.data;
        setFetchedProfileData(data);
        const brandInfo = data.brandInfo;
        // alert("done")
        setSwitches({
          option: !!brandInfo.simulcasting,
        });

        let platform = brandInfo.platform;
        let uiPlatform;
        if (platform == '0') {
          uiPlatform = 'Platform 5';
        } else if (platform == '1') {
          uiPlatform = 'Platform 1';
        } else if (platform == '2') {
          uiPlatform = 'Platform 2';
        } else if (platform == '3') {
          uiPlatform = 'Platform 4';
        } else {
          uiPlatform = 'Platform 3';
        }
        setState({
          companyname: brandInfo.brandName,
          StoreUrl: brandInfo.dubDomain,
          location: brandInfo.designerLocation,
          currency: brandInfo.currencyId,
          productUrl: brandInfo.productUTM,
          website: brandInfo.companyLink,
          countryCodes: brandInfo.countryCode,
          phoneNumber: brandInfo.userPhone,
          email: brandInfo.userEmail,
          logo:
            brandInfo.userProfile ||
            'https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png',
          platform: uiPlatform,
        });
        // dispatch(loginSuccess({
        //     ...user,
        //     stream_platform: uiPlatform
        // }));
      }
    } catch (error) {
      console.log(error);
      // alert(error)
    }
  };

  useEffect(() => {
    fetchProfileData();
    // alert("done")
  }, []);

  const togglechange = async (switches) => {
    try {
      await allApiInstance
        .patch('/updateBrandInfoByBrandId', {
          payload: {
            brandId: user.brand_id,
            simulcasting: switches.option,
          },
        })
        .then((res) => {
          if (res.data) {
            // toast.success("Updated");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitupdated = async (e) => {
    e.preventDefault();
    try {
      let pl;
      const platform = state.platform;
      if (platform == 'Platform 5') {
        pl = '0';
      } else if (platform == 'Platform 1') {
        pl = '1';
      } else if (platform == 'Platform 2') {
        pl = '2';
      } else if (platform == 'Platform 4') {
        pl = '3';
      } else {
        pl = '4';
      }
      await allApiInstance
        .patch('/updateBrandInfoByBrandId', {
          payload: {
            brandId: user.brand_id,
            designerBio: '',
            designerLocation: state.location,
            currencyId: state.currency,
            language: '',
            productUtm: state.productUrl,
            superadmin: 0,
            timezone: 'Asia/Calcutta',
            facebookLink: '',
            twitterLink: '',
            instagramLink: '',
            pinterestLink: '',
            platform: pl,
          },
        })
        .then((res) => {
          if (res.data) {
            toast.success('Updated');
            dispatch(
              loginSuccess({
                ...user,
                platform: pl,
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Something went wrong!');
        });
    } catch (error) { }
  };
  const handleDeleteLogo2 = async () => {
    try {
      const formdata = new FormData();
      formdata.append('imageUrl', state.logo);
      formdata.append('brandId', user.brand_id);
      await allApiInstance
        .patch(`/deleteProfileImage`, {
          imageUrl: state.logo,
          brandId: user.brand_id,
        })
        .then(() => {
          dispatch(
            loginSuccess({
              ...user,
              user_profile:
                'https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png',
            })
          );
          toast.success('Profile image deleted');
        });
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };
  const handleProfilePickUpdate = async () => {
    if (state.imageFile) {
      try {
        setLoading(true);
        const formdata = new FormData();
        formdata.append('profileImage', state.imageFile);
        formdata.append('brandId', user.brand_id);
        await allApiInstance
          .patch('/updateProfileImage', formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            // res.data.brandInfo
            if (res.data.brandInfo) {
              dispatch(
                loginSuccess({
                  ...user,
                  user_profile: `${res.data.brandInfo}?v=${new Date().getTime()}`,
                })
              );
              setLoading(false);
              toast.success('Profile image updated');
              setState((prevState) => ({
                ...prevState,
                logo: `${res.data.brandInfo}?v=${new Date().getTime()}`,
              }));
              // setState(prevState => ({ ...prevState, imageFile: res.data.brandInfo }));
            }
          });
      } catch (error) {
        toast.error('Something went wrong 1');
        setLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleProfilePickUpdate();
  }, [state.imageFile]);

  return (
    <div>
      <Helmet>
        <title>SWIRL | Profile</title>
      </Helmet>
      {/* <Helmet>
                <title>SWIRL | Profile</title>
            </Helmet> */}
      <Container
        style={{
          height: '87vh',
          border: '1px solid #D1D1D2',
          borderRadius: '5px',
          overflow: 'hidden',
        }}
        fluid
      >
        <Row className="h-100 ps-2">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="companyinfo"
            onSelect={(k) => setActiveTab(k)}
          >
            <Row className="h-100 pt-3 pb-3">
              <Col
                md={2}
                className=" mb-3"
                style={{ borderRight: '1px solid #D1D1D2' }}
              >
                <Nav
                  variant="pills"
                  className="flex-column mt-3 video_settting_tabs"
                >
                  <Nav.Item>
                    <Nav.Link className="p-3" eventKey="companyinfo">
                      Company info.
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="p-3" eventKey="products">
                      Products
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="p-3" eventKey="accesstoken">
                      Access Token
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={activeTab === 'accesstoken' ? 5 : 10} className="p-3">
                <Tab.Content>
                  <h2 className="mt-2 ms-3">Profile Settings</h2>
                  <Tab.Pane eventKey="companyinfo">
                    <Companyinfo
                      loading={loading}
                      setLoading={setLoading}
                      togglechange={togglechange}
                      handleSubmitupdated={handleSubmitupdated}
                      handleDeleteLogo2={handleDeleteLogo2}
                      state={state}
                      setState={setState}
                      switches={switches}
                      setSwitches={setSwitches}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="products">
                    <Profileproducts />
                  </Tab.Pane>
                  <Tab.Pane eventKey="accesstoken">
                    <AccessToken />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
