import React, { useEffect, useRef, useState } from "react";
import "./Review.css";
import { IoClose } from "react-icons/io5";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import { Toast } from "react-bootstrap";
import { allApiInstance } from "../../axiosInstances";
const ReviewModal = ({
  isOpen,
  onClose,
  review,
  setSelectedOption,
  setShowForm,
}) => {
  const fileInputRef = useRef(null);
  const [error, setErorr] = useState("");
  const [submitloader, setSubmitLoader] = useState(false);
  const [productData, setProductData] = useState({
    product_link: "",
    product_title: "",
    product_desc: "",
    price: "",
    selling_price: "",
    image: "",
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagename, setImagename] = useState("");
  const [imageSize, setImageSize] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // const handleFileSelect = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //         setProductData(prevState => ({
  //             ...prevState,
  //             image: file

  //         }));
  //     }
  // };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImageFile(file);
      setProductData((prevState) => ({
        ...prevState,
        image: file,
      }));

      // Create a preview URL for the selected image
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setProductData((prevState) => ({
          ...prevState,
          image: fileReader.result,
        }));
        setImagename(file.name);
        setImageSize(file.size);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const modalRef = useRef(null);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isOpen) {
        modalElement.classList.add("slide-down");
        modalElement.classList.remove("slide-up");
      } else {
        modalElement.classList.add("slide-up");
        modalElement.classList.remove("slide-down");
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.classList.add("slide-up");
      setTimeout(() => {
        setIsClosing(false);
        onClose();
      }, 500);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "selling_price") {
      const sellingPrice = parseFloat(value);
      const mrp = parseFloat(productData.price);
      if (sellingPrice > mrp) {
        toast.error("Selling Price must be less than or equal to MRP.");
      }
    }
  };

  const getMetaData = async (link) => {
    if (link.includes("http")) {
      setLoading(true);
      setErorr("");
      try {
        const response = await allApiInstance.post("/getProductMetadata", {
          payload: { productUrl: productData.product_link },
        });

        if (response.data && response.data.product) {
          const { title, description, selling_price, product_price, image } =
            response.data.product;
          setProductData((prevState) => ({
            ...prevState,
            product_title: title,
            product_desc: description,
            selling_price,
            price: product_price,
            image: image,
          }));
        }
      } catch (error) {
        console.error(error);
        setErorr("Failed to fetch metadata");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      setErorr("Please enter a valid product URL");
    }
  };
  const environment = process.env.REACT_APP_PDP_ENV
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (productData.product_link.length > 0) {
        getMetaData(productData.product_link);
      }
    }, 500);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [productData.product_link]);
  const user = useSelector((state) => state.auth.user);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (productData.image) {
      setSubmitLoader(true);
      setLoading(true);
      const formData = new FormData();
      formData.append("product_link", productData.product_link);
      formData.append("product_title", productData.product_title);
      formData.append("product_desc", productData.product_desc);
      formData.append("price", productData.price);
      formData.append("selling_price", productData.selling_price);
      formData.append("source", 1);
      formData.append("brandId", user.brand_id);
      formData.append("sku_code", "");
      formData.append("product_tag", 1);

      formData.append("brand_product_id", "");
      formData.append("product_attribute", "");
      // if (productData.image && imageFile) {
      //     formData.append('image', productData.image);

      // } else {
      formData.append("product_image", productData.image);
      formData.append("environment", environment);
      // }

      try {
        await allApiInstance
          .post("/addProduct", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data) {
              if (res.data.status == 1) {
                setSelectedOption({
                  ...res.data,
                  value: res.data.response.productId,
                  label: res.data.response.productTitle,
                });
                setShowForm(true);
                toast.success("Product added and link generated");
                handleClose();
                setSubmitLoader(false);
                setLoading(false);
              } else {
                toast.warn(res.data.message);
                setSubmitLoader(false);
                setLoading(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong!");
            setSubmitLoader(false);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
        setSubmitLoader(false);
        setLoading(false);
      }
    } else {
      toast.warn("Please choose an image");
    }
  };

  const clearSelection = () => {
    setProductData((prevState) => ({
      ...prevState,
      image: null,
    }));
    // setImagePreview(null);
    setImagename("");
    setImageFile(null);
    fileInputRef.current.value = "";
  };

  if (!isOpen && !isClosing) return null;

  return (
    <div
      className="review-modal-overlay"
      style={{ pointerEvents: loading ? "none" : "auto" }}
    >
      <div className="review-modal-content" ref={modalRef}>
        <button className="review-modal-close" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 44 44"
            fill="none"
          >
            <rect width="44" height="44" rx="22" fill="white" />
            <g clipPath="url(#clip0_483_11622)">
              <path
                d="M22 20.586L26.95 15.636L28.364 17.05L23.414 22L28.364 26.95L26.95 28.364L22 23.414L17.05 28.364L15.636 26.95L20.586 22L15.636 17.05L17.05 15.636L22 20.586Z"
                fill="#454549"
              />
            </g>
            <defs>
              <clipPath id="clip0_483_11622">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <form onSubmit={handleSubmit} style={{ opacity: loading ? "0.6" : 1 }}>
          <div className="input-group-container">
            <label htmlFor="productUrlInput" className="review-font">
              Enter product URL <sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              id="productUrlInput"
              name="product_link"
              className="form-control mt-2"
              placeholder="Enter the link"
              value={productData.product_link}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group-container mt-1">
            <label htmlFor="productNameInput" className="review-font">
              Product name <sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              id="productNameInput"
              name="product_title"
              className="form-control mt-2"
              placeholder="Enter Product name"
              value={productData.product_title}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group-container mt-1">
            <label htmlFor="descriptionInput" className="review-font">
              Description <sup className="text-danger">*</sup>
            </label>
            <textarea
              id="descriptionInput"
              name="product_desc"
              rows="4"
              className="form-control mt-2"
              placeholder="Description"
              value={productData.product_desc}
              onChange={handleChange}
              required
            />
          </div>

          <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ flex: 1, position: "relative" }}>
              <label className="review-font mt-2" htmlFor="mrpInput">
                MRP <sup className="text-danger">*</sup>
              </label>
              <input
                type="number"
                id="mrpInput"
                name="price"
                className="form-control mt-2"
                placeholder="Enter MRP"
                value={productData.price}
                onChange={handleChange}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                required
              />
            </div>
            <div style={{ flex: 1, position: "relative" }}>
              <label className="review-font mt-2" htmlFor="sellingPriceInput">
                Selling Price <sup className="text-danger">*</sup>
              </label>
              <input
                type="number"
                id="sellingPriceInput"
                name="selling_price"
                className="form-control mt-2"
                placeholder="Enter Selling Price"
                value={productData.selling_price}
                onChange={handleChange}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
              />
            </div>
          </div>

          <label className="review-font mt-3">
            Cover image <sup className="text-danger">*</sup>
          </label>
          <div
            className="input-group mb-1"
            style={{
              border: "1px solid rgba(209, 209, 210, 1)",
              borderRadius: "8px",
            }}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
            <input
              type="text"
              className="form-control"
              style={{ border: "none" }}
              placeholder={
                productData.image
                  ? !imageFile
                    ? productData.image.split("/").pop()
                    : imagename
                  : "No file chosen"
              }
              onClick={() => fileInputRef.current.click()}
              readOnly
            />
            <button
              className="btn"
              type="button"
              style={{
                border: "none",
                backgroundColor: "rgba(232, 232, 232, 1)",
                borderRadius: "8px",
                padding: "0 13px",
                fontSize: "12px",
                margin: "5px",
              }}
              onClick={() => fileInputRef.current.click()}
            >
              Browse
            </button>
          </div>

          {productData.image && (
            <div className="card p-1 mb-2">
              <div className="d-flex w-100">
                <img
                  style={{ height: "50px", width: "35px", borderRadius: "3px" }}
                  src={productData.image}
                  alt="Selected file preview"
                />
                <div className="d-flex flex-column ps-2 pt-1">
                  <small className="text-muted">
                    {!imageFile
                      ? productData.image.split("/").pop()
                      : imagename}
                  </small>
                  <small style={{ color: "rgb(162, 162, 164)" }}>
                    {imageFile && `${(imageSize / 1024).toFixed(2)} KB`}{" "}
                    <b>.</b> <span className="text-success">Uploaded</span>
                  </small>
                </div>
                <div className="ms-auto" onClick={clearSelection}>
                  <IoClose className="pointer_cursor" />
                </div>
              </div>
            </div>
          )}

          <button className="btn btn-sm btn-dark">
            Generate Link{" "}
            {submitloader ? <FaSpinner className="spinning" /> : ""}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReviewModal;
