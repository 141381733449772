import React, { useEffect, useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Helmet } from 'react-helmet';
import './products.css';
import { MdOutlineModeEdit } from 'react-icons/md';
import { GrTrash } from 'react-icons/gr';
import Product from '../components/products/Product';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import { RotatingLines } from 'react-loader-spinner';
import { BiExport } from 'react-icons/bi';
import {
  FaCalendar,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaSearch,
} from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Importing jspdf-autotable for table creation
import { confirmAlert } from 'react-confirm-alert';
import { allApiInstance } from '../axiosInstances';
const Products = () => {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [xstate, setxState] = useState([
    {
      startDate: new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000),
      endDate: yesterday,
      key: 'selection',
    },
  ]);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleApply = () => {
    setDropdownOpen(false); // Close the dropdown
    setState(xstate);
  };

  const [products, setProducts] = useState([]);

  const handleEditPage = (products, productId) => {
    navigate(`/admin/product-edit/${btoa(productId)}`);
  };

  const columns = [
    {
      name: 'Sr',
      selector: (row) => row.index + 1,
      width: '50px',
      style: {
        paddingLeft: '18px',
      },
    },
    {
      name: 'Date',
      selector: (row) => row.createdDate.slice(0, 10),
      // width: "100px",
    },
    {
      name: 'Image',
      selector: (row) => row.image,
      cell: (row) => {
        return (
          <>
            <img height={70} width={70} src={row?.image} />
          </>
        );
      },
      // width: "110px"
    },
    {
      name: 'Product title',
      selector: (row) => row.title,
      cell: (row) => {
        return <p>{row.title}</p>;
      },
      width: '150px',
    },
    {
      name: 'Product description',
      selector: (row) => row.description,
      cell: (row) => {
        return (
          <p title={row.description}>
            {row.description.length > 120
              ? `${row.description.slice(0, 120)} ...`
              : row.description}
          </p>
        );
      },
      width: '300px',
    },
    {
      name: 'Original price',
      selector: (row) => row.price,
      // width: "100px"
    },
    {
      name: 'Discounted price',
      selector: (row) => (row.discountPrice ? row.discountPrice : '-'),
      // width: "100px"
    },
    {
      name: 'SKU code',
      selector: (row) => (row.skuCode ? row.skuCode : '-'),
      // width: "100px"
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      // width: "70px",
      style: {
        textAlign: 'center',
        paddingLeft: '19px',
      },
      cell: (row) => {
        return (
          <>
            <div className="form-check form-switch d-flex justify-content-between align-items-center ">
              <input
                className="form-check-input switch-lg"
                type="checkbox"
                role="switch"
                id={`status-switch-${row.id}`}
                name={`status-switch-${row.id}`}
                checked={row.productActiveState === 0 ? true : false}
                onChange={() => {
                  updateProductStatus(
                    user.brand_id,
                    row.productId,
                    row.productActiveState
                  );
                }}
              />
            </div>
          </>
        );
      },
    },
    {
      name: 'Action',
      selector: (row) => row.videoCount,
      cell: (row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <MdOutlineModeEdit
                className="pointer_cursor"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleEditPage(products, row.productId, row.name);
                }}
                size={20}
              />
              <GrTrash
                className="pointer_cursor"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleDeleteModal(row.productId);
                }}
                size={20}
              />
            </div>
          </>
        );
      },
      width: '100px',
    },
  ];

  const handleDeleteModal = (id) => {
    confirmAlert({
      title: 'Do you really want to delete the product?',
      // message: "Are you sure to do this.",
      buttons: [
        {
          label: 'Yes, delete',
          onClick: () => handleDeleteProduct(id),
        },
        {
          label: 'Cancel',
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  const customStyles = {
    table: {
      style: {
        width: 'auto',
        minWidth: '200px',
        // overflowX: "hidden"
      },
    },

    headCells: {
      style: {
        backgroundColor: '#F1F1EE', // Set background color for header cells
        color: '#000', // Set text color for header cells
        border: '1px solid #E0E0E0', // Add border to header cells
      },
    },
    cells: {
      style: {
        border: '1px solid #F1F1EE',
        padding: '10px',
        textWrap: 'wrap',
        whiteSpace: 'normal',
      },
    },

    headRow: {
      style: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#f1f1f1',
        zIndex: 1,
      },
    },
    footer: {
      style: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#f1f1f1',
        zIndex: 1,
      },
    },
  };
  const user = useSelector((state) => state.auth.user);
  const handleFilterProduct = async (firstLoad = true) => {
    if (firstLoad) {
      setLoading(true);
    }

    try {
      await allApiInstance
        .get(
          `/getProductsByBrandIdWithSearchFilterAndPagination`,
          {
            params: {
              brandId: user.brand_id,
              searchQuery: query,
              startDate: state[0].startDate
                ? format(state[0].startDate, 'yyyy-MM-dd')
                : '',
              endDate: state[0].endDate
                ? format(state[0].endDate, 'yyyy-MM-dd')
                : '',
              limit: rowsPerPage,
              offset: page,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setData(res.data.response.products);
            setProducts(res.data.response.products);
            setTotalRows(res.data.response.rowCount);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleDeleteProduct = async (product) => {
    try {
      await allApiInstance
        .patch(`/deleteProductByProductId`, {
          productId: product,
          brandId: user.brand_id,
        })
        .then((res) => {
          if (res.data) {
            toast.success('deleted successfully');
            handleFilterProduct(page, rowsPerPage);
          }
        });
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };
  const environment = process.env.REACT_APP_PDP_ENV
  const updateProductStatus = async (
    brandId,
    productId,
    productActiveState
  ) => {
    try {
      await allApiInstance
        .patch('/updateProductStatus', {
          brandId,
          productId,
          productActiveState: productActiveState === 0 ? 1 : 0,
          environment
        })
        .then((res) => {
          if (res.data) {
            toast.success(
              `Product ${productActiveState === 1 ? 'activated' : 'deactivated'}`,
              { autoClose: 1000 }
            );
            handleFilterProduct();
          }
        })
        .catch((err) => {
          toast.error('Something went wrong!');
          console.log(err);
        });
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const downloadCSV = () => {
    const headers = [
      'Date',
      'Product title',
      'Product description',
      'Original price',
      'Discounted price',
      'SKU code',
      'Status',
    ];
    const fieldsToExport = [
      'createdDate',
      'title',
      'description',
      'price',
      'discountPrice',
      'skuCode',
      'productActiveState',
    ];

    const handleMissingKey = (obj, key, defaultValue = '-') => {
      return obj.hasOwnProperty(key) ? obj[key] : defaultValue;
    };

    const escapeCSVValue = (value) => {
      if (typeof value === 'string') {
        value = value.replace(/"/g, '""');
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    };

    let csvContent = headers.map(escapeCSVValue).join(',') + '\n';
    data.forEach((item) => {
      const row = fieldsToExport.map((field) => {
        return escapeCSVValue(handleMissingKey(item, field));
      });
      csvContent += row.join(',') + '\n';
    });

    const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, `ProductData${new Date()}.csv`);
  };

  const downloadExcel = () => {
    // Define your custom column headers
    const headers = [
      'Date',
      'Product title',
      'Product description',
      'Original price',
      'Discounted price',
      'SKU code',
      'Status' /* Add more titles as needed */,
    ];

    // Function to handle missing keys and set default value
    const handleMissingKey = (obj, key, defaultValue = '-') => {
      return obj.hasOwnProperty(key) ? obj[key] : defaultValue;
    };

    // Define which fields to export
    const fieldsToExport = [
      'createdDate',
      'title',
      'description',
      'price',
      'discountPrice',
      'skuCode',
      'productActiveState',
    ];

    // Convert data to an array of arrays with headers as the first row
    const modifiedData = data.map((item) => {
      // Make changes to the 'video_title' key (example: convert to uppercase)
      const modifiedItem = { ...item };
      return fieldsToExport.map((field) =>
        handleMissingKey(modifiedItem, field)
      );
    });

    const excelData = [headers, ...modifiedData];

    // Convert data to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);

    // Create workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert workbook to Excel buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Trigger download
    saveAs(
      new Blob([excelBuffer], { type: 'application/octet-stream' }),
      `ProductData${new Date()}.xlsx`
    );
  };

  const downloadPDF = () => {
    const doc = new jsPDF('l', 'pt', 'a4', true); // Landscape orientation

    // Define your custom column headers
    const headers = [
      'Date',
      'Product title',
      'Product description',
      'Original price',
      'Discounted price',
      'SKU code',
      'Status' /* Add more titles as needed */,
    ];
    const fields = [
      'createdDate',
      'title',
      'description',
      'price',
      'discountPrice',
      'skuCode',
      'productActiveState',
    ];

    // Function to handle missing keys and set default value
    const handleMissingKey = (obj, key, defaultValue = '-') => {
      return obj.hasOwnProperty(key) ? obj[key] : defaultValue;
    };

    const tableData = [headers];
    data.forEach((item) => {
      // Modify the 'video_title' key (example: convert to uppercase)
      const modifiedItem = { ...item };

      const row = fields.map((field) => handleMissingKey(modifiedItem, field));
      tableData.push(row);
    });

    doc.autoTable({
      head: [headers],
      body: tableData.slice(1), // Exclude headers from body data
      headStyles: {
        fillColor: [238, 238, 119],
        textColor: [0, 0, 0],
      },
      styles: { cellPadding: 1, fontSize: 5 },
      columnStyles: { 0: { cellWidth: 'auto' } },
      margin: { top: 20 },
      startY: 20,
    });
    doc.save(`ProductData${new Date()}.pdf`);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = (newPerPage, newPage) => {
    setRowsPerPage(newPerPage);
    setPage(newPage);
  };

  useEffect(() => {
    // Initialize a timer variable
    let timer;
    // Set a timeout to trigger the alert after 400 milliseconds
    timer = setTimeout(() => {
      handleFilterProduct();
    }, 400);
    // Clear the previous timeout if `colorPickers` changes before the timeout completes
    return () => clearTimeout(timer);
  }, [state, query, page, rowsPerPage]); // useEffect dependency on `colorPickers`

  // useState(() => {

  // },[])

  return (
    <>
      <Helmet>
        <title>SWIRL | Products</title>
      </Helmet>
      <div>
        <h3 className="mt-1 ms-3 product">Products</h3>
        <ul
          className="nav nav-tabs"
          id="responsive-tabs-product"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link  active"
              id="video-tab"
              data-bs-toggle="tab"
              data-bs-target="#productlist"
              type="button"
              role="tab"
              aria-controls="video"
              aria-selected="true"
            >
              Product List
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="video-tab"
              data-bs-toggle="tab"
              data-bs-target="#createproduct"
              type="button"
              role="tab"
              aria-controls="video"
              aria-selected="true"
            >
              {' '}
              Add Products
            </button>
          </li>
        </ul>
        <div className="tab-content p-3" id="responsive-tabs-content">
          <div
            className="tab-pane fade show active"
            id="productlist"
            role="tabpanel"
            aria-labelledby="video-tab"
          >
            <div className="w-100 d-flex gap-2 flex-row-reverse">
              <div className="d-flex gap-3">
                {/* <input type='search' className='form-control' style={{ maxWidth: "270px" }} placeholder='🔍  Search product' onChange={(e) => { setQuery(e.target.value) }} /> */}
                {/* <InputGroup className="mb-3" style={{ border: "1px solid rgba(209, 209, 210, 1)", borderRadius: "7px" }}>
                                    <InputGroup.Text id="basic-addon1" style={{ backgroundColor: "white", border: "none" }}><i class="ri-search-line"></i></InputGroup.Text>
                                    <Form.Control
                                        style={{ border: 'none', color: "rgba(139, 139, 142, 1)" }}
                                        type='search'
                                        placeholder=" Search Product..."
                                        onChange={(e) => { setQuery(e.target.value) }}
                                    />

                                </InputGroup> */}
                <InputGroup
                  className="mb-3 no-space-group"
                  style={{
                    border: '1px solid rgba(209, 209, 210, 1)',
                    borderRadius: '7px',
                  }}
                >
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{
                      backgroundColor: 'white',
                      border: 'none',
                      padding: '6px',
                    }}
                  >
                    <i className="ri-search-line"></i>
                  </InputGroup.Text>
                  <Form.Control
                    className="form-control"
                    type="search"
                    placeholder="Search Products..."
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                </InputGroup>
                <div className="dropdown">
                  <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="bg-transparent"
                      style={{
                        border: '1px solid rgba(209, 209, 210, 1)',
                        color: 'rgba(139, 139, 142, 1)',
                      }}
                    >
                      {state[0].endDate !== null
                        ? `${state[0].startDate.toString().slice(4, 15)} to ${state[0].endDate.toString().slice(4, 15)}`
                        : 'Search by date'}
                      {/* //<FaCalendar className='ms-5 me-1' style={{ color: "rgba(69, 69, 73, 1)" }} /> */}
                      <i
                        className="ri-calendar-line"
                        style={{
                          marginLeft: '30px',
                          marginBottom: '1px',
                          color: 'rgba(69, 69, 73, 1)',
                        }}
                      ></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <DateRangePicker
                        editableDateInputs={false}
                        onChange={(item) => setxState([item.selection])}
                        moveRangeOnFirstSelection={true}
                        ranges={xstate}
                        months={2}
                        direction="horizontal"
                        maxDate={today}
                      // minDate={authData?.brand_id == "236633565" ? new Date(2024, 2, 15) : new Date(2024, 3, 5)}
                      // rangeColors={['#17171C']}
                      />
                      <div className="w-100">
                        <button
                          id="dropdown-basic"
                          className="btn btn-sm btn-primary float-end me-3"
                          onClick={handleApply}
                        >
                          Done
                        </button>
                        <button
                          id="dropdown-basic"
                          className="btn btn-sm btn-outline-primary float-end me-3"
                          onClick={() => {
                            setxState([
                              {
                                startDate: null,
                                endDate: null,
                                key: 'selection',
                              },
                            ]);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <button className='btn ' style={{ backgroundColor: "#EEEE77", border: "none" }}>Export</button> */}
                <Dropdown className="ms-1">
                  {window.innerWidth > 556 ? (
                    <Dropdown.Toggle
                      className="text-dark border-0"
                      style={{ backgroundColor: '#EEEE77' }}
                    >
                      Export
                    </Dropdown.Toggle>
                  ) : (
                    <Dropdown.Toggle
                      className="text-dark border-0"
                      style={{ backgroundColor: '#EEEE77' }}
                    >
                      <BiExport />
                    </Dropdown.Toggle>
                  )}

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={downloadCSV}>
                      <FaFileCsv color="gray" /> Export as CSV{' '}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={downloadExcel}>
                      <FaFileExcel color="green" /> Export as XLS
                    </Dropdown.Item>
                    <Dropdown.Item onClick={downloadPDF}>
                      <FaFilePdf color="red" /> Export as PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div style={{ width: '100%', overflow: 'auto' }}>
              <div
                style={{
                  overflowX: 'hidden',
                  display: 'inline-block',
                  height: '100%',
                  width: '100%',
                }}
              >
                <DataTable
                  columns={columns}
                  data={products.map((item, index) => ({
                    ...item,
                    index: (page - 1) * rowsPerPage + index,
                  }))}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="355px"
                  paginationServer
                  paginationTotalRows={totalRows}
                  progressPending={loading}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  progressComponent={
                    <div
                      className="p-3 m-4 w-100 d-grid"
                      style={{ placeItems: 'center' }}
                    >
                      <RotatingLines
                        height={30}
                        width={30}
                        strokeColor="grey"
                      />
                    </div>
                  }
                  // progressPending={loading}
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade show "
            id="createproduct"
            role="tabpanel"
            aria-labelledby="video-tab"
          >
            <Product />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
