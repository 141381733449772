export const Professionalsms = (templateNumber, { user, selectedOption }) => {
  switch (templateNumber) {
    case 1:
      return `
           Hello [Customer’s First Name], we’d love to hear your thoughts on ${selectedOption?.label}. Please share your experience in a brief review: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. We appreciate your time.

            `;
    case 2:
      return `
             We’re committed to excellence, and your feedback helps us get there. Please share your thoughts on ${selectedOption?.label}: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}

            `;
    case 3:
      return `
Hello! We'd value your feedback on your recent purchase. Share a review of ${selectedOption?.label} here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. Your input is appreciated!

            `;
    case 4:
      return `
             Hello! We'd value your feedback on your recent purchase. Share a review of ${selectedOption?.label} here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. Your input is appreciated!

            `;
    case 5:
      return `
          Your opinion helps us serve you better. Please share a quick review of ${selectedOption?.label} here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}. Thank you for your time.

            `;
    case 6:
      return `
             We appreciate your purchase! Could you take a moment to share your experience with ${selectedOption?.label} by leaving a review? ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}.

    
                `;
  }
};
