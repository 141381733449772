import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReviewCard from "./ReviewCard";
import { RiFileCopyLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EmbeddedModal = ({ embeddedshow, handleCloseembbeded, id }) => {
  const modalRef = useRef(null);
  // alert(id)
  const [moderatingObj, setModeratingObj] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [videos, setVideos] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [input2, setInput2] = useState(
    `<div class="swirl-short-videos-c-ssv13" data-code="${user.short_code}" data-playlist="" data-pdp="product/,products/,collection/" data-wt="0" data-reviewid="${id}"></div>`
  );
  const [input1, setInput1] = useState(
    '<script type="text/javascript" src="https://apigoswirl.com/short_video/reviews/short-video-reviews.min.js"></script>'
  );

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (embeddedshow) {
        modalElement.classList.add("slide-down");
        modalElement.classList.remove("slide-up");
      } else {
        modalElement.classList.add("slide-up");
        modalElement.classList.remove("slide-down");
      }
    }
  }, [embeddedshow, handleCloseembbeded]);

  useEffect(() => {
    if (!id) {
      // console.error('UUID is not available');
      return;
    }

    setInput2(
      `<div class="swirl-short-videos-c-ssv13" data-code="${user.short_code}" data-playlist="" data-pdp="product/,products/,collection/" data-wt="0" data-reviewid="${id}"></div>`
    );
  }, [id, user.short_code]);

  const handleCloseed = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.classList.add("slide-up");

      setTimeout(() => {
        setIsClosing(false);
        handleCloseembbeded();
      }, 500);
    }
  };

  const copyToClipboard = (text) => {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    toast.success("Text copied to clipboard!");
  };

  if (!embeddedshow && !isClosing) return null;

  return (
    <div className="review-modal-overlay">
      <div
        className="review-modal-content"
        ref={modalRef}
        style={{ height: "40vh" }}
      >
        <button
          className="review-modal-close"
          style={{ top: "-15%" }}
          onClick={handleCloseed}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 44 44"
            fill="none"
          >
            <rect width="44" height="44" rx="22" fill="white" />
            <g clipPath="url(#clip0_483_11622)">
              <path
                d="M22 20.586L26.95 15.636L28.364 17.05L23.414 22L28.364 26.95L26.95 28.364L22 23.414L17.05 28.364L15.636 26.95L20.586 22L15.636 17.05L17.05 15.636L22 20.586Z"
                fill="#454549"
              />
            </g>
            <defs>
              <clipPath id="clip0_483_11622">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div style={{ overflow: "auto", height: "100%" }}>
          <h2>Embbed Code</h2>
          <label className="label mt-2 mb-2">
            Step-1: Add the below script in the footer
          </label>
          <div className="input-group mb-1">
            <input
              type="text"
              className="form-control"
              value={input1}
              readOnly
              placeholder="Enter text here..."
            />
            <span
              className="input-group-text"
              onClick={() => copyToClipboard(input1)}
              style={{ cursor: "pointer" }}
            >
              <RiFileCopyLine style={{ fontSize: "1.2rem" }} />
            </span>
          </div>
          <label className="label mt-2 mb-2">
            Step-2: Add the below code in the Product Details Page(PDP)
          </label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={input2}
              readOnly
              placeholder="Enter text here..."
            />
            <span
              className="input-group-text"
              onClick={() => copyToClipboard(input2)}
              style={{ cursor: "pointer" }}
            >
              <RiFileCopyLine style={{ fontSize: "1.2rem" }} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbeddedModal;
