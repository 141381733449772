import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { pdpApiInstance } from "../../axiosInstances";
import { toast } from "react-toastify";

const Setting = () => {
  const [aiChat, setAiChat] = useState(false);
  const [editorData, setEditorData] = useState({
    dos: "",
    donts: "",
    terms: "",
  });
  const [settingChanged, setSettingChanged] = useState(false);

  const handleToggle = () => {
    setAiChat((prevState) => !prevState);
  };

  const getReviewSetting = async () => {
    try {
      await pdpApiInstance
        .get("api/brands/pdpsettings", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data) {
            const userSettings = res.data.data;
            setEditorData({
              dos: userSettings.do
                ? userSettings.do
                : `<ol><li>Outline the key points you want to cover and create a script if necessary.</li><li>Use natural light or studio lights to ensure the subject is well-lit.</li><li>Conduct a test run to check video and audio quality.</li><li>Choose a quiet and visually appealing environment.</li></ol>`,
              donts: userSettings.dont
                ? userSettings.dont
                : "<ol><li>Avoid dim or harsh lighting that affects video quality.</li><li>Avoid shaky camera work unless for effect.</li><li>Avoid cluttered or busy backgrounds.</li><li>Don’t rely on the camera’s built-in microphone.</li></ol>",
              terms: userSettings.termsAndCondition
                ? userSettings.termsAndCondition
                : "",
            });
            setAiChat(userSettings.aiVideoSearch);
          }
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getReviewSetting();
  }, []);

  const handleSubmit = async (chat) => {
    try {
      await pdpApiInstance
        .put(
          "api/brands/pdpsettings",
          {
            do: editorData.dos,
            dont: editorData.donts,
            termsAndCondition: editorData.terms,
            aiVideoSearch: chat !== undefined ? !chat : aiChat,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          toast.success("Review settting updated");
        })
        .catch((err) => {
          toast.err("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  // useEffect(() => {
  //   if (settingChanged) {
  //     handleSubmit()
  //   }
  // }, [aiChat])

  return (
    <div>
      <div className="row">
        <div className="col-10">
          <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
            <div>
              <label
                className="fs-18 form-check-label"
                htmlFor="buySwitch"
                style={{ paddingLeft: "20px" }}
              >
                AI video search
                <br />
                <div className="fs-16 mt-1 mb-1">
                  This feature activates AI search within the video.
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className="col-2 d-flex align-items-center justify-content-end">
          <div className="form-check form-switch">
            <input
              className="form-check-input switch-lg"
              type="checkbox"
              role="switch"
              id="buySwitch"
              checked={aiChat}
              onChange={(e) => {
                handleToggle();
                handleSubmit(aiChat);
              }}
              aria-label="Toggle AI video chat"
            />
          </div>
        </div>
      </div>
      <Accordion defaultActiveKey="0" className="custom-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Do's</Accordion.Header>
          <Accordion.Body>
            <Card>
              <CKEditor
                editor={ClassicEditor}
                data={editorData.dos}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorData((prev) => ({ ...prev, dos: data }));
                  setSettingChanged(true);
                }}
              />
            </Card>
            <button
              className="btn btn-dark  mt-3"
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Don't</Accordion.Header>
          <Accordion.Body>
            <CKEditor
              editor={ClassicEditor}
              data={editorData.donts}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditorData((prev) => ({ ...prev, donts: data }));
                setSettingChanged(true);
              }}
            />
            <button
              className="btn btn-dark  mt-3"
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </Accordion.Body>
        </Accordion.Item>
        {/* <Accordion.Item eventKey="2">
          <Accordion.Header>Terms and Condition</Accordion.Header>
          <Accordion.Body>
            <CKEditor
              editor={ClassicEditor}
              data={editorData.terms}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditorData((prev) => ({ ...prev, terms: data }));
                setSettingChanged(true)
              }}
            />
            <button
              className="btn btn-dark mt-3"
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
    </div>
  );
};

export default Setting;
