// authApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL_LOGIN }), // Your API base URL
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/doLogin', // Your login API endpoint
        method: 'POST',
        body: credentials,
      }),
    }),
    tokenGenerate: builder.mutation({
      query: (credentials) => ({
        url: '/login', // Your login API endpoint
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const { useLoginMutation, useTokenGenerateMutation } = authApi;
