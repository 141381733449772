import React, { useState, useEffect, useRef } from 'react';
import ProductUrl from './ProductUrl';
import { MdAddShoppingCart } from 'react-icons/md';
import { LuPictureInPicture } from 'react-icons/lu';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
import { FaRegComments } from 'react-icons/fa6';
import { MdReviews } from 'react-icons/md';
import { GoMute } from 'react-icons/go';
import { VscUnmute } from 'react-icons/vsc';
import { FaShare, FaTrophy, FaFireAlt } from 'react-icons/fa';
import img from '../../assets/images/newdeals.png';
import ProductSync from './ProductSync';
import Bulk from './Bulk';
import { RiImageAddFill } from 'react-icons/ri';
import { FallingLines } from 'react-loader-spinner';
import './products.css';
const Product = () => {
  const [selectedOption, setSelectedOption] = useState('url');
  const [loading, setLoading] = useState(false);
  const [productLink, setProductLink] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [mrp, setMrp] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [productTag, setProductTag] = useState(0);
  const [productImage, setProductImage] = useState(null);
  const [productImageName, setProductImageName] = useState('');
  const [productImagePreview, setProductImagePreview] = useState('');
  const [productImageSize, setProductImageSize] = useState(null);
  const [brandid, setBrandid] = useState('');
  const [productsku, setProductSku] = useState('');
  const [productattribute, setProductAttribute] = useState('');
  const [discount, setDiscount] = useState(null);
  const [fetchedImage, setFetchedImage] = useState('');
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef?.current?.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProductImageName(file.name);
      setProductImageSize((file.size / 1024).toFixed(2) + ' KB');
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const calculateDiscount = () => {
    if (
      parseInt(mrp) > 0 &&
      parseInt(sellingPrice) > 0 &&
      parseInt(mrp) >= parseInt(sellingPrice)
    ) {
      const discountPercentage =
        ((parseInt(mrp) - parseInt(sellingPrice)) / parseInt(mrp)) * 100;
      setDiscount(discountPercentage.toFixed(2));
    } else {
      setDiscount(null);
    }
  };

  useEffect(() => {
    calculateDiscount();
  }, [mrp, sellingPrice]);

  const videoData = {
    video_title: 'Dashing Red Mini Ferrari',
    cover_image: 'https://cdn-product-images.goswirl.live/25155740-0ed5-4a8e-b540-38f7c9684695.webp',
    video_url: 'https://d1g3m9ml694eqp.cloudfront.net/1715162670.mp4',
    price: '749.00',
    discount_price: '719.00',
    image: 'https://cdn-product-images.goswirl.live/25155740-0ed5-4a8e-b540-38f7c9684695.webp',
  };

  if (loading) {
    return (
      <div
        className="w-100 d-grid "
        style={{ placeItems: 'center', height: '70vh' }}
      >
        <FallingLines
          color="#EEEE77"
          width="100"
          visible={true}
          ariaLabel="falling-circles-loading"
        />
      </div>
    );
  } else {
    return (
      <>
        {/* <div className='position-absolute ' style={{ height: "70vh", backgroundColor: "red", width: "70%" }}>123</div> */}
        <div className="container-fluid mt-3 m-0">
          <div className="row m-0">
            <div
              className="col-md-7"
              style={{ height: '70vh', overflow: 'auto' }}
            >
              <div className="d-flex mt-2">
                <div className="orientation form-check me-5">
                  <input
                    type="radio"
                    className="orientation form-check-input"
                    name="orientationStyle"
                    id="URL"
                    value="url"
                    checked={selectedOption === 'url'}
                    onChange={handleOptionChange}
                  />
                  <label className="form-check-label" htmlFor="URL">
                    From URL
                  </label>
                </div>
                <div className="orientation form-check me-5 d-none">
                  <input
                    type="radio"
                    className="orientation form-check-input"
                    name="orientationStyle"
                    id="ProductSync"
                    value="ProductSync"
                    checked={selectedOption === 'ProductSync'}
                    onChange={handleOptionChange}
                  />
                  <label
                    className="form-check-label orientation"
                    htmlFor="ProductSync"
                  >
                    Product Sync
                  </label>
                </div>
                <div className="form-check me-5">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="orientationStyle"
                    id="BulkProduct"
                    value="BulkProduct"
                    checked={selectedOption === 'BulkProduct'}
                    onChange={handleOptionChange}
                  />
                  <label className="form-check-label" htmlFor="BulkProduct">
                    Bulk Product
                  </label>
                </div>
              </div>
              {selectedOption === 'url' && (
                <ProductUrl
                  productLink={productLink}
                  setProductLink={setProductLink}
                  productName={productName}
                  setProductName={setProductName}
                  productDescription={productDescription}
                  setProductDescription={setProductDescription}
                  mrp={mrp}
                  setMrp={setMrp}
                  sellingPrice={sellingPrice}
                  setSellingPrice={setSellingPrice}
                  productImagePreview={productImagePreview}
                  setProductImagePreview={setProductImagePreview}
                  productImageName={productImageName}
                  setProductImageName={setProductImageName}
                  productImage={productImage}
                  setProductImage={setProductImage}
                  productTag={productTag}
                  setProductTag={setProductTag}
                  productattribute={productattribute}
                  setProductAttribute={setProductAttribute}
                  productsku={productsku}
                  setProductSku={setProductSku}
                  brandid={brandid}
                  setBrandid={setBrandid}
                  productImageSize={productImageSize}
                  setProductImageSize={setProductImageSize}
                  discount={discount}
                  setDiscount={setDiscount}
                  setLoading={setLoading}
                  loading={loading}
                  fetchedImage={fetchedImage}
                  setFetchedImage={setFetchedImage}
                />
              )}
              {selectedOption === 'ProductSync' && <ProductSync />}
              {selectedOption === 'BulkProduct' && <Bulk />}
            </div>

            {selectedOption === 'url' && (
              <div className="col-md-5 me-0">
                <div
                  className="container-fluid position-relative"
                  style={{
                    height: '68vh',
                    backgroundColor: '#F8F8FF',
                    width: '100%',
                  }}
                >
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-7 pt-4">
                      <div
                        className="card mb-4 position-relative"
                        style={{ borderRadius: '20px' }}
                      >
                        <video
                          className="card-img-top"
                          style={{ borderRadius: '20px' }}
                          poster={videoData.cover_image}
                          autoPlay
                          loop
                          muted
                        >
                          <source src={videoData.video_url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        {/* <div className='position-absolute top-0 end-0 mt-2' style={{ marginRight: "20%" }}>
                          <LuPictureInPicture color='white' size={22} />
                        </div>
                        <div className='position-absolute top-0 end-0 mt-2' style={{ marginRight: "10%" }}>
                          <HiOutlineShoppingCart color='white' size={22} />
                        </div>
                        <div className='position-absolute top-0 end-0 mt-2 me-1'>
                          <IoClose color='white' size={22} />
                        </div> */}
                        <div className="position-absolute end-0 d-flex flex-row align-items-end mt-2 me-2 gap-2">
                          <LuPictureInPicture color="white" size={22} />
                          <HiOutlineShoppingCart color="white" size={22} />
                          <IoClose color="white" size={22} />
                        </div>
                        <div
                          className="position-absolute end-0 d-flex flex-column align-items-end"
                          style={{ marginRight: '5%', marginTop: '75%' }}
                        >
                          <VscUnmute color="white" size={22} className="mb-3" />
                          <FaRegComments
                            color="white"
                            size={22}
                            className="mb-3"
                          />
                          <MdReviews color="white" size={22} className="mb-3" />
                          <FaShare color="white" size={22} className="mb-2" />
                        </div>

                        <div
                          className="card-body position-absolute justify-content-center ms-1"
                          style={{
                            borderRadius: '8px',
                            marginBottom: '7px',
                            background: 'rgba(255, 255, 255, 0.9)',
                            width: '97%',
                            bottom: '0',
                          }}
                        >
                          <div
                            className="d-flex "
                            style={{ zIndex: '1', position: 'relative' }}
                          >
                            {productTag == '1' && (
                              <span
                                className="badge position-absolute start-0 p-2 tags1"
                                style={{
                                  borderRadius: '12px 12px 2px 2px',
                                  backgroundColor: '#CD6113',
                                  fontWeight: 'lighter',
                                  zIndex: '1',
                                }}
                              >
                                <FaTrophy
                                  className="pe-1"
                                  color="#F3CE58"
                                  size={15}
                                />
                                Best Seller
                              </span>
                            )}
                            {productTag == '2' && (
                              <span
                                className="badge position-absolute start-0 p-2  tags"
                                style={{
                                  color: '#FF9D33',
                                  borderRadius: '12px 12px 2px 2px',
                                  backgroundColor: '#FFF6DF',
                                  fontWeight: 'lighter',
                                  zIndex: '446',
                                }}
                              >
                                <FaFireAlt
                                  className="pe-1"
                                  color="#F3CE58"
                                  size={20}
                                />
                                Hot Deals
                              </span>
                            )}
                            {productTag == '3' && (
                              <span
                                className="badge position-absolute start-0 p-2 tags"
                                style={{
                                  borderRadius: '12px 12px 2px 2px',
                                  backgroundColor: 'white',
                                  fontWeight: 'lighter',
                                  color: '#A2A2A4',
                                  zIndex: '446',
                                }}
                              >
                                <img src={img} />
                                New
                              </span>
                            )}
                            <div
                              className="d-flex w-100"
                              style={{
                                borderRadius: `${productTag ? '0' : '10'}px 10px 10px 10px`,
                                overflow: 'hidden',
                              }}
                            >
                              <div
                                style={{ width: '25%', overflow: 'hidden' }}
                                onClick={handleImageClick}
                              >
                                {productImagePreview ||
                                  fetchedImage.length > 0 ? (
                                  <img
                                    src={productImagePreview || fetchedImage}
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      overflow: 'hidden',
                                    }}
                                    alt="video thumbnail"
                                  />
                                ) : (
                                  <div
                                    style={{
                                      display: 'grid',
                                      placeItems: 'center',
                                      height: '100%',
                                    }}
                                  >
                                    <RiImageAddFill
                                      style={{ zIndex: '5', cursor: 'pointer' }}
                                      size={32}
                                    />

                                    <input
                                      type="file"
                                      placeholder="select"
                                      ref={fileInputRef}
                                      style={{ display: 'none' }}
                                      onChange={handleImageChange}
                                    />
                                  </div>
                                )}
                              </div>
                              <div style={{ width: '75%' }}>
                                <div className="d-flex flex-column ps-2">
                                  <div
                                    className="m-0 p-0"
                                    style={{
                                      fontSize: '12px',
                                      textAlign: 'right',
                                    }}
                                  >
                                    <span
                                      className="badge text-muted p-2"
                                      style={{ backgroundColor: '#FCFCE4' }}
                                    >
                                      Rating: 4.5
                                    </span>
                                  </div>
                                  <span
                                    className="product_title_preview"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {productName.length === 0
                                      ? 'Product title'
                                      : productName}
                                  </span>
                                  <span
                                    style={{ fontSize: '12px', padding: '2px' }}
                                  >
                                    {!sellingPrice ? (
                                      <>
                                        {mrp.length === 0 ? 'mrp' : `$${mrp} `}
                                        {/* <del className="text-muted">${mrp.length === 0 ? "mrp" : mrp}</del> */}
                                      </>
                                    ) : (
                                      <>
                                        {sellingPrice == mrp ? (
                                          <>
                                            {mrp.length === 0
                                              ? 'mrp'
                                              : `$${mrp} `}
                                          </>
                                        ) : (
                                          <>
                                            $
                                            {sellingPrice.length === 0
                                              ? 'sell '
                                              : `${sellingPrice} `}
                                            <del className="text-muted">
                                              ${mrp.length === 0 ? 'mrp' : mrp}
                                            </del>
                                            <span className="badge_discount bg-success text-white fw-light p-1 rounded ms-1">
                                              <small>
                                                {discount
                                                  ? `${discount}%`
                                                  : '0%'}
                                              </small>
                                            </span>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </span>

                                  <div className="d-flex align-items-center gap-2">
                                    <button
                                      className="btn btn-sm btn-dark mt-2 buynow-button"
                                      style={{
                                        fontSize: '12px',
                                        width: '75%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      Buy now
                                    </button>
                                    <MdAddShoppingCart
                                      size={35}
                                      className="pe-2 mt-1"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className='col-3' style={{ cursor: "pointer" }} onClick={justCHeck}>
                            {productImagePreview ? (
                              <img
                                src={productImagePreview}
                                style={{ height: '100px', width: '80px', borderRadius: '0 0 0 10px' }}
                                alt='video thumbnail'
                              />
                            ) : (
                              <div>
  
                                <RiImageAddFill
  
                                  style={{ zIndex: "5", cursor: "pointer", margin: "50%" }}
                                  size={32}
                                />
  
                                <input
                                  type="file"
                                  placeholder='select'
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  onChange={handleImageChange} />
  
                              </div>
                            )}
                          </div>
  
  
                          <div className='col-9'>
                            <div className='d-flex flex-column ps-2'>
                              <div className='m-0 p-0' style={{ fontSize: "12px", textAlign: "right" }}>
                                <span className='badge text-muted p-2' style={{ backgroundColor: "#FCFCE4" }}>Rating: 4.5</span>
                              </div>
                              <span className='product_title_preview' style={{ fontSize: "14px" }}>{productName}</span>
                              <span style={{ fontSize: "12px", padding: "2px" }}>${sellingPrice}<del className="text-muted">${mrp}</del><span className='badge_discount bg-success text-white fw-light p-1 rounded ms-1'><small>{discount}%</small></span></span>
                              <div className='d-flex align-items-center gap-2'>
                                <button className='btn btn-sm btn-dark mt-2 ' style={{ fontSize: "12px", width: "75%" }}>Buy now</button>
                                <MdAddShoppingCart size={35} className='pe-2 mt-1' />
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Product;
