import React, { useEffect, useRef, useState } from "react";
import "./insight.css";
import ReactSlider from "react-slider";
const Sentiment = ({ obj, showTranslation }) => {
  const sliderRef = useRef();
  // const handleChange = (event) => {
  //     const newValue = event.target.value;
  //     // setValue(newValue);
  //     // Update the background based on the current value
  //     event.target.style.background = `linear-gradient(
  //       to right,
  //       rgba(11, 137, 92, 1) 0%,
  //      rgba(11, 137, 92, 1) ${newValue}%,
  //       #cfd8dc ${newValue}%,
  //       #cfd8dc 100%
  //     )`;
  // };
  console.log("obj", obj);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.background = `linear-gradient(
          to right,
          rgba(11, 137, 92, 1) 0%,
         rgba(11, 137, 92, 1) ${obj.sentimentalScore}%, 
          #cfd8dc ${obj.sentimentalScore}%,
          #cfd8dc 100%
        )`;
    }
  }, [obj]);

  return (
    <div>
      <div className="row">
        <div className="col-6">
          <div className="d-flex gap-2">
            <i
              className="ri-user-smile-line"
              style={{ fontSize: "24px", color: "rgba(11, 137, 92, 1)" }}
            ></i>
            <span className="positive mt-1"> Positive</span>
          </div>
          <ol
            style={{ maxHeight: "110px", overflowY: "auto" }}
            dir={
              obj.audioLanguage === "Arabic - Saudi Arabia"
                ? !showTranslation
                  ? "rtl"
                  : ""
                : ""
            }
          >
            {obj.moderatorSummary?.positivePoints.length > 0 ? (
              <>
                {showTranslation ? (
                  obj?.translation?.moderatorSummary?.positivePoints.length >
                  0 ? (
                    obj?.translation?.moderatorSummary?.positivePoints.map(
                      (el, index) => {
                        return <li key={index}>{el}</li>;
                      }
                    )
                  ) : (
                    <p> No Translation Available</p>
                  )
                ) : (
                  obj.moderatorSummary &&
                  obj.moderatorSummary?.positivePoints.map((el, index) => {
                    return <li key={index}>{el}</li>;
                  })
                )}
              </>
            ) : (
              <p className="text-muted"> No positive points</p>
            )}
          </ol>
        </div>
        <div className="col-6">
          <div className="d-flex gap-2">
            <i
              className="ri-user-smile-line"
              style={{ fontSize: "24px", color: "rgba(255, 136, 102, 1)" }}
            ></i>
            <span className="negative mt-1">Negative</span>
          </div>
          <ol
            style={{ maxHeight: "110px", overflowY: "auto" }}
            dir={
              obj.audioLanguage === "Arabic - Saudi Arabia"
                ? !showTranslation
                  ? "rtl"
                  : "ltr"
                : "ltr"
            }
          >
            {/* {obj.moderatorSummary?.negativePoints.length > 0 ? (
              <>
                {obj.moderatorSummary &&
                  obj.moderatorSummary?.negativePoints.map((el, index) => {
                    return <li key={index}>{el}</li>;
                  })}
              </>
            ) : (
              <p className="text-muted"> No negative points</p>
            )} */}
            {obj.moderatorSummary?.negativePoints.length > 0 ? (
              <>
                {showTranslation ? (
                  obj?.translation?.moderatorSummary?.negativePoints.length >
                  0 ? (
                    obj?.translation?.moderatorSummary?.negativePoints.map(
                      (el, index) => {
                        return <li key={index}>{el}</li>;
                      }
                    )
                  ) : (
                    <p>No Translation Available</p>
                  )
                ) : (
                  obj.moderatorSummary &&
                  obj.moderatorSummary?.negativePoints.map((el, index) => {
                    return <li key={index}>{el}</li>;
                  })
                )}
              </>
            ) : (
              <p className="text-muted"> No Negative points</p>
            )}
          </ol>
        </div>
        <span className="score">Sentiment Score</span>
        <div className="d-flex gap-2">
          <i
            className="ri-user-smile-line"
            style={{ fontSize: "24px", color: "rgba(255, 136, 102, 1)" }}
          ></i>
          {/* <div className="slider-container mt-1">
                        <input
                            type="range"
                            ref={sliderRef}
                            min="0"
                            max="100"
                            defaultValue={obj.sentimentalScore}
                            value={obj.sentimentalScore}
                            // onChange={handleChange}
                            className="slider"
                        />
                        <div className="thumb-label" style={{ left: `${obj.sentimentalScore}%` }}>
                            {obj.sentimentalScore}
                        </div> */}
          {/* </div> */}
          {/* <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        renderThumb={(props, state) => <div {...props}>{`${obj.sentimentalScore}%`}</div>}
                    /> */}

          <div style={{ marginTop: "12px", width: "50%" }}>
            <ReactSlider
              ariaLabelledby="slider-label"
              className="horizontal-slider"
              thumbClassName={`example-thumb ${
                obj.sentimentalScore > 70
                  ? "thumb-high-score"
                  : obj.sentimentalScore > 40
                  ? "thumb-medium-score"
                  : "thumb-low-score"
              }`}
              trackClassName="example-track"
              value={obj.sentimentalScore}
              renderTrack={(props, state) => (
                <div
                  {...props}
                  className={
                    state.index === 0
                      ? obj.sentimentalScore > 70
                        ? "track-high-score"
                        : obj.sentimentalScore > 40
                        ? "track-medium-score"
                        : "track-low-score"
                      : "track-empty"
                  }
                />
              )}
              disabled
              renderThumb={(props, state) => (
                <div {...props}>{`${
                  obj.sentimentalScore === null ? "0" : obj.sentimentalScore
                }`}</div>
              )} // Renders the thumb and shows the current value
            />
          </div>

          <i
            className="ri-user-smile-line"
            style={{ fontSize: "24px", color: "rgba(11, 137, 92, 1)" }}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default Sentiment;
