import React, { useEffect, useState } from 'react';
import {
  Modal,
  Container,
  Card,
  Form,
  Button,
  InputGroup,
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy, FaCheck } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const PDpIntegrateModal = ({ show, handleClose, setCurrentStep }) => {
  const [copied, setCopied] = useState({
    footer: false,
    body: false,
    pdp: false,
  });

  const user = useSelector((state) => state.auth.user);
  const handleCopy = (field) => {
    setCopied({ ...copied, [field]: true });
    // setTimeout(() => {
    //     setCopied({ ...copied, [field]: false });
    // }, 2000); // Reset after 2 seconds
  };

  const codeSnippets = {
    footer:
      '<script src="https://apigoswirl.com/short_video/v13/short-video.min.js"></script>',

    pdp: `<div class="swirl-short-videos-c-ssv13" data-code="${user.short_code}" data-playlist="" data-pdp="product/,products/,collection/" data-wt="${user.platform}"></div>`,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied({
        footer: false,
        body: false,
        pdp: false,
      });
    }, 2000);

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts or copied state changes
  }, [copied]);

  return (
    <Modal
      show={show}
      className="d-grid modal_video "
      size="lg"
      onHide={() => {
        handleClose();
        setCurrentStep(1);
      }}
    >
      <Modal.Body>
        <Container>
          <div className="p-4">
            <Card.Header>
              {' '}
              <Card.Title className="mb-2">Embed Code</Card.Title>
            </Card.Header>{' '}
            <hr />
            <Card.Body>
              <Card.Text>Step-1: Add the below script in the footer</Card.Text>
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={1}
                  readOnly
                  cols={120}
                  value={codeSnippets.footer}
                  className="coly_field"
                />
                <CopyToClipboard
                  text={codeSnippets.footer}
                  onCopy={() => handleCopy('footer')}
                >
                  <Button variant="outline-secondary">
                    {copied.footer ? <FaCheck /> : <FaRegCopy />}
                  </Button>
                </CopyToClipboard>
              </InputGroup>

              <Card.Text className="mt-2">
                Step-2: Add the below code in the Product Details Page(PDP)
              </Card.Text>
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={1}
                  readOnly
                  value={codeSnippets.pdp}
                  className="coly_field"
                />
                <CopyToClipboard
                  text={codeSnippets.pdp}
                  onCopy={() => handleCopy('pdp')}
                >
                  <Button variant="outline-secondary">
                    {copied.pdp ? <FaCheck /> : <FaRegCopy />}
                  </Button>
                </CopyToClipboard>
              </InputGroup>

              <Button
                variant="dark"
                className="mt-3"
                onClick={() => {
                  handleClose();
                  setCurrentStep(1);
                }}
              >
                Done
              </Button>
            </Card.Body>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PDpIntegrateModal;
