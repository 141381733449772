export const Casual = (templateNumber, { user, selectedOption }) => {
  switch (templateNumber) {
    case 1:
      return `
              Subject: Hey, ${selectedOption?.label} Fan! Share Your Thoughts in a Quick Video
              <br/><br/>
              Hey customer,
              <br/><br/>
              Hope that <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a> of yours is treating you right! We’re all about creating epic experiences, and guess what? We want to hear from you.
              <br/><br/>
              Here’s the deal:
              <br/><br/>
              Grab Your Phone: Whip out your smartphone (or dust off that webcam) and record a mini video review. Tell us what floats your boat about your <a href="${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}">${selectedOption?.label}</a>.
              <br/><br/>
              Send It Our Way: Hop over to our Video UGC Submission Page. Upload your masterpiece—it won’t take more than a coffee break’s worth of time.
              <br/><br/>
              Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Thanks for being part of the fam!
              <br/><br/>
              [Your First Name] ${user?.brand_name} Enthusiast
            `;
    case 2:
      return `
              Subject: Share Your ${selectedOption?.label} Experience: Video UGC Request
              <br/><br/>
              Hi Customer ,
              <br/><br/>
              Hope you’re enjoying your ${selectedOption?.label}! We’d love to hear your thoughts. Could you spare a few minutes to create a short video review? It’s straightforward—just share what you love most about your ${selectedOption?.label}.
              <br/><br/>
              Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              As a token of our appreciation, we want to extend our heartfelt thanks. Your feedback matters to us!
              <br/><br/>
              Thanks for being part of our family. Looking forward to your review!
              <br/><br/>
              Best regards,
              <br/><br/>
              [Your Name]
              <br/>
              ${user?.brand_name} Enthusiast
            `;
    case 3:
      return `
              Subject: Share Your ${selectedOption?.label} Love!
              <br/><br/>
              Hey Customer ,
              <br/><br/>
              Hope you're loving your ${selectedOption?.label}! We want to hear all about it! Your thoughts will help us make even better products for you and everyone else.
              <br/><br/>
              Can you record a quick video (just 1-2 minutes) sharing your honest opinion? Tell us what you like, what you don't, and any features that are totally winning.
              <br/><br/>
              Submit your review here: ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Thanks so much for your time! Your feedback means a lot to us.
              <br/><br/>
              Cheers,
              <br/><br/>
              [Your Name]
              <br/>
              The ${user?.brand_name} Team
            `;
    case 4:
      return `
              Subject: We Want to Hear from You!
              <br/><br/>
              Hey Customer ,
              <br/><br/>
              So, what do you really think of your ${selectedOption?.label}? We're all ears (or in this case, all eyes)!
              <br/><br/>
              Record a quick video review (just 1-2 minutes) and share your thoughts. The good, the bad, and the awesome features that make your experience epic.
              <br/><br/>
              Here's how to make it easy peasy:
              <br/><br/>
              - Keep it short and snappy
              <br/>
              - Be honest (we can take it)
              <br/><br/>
              Submit your review here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Thanks for taking the time to share your thoughts!
              <br/><br/>
              Cheers,
              <br/><br/>
              [Your Name]
              <br/>
              ${user?.brand_name}
            `;
    case 5:
      return `
              Subject: Hey, Let's Talk About Your ${selectedOption?.label}!
              <br/><br/>
              Hey Customer ,
              <br/><br/>
              Hope your ${selectedOption?.label} is rockin' your world!
              <br/><br/>
              We'd love to hear what you think. Could you take a quick minute to share a video review of your ${selectedOption?.label}? It'll help us make things even better : ${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Thanks a bunch,
              <br/><br/>
              The ${user?.brand_name} Team
            `;
  }
};
