import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReviewCard from "./ReviewCard";
import { Card, Tab, Tabs } from "react-bootstrap";
import { Casualsms } from "./SmsTemplate/Casualsms";
import { Professionalsms } from "./SmsTemplate/Professionalsms";
import { Whimsicalsms } from "./SmsTemplate/Whimsicalsms";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const SmsModal = ({ smsshow, handleClosesms, selectedProduct }) => {
  const modalRef = useRef(null);
  const [moderatingObj, setModeratingObj] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [videos, setVideos] = useState([]);
  const [activeTab, setActiveTab] = useState("professional");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [previousIndex, setPreviousIndex] = useState(null);
  const textRef = useRef(null);
  const selectedOption = selectedProduct;
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (smsshow) {
        modalElement.classList.add("slide-down");
        modalElement.classList.remove("slide-up");
      } else {
        modalElement.classList.add("slide-up");
        modalElement.classList.remove("slide-down");
      }
    }
  }, [smsshow, handleClosesms]);

  const handleCloseed = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.classList.add("slide-up");

      setTimeout(() => {
        setIsClosing(false);
        handleClosesms();
      }, 500);
    }
  };

  const handleCopyClick2 = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Sms copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy text: ", err);
        });
    }
  };
  const templateNumbersms = [1, 2, 3, 4, 5, 6];
  const templateNumbersms2 = [1, 2, 3, 4, 5, 6, 7];

  const selectRandomTemplate2 = () => {
    let randomIndex;
    if (activeTab === "professional") {
      do {
        randomIndex = Math.floor(Math.random() * templateNumbersms.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumbersms[randomIndex];
      const templateContent = Professionalsms(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    } else if (activeTab === "casual") {
      do {
        randomIndex = Math.floor(Math.random() * templateNumbersms.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumbersms[randomIndex];
      const templateContent = Casualsms(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    } else {
      do {
        randomIndex = Math.floor(Math.random() * templateNumbersms2.length);
      } while (randomIndex === previousIndex);

      const selectedTemplateNumber = templateNumbersms2[randomIndex];
      const templateContent = Whimsicalsms(selectedTemplateNumber, {
        user,
        selectedOption,
      });

      setSelectedTemplate(templateContent);
      setPreviousIndex(randomIndex); // Store the selected index
    }
  };

  useEffect(() => {
    selectRandomTemplate2();
  }, [selectedOption, activeTab]);

  if (!smsshow && !isClosing) return null;

  return (
    <div className="review-modal-overlay">
      <div
        className="review-modal-content"
        ref={modalRef}
        style={{ height: "55vh" }}
      >
        <button
          className="review-modal-close"
          onClick={handleCloseed}
          style={{ top: "-10%" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 44 44"
            fill="none"
          >
            <rect width="44" height="44" rx="22" fill="white" />
            <g clipPath="url(#clip0_483_11622)">
              <path
                d="M22 20.586L26.95 15.636L28.364 17.05L23.414 22L28.364 26.95L26.95 28.364L22 23.414L17.05 28.364L15.636 26.95L20.586 22L15.636 17.05L17.05 15.636L22 20.586Z"
                fill="#454549"
              />
            </g>
            <defs>
              <clipPath id="clip0_483_11622">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div className="row" style={{ overflow: "auto", height: "100%" }}>
          <h2>SMS Tempaltes</h2>
          <Tabs
            id="email-modal-tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
          >
            <Tab eventKey="professional" title="Professional">
              <Card
                className="mt-3 p-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "33vh",
                  overflow: "auto",
                }}
              >
                <Card.Body style={{ flex: 1 }}>
                  <div
                    id="text-to-copy"
                    ref={textRef}
                    style={{
                      fontSize: "16px",
                      color: "rgba(102, 112, 133, 1)",
                      marginTop: "20px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedTemplate ||
                        "Click the button to generate an email template.",
                    }}
                  />
                </Card.Body>
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <i
                    className="ri-file-copy-line"
                    onClick={handleCopyClick2}
                    style={{
                      fontSize: "15px",
                      backgroundColor: "rgba(232, 232, 232, 1)",
                      cursor: "pointer",
                      borderRadius: "50%",
                      padding: "10px 14px",
                    }}
                  />
                  <i
                    className="ri-refresh-line"
                    onClick={selectRandomTemplate2}
                    style={{
                      fontSize: "20px",
                      backgroundColor: "rgba(232, 232, 232, 1)",
                      cursor: "pointer",
                      borderRadius: "50%",
                      padding: "5px 9px",
                    }}
                  />
                </div>
              </Card>
            </Tab>
            <Tab eventKey="casual" title="Casual">
              <Card
                className="mt-3 p-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "33vh",
                  overflow: "auto",
                }}
              >
                <Card.Body style={{ flex: 1 }}>
                  <div
                    id="text-to-copy"
                    ref={textRef}
                    style={{
                      fontSize: "16px",
                      color: "rgba(102, 112, 133, 1)",
                      marginTop: "20px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedTemplate ||
                        "Click the button to generate an email template.",
                    }}
                  />
                </Card.Body>
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <i
                    className="ri-file-copy-line"
                    onClick={handleCopyClick2}
                    style={{
                      fontSize: "15px",
                      backgroundColor: "rgba(232, 232, 232, 1)",
                      cursor: "pointer",
                      borderRadius: "50%",
                      padding: "10px 14px",
                    }}
                  />
                  <i
                    className="ri-refresh-line"
                    onClick={selectRandomTemplate2}
                    style={{
                      fontSize: "20px",
                      backgroundColor: "rgba(232, 232, 232, 1)",
                      cursor: "pointer",
                      borderRadius: "50%",
                      padding: "5px 9px",
                    }}
                  />
                </div>
              </Card>
            </Tab>
            <Tab eventKey="whimsical" title="Whimsical">
              <Card
                className="mt-3 p-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "33vh",
                  overflow: "auto",
                }}
              >
                <Card.Body style={{ flex: 1 }}>
                  <div
                    id="text-to-copy"
                    ref={textRef}
                    style={{
                      fontSize: "16px",
                      color: "rgba(102, 112, 133, 1)",
                      marginTop: "20px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedTemplate ||
                        "Click the button to generate an email template.",
                    }}
                  />
                </Card.Body>
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <i
                    className="ri-file-copy-line"
                    onClick={handleCopyClick2}
                    style={{
                      fontSize: "15px",
                      backgroundColor: "rgba(232, 232, 232, 1)",
                      cursor: "pointer",
                      borderRadius: "50%",
                      padding: "10px 14px",
                    }}
                  />
                  <i
                    className="ri-refresh-line"
                    onClick={selectRandomTemplate2}
                    style={{
                      fontSize: "20px",
                      backgroundColor: "rgba(232, 232, 232, 1)",
                      cursor: "pointer",
                      borderRadius: "50%",
                      padding: "5px 9px",
                    }}
                  />
                </div>
              </Card>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default SmsModal;
