import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import './playlistsetting.css';
import { FaPause, FaPlay } from 'react-icons/fa';
const Productdetailselected = ({
  selectedCards,
  setSelectedCards,
  setFilteredVideos,
  filteredVideos,
  filterOrientation,
  playinVideoId,
  setPlayinVideoId,
  setLoading,
}) => {
  const [draggedVideo, setDraggedVideo] = useState(null);
  const [play, setPlay] = useState(false);

  const handleDragStart = (index) => {
    setDraggedVideo(index);
  };

  const handleDragEnter = (index) => {
    if (draggedVideo !== null && Array.isArray(filteredVideos)) {
      const updatedVideos = [...filteredVideos];
      const draggedItem = updatedVideos.splice(draggedVideo, 1)[0];
      updatedVideos.splice(index, 0, draggedItem);
      setFilteredVideos(updatedVideos);
      setDraggedVideo(index);
    }
  };
  const handlePlayPauseClick = (videoId) => {
    setPlayinVideoId(videoId);
    setPlay(!play);
  };

  const handleDeleteVideo = (index) => {
    if (Array.isArray(filteredVideos)) {
      const updatedVideos = [...filteredVideos];
      const deletedVideo = updatedVideos[index];
      const updatedSelectedCards = selectedCards.filter(
        (id) => id !== deletedVideo.video_id
      );

      updatedVideos.splice(index, 1);
      setFilteredVideos(updatedVideos);
      setSelectedCards(updatedSelectedCards);
    }
  };
  return (
    <div>
      <h5
        className="ps-0 p-2 "
        style={{
          fontWeight: '600px',
          fontSize: '18px',
          color: 'rgba(23, 23, 28, 1)',
        }}
      >
        Change the sequence of videos placement by dragging and dropping
        positions.
      </h5>
      <div
        className="d-flex"
        style={{
          borderRadius: '10px',
          flexWrap: 'wrap',
          overflow: 'auto',
          height: '47vh',
        }}
      >
        {filteredVideos?.map((video, index) => (
          <div
            className={filterOrientation == 1 ? 'col-2' : 'col-3'}
            key={video.video_id}
            style={{
              position: 'relative',
              borders: '5px 5px 0 5px',
              borderRadius: '10px',
              padding: '10px',
            }}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragEnter={() => handleDragEnter(index)}
            onDragOver={(e) => e.preventDefault()}
          >
            <div
              style={{
                boxShadow: '0.3px 0.3px 2px 1px #E8E8E8',
                borderRadius: '10px',
              }}
            >
              {/* <video
                onClick={(e) => handlePlayPause(index, e.target)}
                style={{ display: 'block', borderRadius: '10px 10px 0 0', objectFit: 'cover', height: '100%', width: '100%' }}
              >
                <source src={video.server_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
              {play && playinVideoId === video.video_id ? (
                <video
                  autoPlay
                  style={{
                    display: 'block',
                    borderRadius: '10px 10px 0 0',
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <source src={video.server_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={video.cover_image}
                  alt="Cover"
                  style={{
                    display: 'block',
                    borderRadius: '10px 10px 0 0',
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                />
              )}
              <div
                className="title_video_select"
                style={{
                  paddingLeft: '10px',
                  paddingTop: '8px',
                  paddingBottom: '2px',
                }}
              >
                {video.video_title}
              </div>

              <div
                onClick={() => handlePlayPauseClick(video.video_id)}
                className="pointer_cursor"
                style={{
                  position: 'absolute',
                  top: '45%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    height: '34px',
                    width: '34px',
                    display: 'grid',
                    placeItems: 'center',
                    borderRadius: '50%',
                  }}
                >
                  {play && playinVideoId === video.video_id ? (
                    <FaPause
                      size={15}
                      className="ps-1"
                      style={{ marginLeft: '-4px' }}
                      color="#09121F"
                    />
                  ) : (
                    <FaPlay
                      size={15}
                      className="ps-1 pointer_cursor"
                      color="#09121F"
                    />
                  )}
                </div>
              </div>

              <button
                style={{
                  position: 'absolute',
                  top: '5%',
                  right: '10%',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'red',
                  fontSize: '14px',
                  backgroundColor: 'white',
                  padding: '8px 8px',
                  borderRadius: '18px',
                  display: 'flex',
                }}
                onClick={() => handleDeleteVideo(index)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Productdetailselected;
