import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { pdpApiInstance } from "../../axiosInstances";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const ReviewVideosModal = ({
  setReviewVideos,
  reviewVideos,
  show,
  handleClose,
  id,
  fetchNotReviewedVideosCOunr,
  fetchreviewdata
}) => {
  const modalRef = useRef(null);
  const [moderatingObj, setModeratingObj] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [videos, setVideos] = useState([]);
  const [draggedVideo, setDraggedVideo] = useState(null);
  const [playinVideoId, setPlayinVideoId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDragStart = (index) => {
    setDraggedVideo(index);
  };

  const handleDragEnter = (index) => {
    if (draggedVideo !== null && Array.isArray(videos)) {
      const updatedVideos = [...videos];
      const draggedItem = updatedVideos.splice(draggedVideo, 1)[0];
      updatedVideos.splice(index, 0, draggedItem);
      setVideos(updatedVideos);
      setDraggedVideo(index);
    }
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (show) {
        modalElement.classList.add("slide-down");
        modalElement.classList.remove("slide-up");
      } else {
        modalElement.classList.add("slide-up");
        modalElement.classList.remove("slide-down");
      }
    }
  }, [show, handleClose]);

  const handleCloseed = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.classList.add("slide-up");
      setTimeout(() => {
        setIsClosing(false);
        handleClose();
      }, 500);
    }
  };

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const getData = async () => {
    try {
      await pdpApiInstance
        .get(`/api/moderator/videos?page=1&pageSize=1000&productIds=${id}&status=published&order=true`, {
          headers: {
            Authorization: localStorage.getItem("token")
              ? localStorage.getItem("token")
              : "",
          },
        })
        .then((res) => {
          if (res.data) {
            const publishedVideos = res.data.data.records.filter(
              (video) => video.status === "published"
            );
            setVideos([...publishedVideos]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (show) {
      getData();
    }
  }, [show, id]);

  const handleVideoClick = (videoId) => {
    if (playinVideoId === videoId) {
      setPlayinVideoId(null); // Pause the video
    } else {
      setPlayinVideoId(videoId); // Play the video
    }
  };

  const handleUpdateOrder = async () => {
    try {
      const data = videos.map((el, index) => {
        return { id: el.id, order: index + 1 };
      });

      await pdpApiInstance
        .put("/api/reviews/video/order", data, {
          headers: {
            Authorization: localStorage.getItem("token")
              ? localStorage.getItem("token")
              : "",
          },
        })
        .then((res) => {
          if (res.data) {
            toast.success("Order updated successfully!");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleReviewDelete = async (id) => {
    try {
      confirmAlert({
        message: "Are you sure you want to delete this review?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              await pdpApiInstance
                .put(
                  `/api/moderator/video/${id}`,
                  {
                    isDeleted: true,
                  },
                  {
                    headers: {
                      Authorization: localStorage.getItem("token")
                        ? localStorage.getItem("token")
                        : "",
                    },
                  }
                )
                .then((res) => {
                  if (res.data) {
                    toast.success("Review deleted successfully");
                    const elm = document.getElementById(`videocard_${id}`);
                    if (elm) {
                      elm.remove();
                      fetchreviewdata()
                      fetchNotReviewedVideosCOunr();
                      if (videos.length === 1) {
                        handleCloseed()
                      }
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                  toast.error("Something went wrong!");
                });
            },
          },
          { label: "No", onClick: () => { } },
        ],
      });
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  const cdnUrl = process.env.REACT_APP_S3_CDN

  if (!show && !isClosing) return null;

  return (
    <div className="review-modal-overlay">
      <div
        className="review-modal-content"
        ref={modalRef}
        style={{ height: "85vh" }}
      >
        <button className="review-modal-close" onClick={handleCloseed}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 44 44"
            fill="none"
          >
            <rect width="44" height="44" rx="22" fill="white" />
            <g clipPath="url(#clip0_483_11622)">
              <path
                d="M22 20.586L26.95 15.636L28.364 17.05L23.414 22L28.364 26.95L26.95 28.364L22 23.414L17.05 28.364L15.636 26.95L20.586 22L15.636 17.05L17.05 15.636L22 20.586Z"
                fill="#454549"
              />
            </g>
            <defs>
              <clipPath id="clip0_483_11622">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>

        {videos.length > 0 ? <>
          <h5 className="ps-2 mt-3 mb-1" style={{ fontWeight: "600px", fontSize: "18px" }}>
            Change the sequence of videos placement by dragging and dropping positions.
          </h5>
          <div className="row" style={{ flexWrap: "wrap", height: "calc(100% - 65px)", overflow: "auto" }}>
            {videos.map((video, index) => (
              <div
                key={video.id}
                style={{
                  position: "relative",
                  padding: "2px",
                  borderRadius: "10px",
                }}
                id={`videocard_${video.id}`}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragEnter={() => handleDragEnter(index)}
                onDragOver={(e) => e.preventDefault()}
                className="col-2"
              >
                <div className="video-card" style={{ position: "relative", borderRadius: "10px", border: "1px solid rgba(232, 232, 232, 1)" }}>
                  {loading ? (
                    <img
                      className="w-100"
                      style={{ borderRadius: "10px 10px 0 0" }}
                      src="https://cdn-product-images.goswirl.live/default-video-thumbnail.webp"
                      alt="cover"
                    />
                  ) : (
                    <video
                      className="w-100"
                      style={{ objectFit: "cover", borderRadius: "10px 10px 0px 0px" }}
                      poster={video.coverImage ? `${cdnUrl}${video.coverImage}` : "https://cdn-product-images.goswirl.live/default-video-thumbnail.webp"}
                      ref={(ref) => {
                        if (ref) {
                          if (playinVideoId === video.id) {
                            ref.play();
                          } else {
                            ref.pause();
                          }
                        }
                      }}
                      muted
                    >
                      <source
                        src={video.assetKey ? `${cdnUrl}${video.assetKey}` : video.assetKey}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}

                  <button
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "red",
                      fontSize: "18px",
                      backgroundColor: "white",
                      padding: "2px 6px",
                      borderRadius: "50%",
                      zIndex: 1,
                    }}
                    onClick={() => handleReviewDelete(video.id)}
                  >
                    <RiDeleteBinLine />
                  </button>

                  <div
                    onClick={() => handleVideoClick(video.id)}
                    className="pointer_cursor"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                      color: "white",
                      display: loading ? "none" : "block",
                      zIndex: 1,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        height: "50px",
                        width: "50px",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {playinVideoId === video.id ? <FaPause size={20} color="#09121F" /> : <FaPlay size={20} color="#09121F" />}
                    </div>
                  </div>

                  <div className="badge position-absolute end-0 mb-4 me-1" style={{ bottom: "40px", backgroundColor: "rgba(23, 23, 28, 0.7)" }}>
                    {formatDuration(video.duration)}
                  </div>

                  <span
                    className="ps-1 pe-1"
                    style={{
                      fontSize: "16px",
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginBottom: "0px",
                    }}
                  >
                    {video.title ? video.title : "Untitled"}
                  </span>

                  <span className="p-1" style={{ fontSize: "14px", color: "rgba(139, 139, 142, 1)" }}>
                    Submitted on {video?.updatedAt?.split("T")[0]}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <button onClick={handleUpdateOrder} className="btn btn-dark">
            Save Order
          </button></> : <h3 className="text-muted mt-5 text-center">No videos</h3>}
      </div>
    </div>
  );
};

export default ReviewVideosModal;
