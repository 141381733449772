import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./Profile.css";
import countryCodes from "../../utils/countryCodes";
import Currency from "../../utils/Currency";
import { CiEdit } from "react-icons/ci";
import { FiEye, FiEyeOff } from "react-icons/fi";
import axios from "axios";
import { toast } from "react-toastify";
import { allApiInstance } from "../../axiosInstances";
const Companyinfo = ({
  handleDeleteLogo2,
  handleSubmitupdated,
  togglechange,
  loading,
  state,
  setState,
  switches,
  setSwitches,
}) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLogoDeleted, setIsLogoDeleted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const Error = newPassword !== confirmPassword;
  const [errors, setErrors] = useState({});

  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);

  // useEffect(() => {
  //   const newErrors = { newPassword: {}, confirmPassword: {} };
  //   let valid = true;

  //   // Validate newPassword
  //   if (newPassword.length < 6) {
  //     newErrors.newPassword.passwordLength =
  //       'Password must be at least 6 characters long';
  //     valid = false;
  //   } else {
  //     if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
  //       newErrors.newPassword.passwordSpecial =
  //         'Password must contain at least one special character';
  //       valid = false;
  //     } else if (!/[0-9]/.test(newPassword)) {
  //       newErrors.newPassword.passwordNumber =
  //         'Password must contain at least one number';
  //       valid = false;
  //     } else if (!/[a-z]/.test(newPassword)) {
  //       newErrors.newPassword.passwordLowercase =
  //         'Password must contain at least one lowercase letter';
  //       valid = false;
  //     }
  //   }

  //   // Set validity state based on newPassword validation
  //   setIsNewPasswordValid(valid);

  //   // Validate confirmPassword
  //   if (confirmPassword && newPassword !== confirmPassword) {
  //     newErrors.confirmPassword.passwordMatch = 'Passwords do not match';
  //   }

  //   setErrors(newErrors);
  // }, [newPassword, confirmPassword]);
  useEffect(() => {
    const newErrors = { newPassword: {}, confirmPassword: {} };
    let valid = true;
    if (newPassword) {
      if (newPassword.length < 6) {
        newErrors.newPassword.passwordLength =
          "Password must be at least 6 characters long";
        valid = false;
      } else {
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
          newErrors.newPassword.passwordSpecial =
            "Password must contain at least one special character";
          valid = false;
        } else if (!/[0-9]/.test(newPassword)) {
          newErrors.newPassword.passwordNumber =
            "Password must contain at least one number";
          valid = false;
        } else if (!/[a-z]/.test(newPassword)) {
          newErrors.newPassword.passwordLowercase =
            "Password must contain at least one lowercase letter";
          valid = false;
        }
      }
      if (confirmPassword && newPassword !== confirmPassword) {
        newErrors.confirmPassword.passwordMatch = "Passwords do not match";
      }
    }

    // Set validity state based on newPassword validation
    setIsNewPasswordValid(valid);
    setErrors(newErrors);
  }, [newPassword, confirmPassword]);

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (isLogoDeleted) {
      const fileInput = document.getElementById("logoInput");
      fileInput.value = "";
    }
  }, [isLogoDeleted]);

  const handleToggle = (key) => {
    setSwitches((prevSettings) => ({
      ...prevSettings,
      [key]: !prevSettings[key],
    }));
    togglechange({ option: !switches.option });
  };
  // const validatePassword = (password) => {
  //     const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  //     return strongPasswordRegex.test(password);
  // };
  // const validatePassword = (password) => {
  //     const errors = [];

  //     if (!/(?=.*[a-z])/.test(password)) {
  //         errors.push('Password must contain at least one lowercase letter.');
  //     }
  //     if (newPassword !== confirmPassword) {
  //         errors.push('password doesnot match')
  //     }
  //     if (!/(?=.*\d)/.test(password)) {
  //         errors.push('Password must contain at least one number.');
  //     }
  //     if (!/(?=.*[@$!%*?&#])/.test(password)) {
  //         errors.push('Password must contain at least one special character.');
  //     }
  //     if (password.length < 8) {
  //         errors.push('Password must be at least 8 characters long.');
  //     }

  //     return errors;
  // };

  // useEffect(() => {
  //     togglechange(switches);
  // }, [switches]);

  const handleLogoChange = (e) => {
    const selectedLogo = e.target.files[0];
    const reader = new FileReader();
    if (selectedLogo) {
      reader.onload = (event) => {
        setState((prevState) => ({ ...prevState, logo: event.target.result }));
        setState((prevState) => ({ ...prevState, imageFile: selectedLogo }));
        setIsLogoDeleted(false);
      };
      reader.readAsDataURL(selectedLogo);
    }
  };

  const handleDeleteLogo = () => {
    handleDeleteLogo2();
    setIsLogoDeleted(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevInput) => {
      const updatedInp = {
        ...prevInput,
        [name]: value,
      };
      return updatedInp;
    });
  };
  const customStyles = {
    width: "150px",
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const formdata = new FormData()
      // formdata.append("oldPassword", oldPassword)
      // formdata.append("newPassword", newPassword)
      // formdata.append("brandId", user.brand_id)
      await allApiInstance
        .patch("/validateAndUpdatePasswordByBrandId", {
          oldPassword: oldPassword,
          newPassword: newPassword,
          brandId: user.brand_id,
        })
        .then((res) => {
          if (res.data.message) {
            if (res.data.message == "Invalid current password.") {
              toast.error("Invalid current password.");
            } else if (res.data.message == "Password updated successfully.") {
              toast.success("Password updated successfully.");
              setOldPassword("");
              setNewPassword("");
              setConfirmPassword("");
              setEditMode(false);
            } else {
              toast.error("Something went wrong!");
            }
          }
        });
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container-fluid  ">
      <div className="d-flex">
        <form
          className="col-7"
          onSubmit={(e) => {
            handleSubmitupdated(e);
          }}
        >
          <div className="card col-12 me-1">
            <div className="fs-18 ps-3 mt-3">Basic information</div>
            <div className="fs-14 ps-3 pt-3">Profile Logo</div>
            <div className="d-flex float-end text-capitalize align-items-center">
              <input
                type="file"
                accept="image/*"
                id="logoInput"
                style={{ display: "none" }}
                onChange={handleLogoChange}
              />
              {loading ? (
                <div>
                  <p className="mt-4">Uploading</p>
                </div>
              ) : (
                <label htmlFor="logoInput">
                  <img
                    src={
                      isLogoDeleted
                        ? "https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png"
                        : `${state.logo}?v=${new Date().getTime()}`
                    }
                    alt="profile logo"
                    value={state.logo}
                    width={80}
                    height={80}
                    className="rounded-circle m-2 border image_profile_user"
                    style={{ cursor: "pointer" }}
                  />
                </label>
              )}
              <button
                className="btn btn-dark ms-2"
                type="button"
                onClick={() => document.getElementById("logoInput").click()}
              >
                Upload Logo
              </button>
              <button
                className="btn btn-light ms-2"
                type="button"
                style={{ border: "1px solid red", color: "red" }}
                onClick={handleDeleteLogo}
              >
                Delete
              </button>
            </div>
            <div className="col-12 p-3">
              <div className="row" style={{ overflow: "auto", height: "35vh" }}>
                <div className="form-group mt-2">
                  <label>
                    Company Name<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Enter company name"
                    name="companyname"
                    value={state.companyname}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group mt-2">
                  <label>
                    Email Address<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="email"
                    className="form-control mt-2"
                    placeholder="Enter email address"
                    name="email"
                    value={state.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group mt-2">
                  <label>
                    Phone Number<sup className="text-danger">*</sup>
                  </label>
                  <div className="input-group ">
                    <div className="input-group-append">
                      <select
                        className="form-select mt-2"
                        value={state.countryCodes}
                        name="countryCodes"
                        onChange={handleInputChange}
                        style={customStyles}
                        required
                      >
                        <option value="">Country Code</option>
                        {countryCodes.map((country) => (
                          <option key={country.code} value={country.code}>
                            {`${country.country} (${country.phoneCode})`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Enter phone number"
                      name="phoneNumber"
                      value={state.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-2">
                  <label>Website</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Enter website URL"
                    name="website"
                    value={state.website}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group mt-2">
                  <label>Select Platform</label>
                  <div className="mt-2 d-flex" style={{ flexWrap: "wrap" }}>
                    <div className="form-check me-2 selected-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="platform"
                        id="platform1"
                        value="Platform 1"
                        checked={state.platform === "Platform 1"}
                        onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label mt-1"
                        htmlFor="platform1"
                        style={{
                          opacity: state.platform === "Platform 1" ? 1 : 0.7,
                        }}
                      >
                        Shopify
                      </label>
                    </div>
                    <div className="form-check me-2 selected-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="platform"
                        id="platform2"
                        value="Platform 2"
                        checked={state.platform === "Platform 2"}
                        onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label mt-1"
                        htmlFor="platform2"
                        style={{
                          opacity: state.platform === "Platform 2" ? 1 : 0.7,
                        }}
                      >
                        WooCommerce
                      </label>
                    </div>
                    <div className="form-check me-2 selected-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="platform"
                        id="platform3"
                        value="Platform 3"
                        checked={state.platform === "Platform 3"}
                        onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label mt-1"
                        htmlFor="platform3"
                        style={{
                          opacity: state.platform === "Platform 3" ? 1 : 0.7,
                        }}
                      >
                        Magento
                      </label>
                    </div>
                    <div className="form-check me-2 selected-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="platform"
                        id="platform4"
                        value="Platform 4"
                        checked={state.platform === "Platform 4"}
                        onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label mt-1"
                        htmlFor="platform4"
                        style={{
                          opacity: state.platform === "Platform 4" ? 1 : 0.7,
                        }}
                      >
                        Reactjs
                      </label>
                    </div>
                    <div className="form-check me-2 selected-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="platform"
                        id="platform5"
                        value="Platform 5"
                        checked={state.platform === "Platform 5"}
                        onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label mt-1"
                        htmlFor="platform5"
                        style={{
                          opacity: state.platform === "Platform 5" ? 1 : 0.7,
                        }}
                      >
                        Other
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group mt-2">
                  <label>Product UTM</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Enter product URL"
                    name="productUrl"
                    value={state.productUrl}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group mt-2">
                  <label>Currency</label>
                  <select
                    className="form-select mt-2"
                    value={state.currency}
                    onChange={handleInputChange}
                    name="currency"
                  >
                    <option value="">Select Currency</option>
                    {Currency.map((currency) => (
                      <option
                        key={currency.currencyId}
                        value={currency.currencyId}
                      >
                        {`${currency.CurrencyName} (${new Intl.NumberFormat(undefined, {
                          style: "currency",
                          currency: currency.CurrencyName,
                        })
                            .formatToParts(0)
                            .find((part) => part.type === "currency").value
                          })`}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-2">
                  <label>Store Url</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Enter product URL"
                    name="store url"
                    value={state.StoreUrl}
                    style={{ color: "#A2A2A4" }}
                    onChange={handleInputChange}
                    readOnly
                  />
                </div>
                <div className="form-group mt-2">
                  <label>Location</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Enter Location"
                    name="location"
                    value={state.location}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-dark m-3">
            Save Changes
          </button>
        </form>
        <div className=" ms-1">
          <form onSubmit={handleSubmit}>
            <div className="card col-12">
              <div className="fs-18 mt-3 ps-4">
                Password Settings
                {!editMode && (
                  <span
                    className="position-absolute"
                    style={{ right: "15px", cursor: "pointer" }}
                    onClick={() => setEditMode(true)}
                  >
                    <CiEdit />
                  </span>
                )}
                <br />
              </div>
              {!editMode && (
                <span className="fs-16 p-1 mb-2 ms-4">
                  You can update your password
                </span>
              )}
              {editMode && (
                <div className="col-12 p-3">
                  <div className="row">
                    <div className="form-group ms-2">
                      <label
                        style={{
                          fontSize: "14px",
                          color: "rgba(69, 69, 73, 1)",
                        }}
                      >
                        Old Password
                      </label>
                      <div className="input-group">
                        <input
                          type={showOldPassword ? "text" : "password"}
                          className="form-control mt-2"
                          placeholder="Enter old password"
                          name="oldPassword"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          required
                        />
                        <button
                          className="btn btn-outline-secondary mt-2"
                          type="button"
                          onClick={() => togglePasswordVisibility("old")}
                        >
                          {showOldPassword ? <FiEyeOff /> : <FiEye />}
                        </button>
                      </div>
                    </div>
                    <div className="form-group mt-2 ms-2">
                      <label
                        style={{
                          fontSize: "14px",
                          color: "rgba(69, 69, 73, 1)",
                        }}
                      >
                        New Password
                      </label>
                      <div className="input-group">
                        <input
                          type={showNewPassword ? "text" : "password"}
                          className="form-control mt-2"
                          placeholder="Enter new password"
                          name="newPassword"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                        <button
                          className="btn btn-outline-secondary mt-2"
                          type="button"
                          onClick={() => togglePasswordVisibility("new")}
                        >
                          {showNewPassword ? <FiEyeOff /> : <FiEye />}
                        </button>
                      </div>

                      {errors.newPassword.passwordLength && (
                        <small style={{ color: "tomato" }}>
                          {errors.newPassword.passwordLength}
                        </small>
                      )}
                      {errors.newPassword.passwordUppercase && (
                        <small style={{ color: "tomato" }}>
                          {errors.newPassword.passwordUppercase}
                        </small>
                      )}
                      {errors.newPassword.passwordLowercase && (
                        <small style={{ color: "tomato" }}>
                          {errors.newPassword.passwordLowercase}
                        </small>
                      )}
                      {errors.newPassword.passwordNumber && (
                        <small style={{ color: "tomato" }}>
                          {errors.newPassword.passwordNumber}
                        </small>
                      )}
                      {errors.newPassword.passwordSpecial && (
                        <small style={{ color: "tomato" }}>
                          {errors.newPassword.passwordSpecial}
                        </small>
                      )}
                    </div>
                    <div className="form-group mt-2 ms-2">
                      <label
                        style={{
                          fontSize: "14px",
                          color: "rgba(69, 69, 73, 1)",
                        }}
                      >
                        Confirm Password
                      </label>
                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control mt-2"
                          placeholder="Confirm new password"
                          name="confirmPassword"
                          value={confirmPassword}
                          disabled={!isNewPasswordValid}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          required
                        />
                        <button
                          className="btn btn-outline-secondary mt-2"
                          type="button"
                          onClick={() => togglePasswordVisibility("confirm")}
                        >
                          {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                        </button>
                      </div>
                      {/* {Error && (
                                                <small style={{ color: "tomato" }}>Passwords do not match</small>
                                            )} */}
                      {errors.confirmPassword.passwordMatch && (
                        <small style={{ color: "tomato" }}>
                          {errors.confirmPassword.passwordMatch}
                        </small>
                      )}
                      {/* {errors.passwordLength && <small style={{ color: "tomato" }}>{errors.passwordLength}</small>}
                                            {errors.passwordUppercase && <small style={{ color: "tomato" }}>{errors.passwordUppercase}</small>}
                                            {errors.passwordLowercase && <small style={{ color: "tomato" }}>{errors.passwordLowercase}</small>}
                                            {errors.passwordNumber && <small style={{ color: "tomato" }}>{errors.passwordNumber}</small>}
                                            {errors.passwordSpecial && <small style={{ color: "tomato" }}>{errors.passwordSpecial}</small>} */}
                    </div>
                  </div>
                  <div
                    className="d-flex gap-2 mt-3 ms-2 "
                    style={{ cursor: "pointer" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-dark"
                      disabled={
                        !oldPassword ||
                        !newPassword ||
                        !confirmPassword ||
                        confirmPassword !== newPassword
                      }
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-light"
                      style={{ color: "rgba(51, 68, 153, 1)" }}
                      onClick={() => {
                        setEditMode(false);
                        setOldPassword("");
                        setNewPassword("");
                        setConfirmPassword("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
          <div className="card col-12 mt-2">
            <div className="col-12 p-2">
              <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
                <div>
                  <label
                    className="fs-18 form-check-label ps-3"
                    htmlFor="priceSwitch"
                    style={{ width: "80%", wordBreak: "break-all" }}
                  >
                    Social simulcasting
                    <br />
                    <span className="fs-16 p-1">
                      You can link multiple social platforms to broadcast live
                      streams.
                    </span>
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input switch-lg"
                    type="checkbox"
                    role="switch"
                    id="option"
                    checked={switches.option}
                    value={switches.option}
                    onChange={
                      () => handleToggle("option")
                      // handleSubmitupdated('option');
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companyinfo;
