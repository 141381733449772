import React, { useEffect, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import Moderatemodal from "./Moderatemodal";
import { pdpApiInstance } from "../../axiosInstances";

const ReviewCard = ({
  setReviewVideos,
  reviewVideos,
  setModeratingObj,
  moderatingObj,
  tab,
  setTab,
  setPlayinVideoId,
  playinVideoId,
  el,
  selectedCards,
  index,
  showCheckboxes,
  handleCheckboxChange,
  setLoading,
  fetchNotReviewedVideosCOunr,
  hideModerateButton = false,
}) => {
  const navigate = useNavigate();
  const [play, setPlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [duration, setDuration] = useState("00:00");
  const [loading, setLoadingState] = useState(true); // Loading state for smoother experience

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleModerateClick = () => {
    setModeratingObj(el);
    handleShow(); // Show the modal
  };


  const formatVideoDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(durationInSeconds % 60)
      .toString()
      .padStart(2, "0");
    setDuration(`${minutes}:${seconds}`);
  };

  const handleReviewDelete = async () => {
    try {
      confirmAlert({
        message: "Are you sure you want to delete this review?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              await pdpApiInstance
                .put(
                  `/api/moderator/video/${el.id}`,
                  {
                    isDeleted: true,
                  },
                  {
                    headers: {
                      Authorization: localStorage.getItem("token")
                        ? localStorage.getItem("token")
                        : "",
                    },
                  }
                )
                .then((res) => {
                  if (res.data) {
                    toast.success("Review deleted successfully");
                    const elm = document.getElementById(`videocard_${el.id}`);
                    if (elm) {
                      elm.remove();
                      fetchNotReviewedVideosCOunr();
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                  toast.error("Something went wrong!");
                });
            },
          },
          { label: "No", onClick: () => { } },
        ],
      });
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  const cdnUrl = process.env.REACT_APP_S3_CDN

  useEffect(() => {
    formatVideoDuration(el?.duration);

    const image = new Image();
    image.src = `${cdnUrl}${el.coverImage}`;
    image.onload = () => setLoadingState(false); // Once the image is loaded, update the loading state
  }, [el]);

  return (
    <>
      <div
        className="col-lg-2 col-4 col-sm-6 p-2"
        id={`videocard_${el.id}`}
        key={index}
      >
        <div
          className="card h-100"
          style={{ borderRadius: "10px" }}
          onClick={() => showCheckboxes && handleCheckboxChange(el.id)}
        >
          <div
            className="h-100"
            style={{
              position: "relative",
              borderRadius: "10px",
              border: selectedCards?.includes(el.id)
                ? "5px solid #334499"
                : "none",
            }}
          >
            {loading ? (
              <img
                className="h-100 w-100"
                style={{
                  borderRadius: "10px",
                  objectFit: "cover",
                  height: hideModerateButton
                    ? "calc(100% - 70px)"
                    : "calc(100% - 90px)",
                }}
                loading="lazy"
                src={`https://cdn-product-images.goswirl.live/default-video-thumbnail.webp`}
                alt="cover"
              />
            ) : (
              <video
                className="h-100 w-100"
                style={{ objectFit: "cover", borderRadius: "10px" }}
                poster={
                  el.coverImage
                    ? `${cdnUrl}${el.coverImage}`
                    : "https://cdn-product-images.goswirl.live/default-video-thumbnail.webp"
                }
                ref={(ref) => {
                  if (ref) {
                    if (playinVideoId === el.id) {
                      ref.play();
                    } else {
                      ref.pause();
                    }
                  }
                }}
              >
                <source
                  src={
                    el.assetKey
                      ? `${cdnUrl}${el.assetKey}`
                      : el.assetKey
                  }
                />
              </video>
            )}

            <div style={{ position: "absolute", right: "0", top: "0" }}>
              <div
                style={{
                  position: "relative",
                  top: "0",
                  right: "0",
                  textAlign: "right",
                }}
              >
                {showCheckboxes ? (
                  <input
                    type="checkbox"
                    className="float-end m-2"
                    checked={selectedCards.includes(el.id)}
                    style={{
                      accentColor:
                        tab === "video" ? "rgba(238, 238, 119, 1)" : "black",
                    }}
                  />
                ) : (
                  <button
                    style={{
                      position: "absolute",
                      top: "15px",
                      right: "15px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "red",
                      fontSize: "14px",
                      backgroundColor: "white",
                      padding: "4px 7px",
                      borderRadius: "50%",
                    }}
                    onClick={() => handleReviewDelete(index)}
                  >
                    <i
                      className="ri-delete-bin-line"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>
                )}
              </div>
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();

                if (el.id === playinVideoId) {
                  setPlayinVideoId(null);
                } else {
                  setPlayinVideoId(el.id);
                }
              }}
              className="pointer_cursor"
              style={{
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
                display: loading ? "none" : "",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  height: "34px",
                  width: "34px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "50%",
                }}
              >
                {playinVideoId === el.id ? (
                  <FaPause
                    size={15}
                    className="ps-1"
                    style={{ marginLeft: "-4px", cursor: "pointer" }}
                    color="#09121F"
                  />
                ) : (
                  <FaPlay
                    size={15}
                    className="ps-1"
                    style={{ cursor: "pointer" }}
                    color="#09121F"
                  />
                )}
              </div>
            </div>
            <div
              className="badge position-absolute  end-0 mb-4 me-1"
              style={{
                bottom: "80px",
                backgroundColor: "rgba(23, 23, 28, 0.7)",
              }}
            >
              {duration}
            </div>
            {el.status === "approved" && (
              <div
                className="badge position-absolute start-0 mb-4 ms-1"
                style={{
                  bottom: "80px",
                  backgroundColor: "rgba(11, 137, 92, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "15px",
                }}
              >
                Approved
              </div>
            )}
            {el.status === "notreviewed" && (
              <div
                className="badge position-absolute start-0 mb-4 ms-1"
                style={{
                  bottom: "80px",
                  backgroundColor: "rgba(23, 23, 28, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "15px",
                }}
              >
                Not Reviewed
              </div>
            )}
            {el.status === "rejected" && (
              <div
                className="badge position-absolute start-0 mb-4 ms-1"
                style={{
                  bottom: "80px",
                  backgroundColor: "rgba(255, 136, 102, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "15px",
                }}
              >
                Reject
              </div>
            )}
            {el.status === "published" && (
              <div
                className="badge position-absolute start-0 mb-4 ms-1"
                style={{
                  bottom: "80px",
                  backgroundColor: "rgba(238, 238, 119, 1)",
                  color: "rgba(93, 93, 96, 1)",
                  borderRadius: "15px",
                }}
              >
                Published
              </div>
            )}
            <div
              className="p-1 bg-white w-100 video_title_gallery"
              title={el.title}
              style={{
                position: "absolute",
                bottom: "0",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <span
                className="ms-1"
                style={{
                  fontSize: "16px",
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginBottom: "0px",
                }}
              >
                {el.title ? el.title : "Untitled"}
              </span>

              <span
                className="ms-1"
                style={{ fontSize: "14px", color: "rgba(139, 139, 142, 1)" }}
              >
                Submitted on {el?.updatedAt?.split("T")[0]}
              </span>

              {hideModerateButton ? (
                ""
              ) : (
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-light btn-outline-dark w-100 ms-1 me-1"
                    onClick={handleModerateClick}
                  >
                    Moderate
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Moderatemodal
        show={showModal}
        handleClose={handleClose}
        videoUrl={`${cdnUrl}${el.assetKey}`}
        el={moderatingObj}
        reviewVideos={reviewVideos}
        setReviewVideos={setReviewVideos}
        fetchNotReviewedVideosCOunr={fetchNotReviewedVideosCOunr}
      />
    </>
  );
};

export default ReviewCard;
