export const Whimsical = (templateNumber, { user, selectedOption }) => {
  switch (templateNumber) {
    case 1:
      return `
              Subject: ${selectedOption?.label}: A Stellar Review!
              <br/><br/>
              Hey there, stargazer!
              <br/><br/>
              Is your ${selectedOption?.label} shining bright in your world? We're eager to hear about your stellar experience!
              <br/><br/>
              Send us a video review and let us know what you think of your celestial companion.
              <br/><br/>
              Submit your review here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Thanks for being a part of our galaxy of happy customers!
              <br/><br/>
              The ${user?.brand_name} Team
            `;
    case 2:
      return `
              Subject: Your ${selectedOption?.label}: A Magical Adventure!
              <br/><br/>
              Hey there, explorer!
              <br/><br/>
              Is your ${selectedOption?.label} taking you on a magical adventure? We're itching to hear about your enchanted journey!
              <br/><br/>
              Send us a video review and let us know what you think of your enchanted companion.
              <br/><br/>
              Submit your review here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Thanks for being a part of our enchanted world!
              <br/><br/>
              The ${user?.brand_name} Team
            `;
    case 3:
      return `
              Subject: Spill the Tea on Your ${selectedOption?.label}!
              <br/><br/>
              Hey Customer ,
              <br/><br/>
              We're curious... what's the real tea on your ${selectedOption?.label}? Do you love it? Do you love it not? Inquiring minds want to know!
              <br/><br/>
              Grab your phone, get cozy, and record a teensy video review (just 1-2 minutes, we promise!). Share your thoughts, your feels, and your fav features. The good, the bad, and the utterly fantastic!
              <br/><br/>
              Here's the magic formula:
              <br/><br/>
              - Keep it short & sweet (like a fairy tale)
              <br/>
              - Be honest (we won't judge, we promise!)
              <br/><br/>
              Submit your review here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Thanks for sharing your enchanted thoughts!
              <br/><br/>
              Cheers,
              <br/><br/>
              [Your Name]
              <br/>
              The ${user?.brand_name} Team
            `;
    case 4:
      return `
              Subject: Your ${selectedOption?.label} Secrets Revealed!
              <br/><br/>
              Hey Customer ,
              <br/><br/>
              Confession time! We want to know all about your ${selectedOption?.label} adventures! What makes you swoon? What makes you go "hmm"?
              <br/><br/>
              Record a quick video confession (just 1-2 minutes, we won't tell!) and share your innermost thoughts. The more sparkle, the better!
              <br/><br/>
              Submit your review here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Thanks for spilling the beans!
              <br/><br/>
              Cheers,
              <br/><br/>
              [Your Name]
              <br/>
              ${selectedOption?.label} Secret Keepers
            `;
    case 5:
      return `
              Subject: Show Off Your ${selectedOption?.label} Skills!
              <br/><br/>
              Hey Customer ,
              <br/><br/>
              Hope you’re soaring like a caffeinated rocket!
              <br/><br/>
              Got our cosmic ${selectedOption?.label}? We’d love to see it in action!
              <br/><br/>
              How?
              <br/><br/>
              - Grab your phone. (Or borrow one from a friendly alien.)
              <br/>
              - Find light. Avoid black holes. Natural light works best.
              <br/>
              - Talk to the lens. Share your ${selectedOption?.label} tales.
              <br/>
              - Bonus for quirky accents!
              <br/><br/>
              Submit your review here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value}
              <br/><br/>
              Galactically yours,
              <br/><br/>
              [Your Name]
              <br/>
              ${user?.brand_name}
            `;
  }
};
