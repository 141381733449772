import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { FaAngleDown, FaStar } from "react-icons/fa"; // Example SVG icon
import ReviewCard from "./ReviewCard"; // Ensure this component is imported
import { useSelector } from "react-redux";
import { DateRangePicker } from "react-date-range";
import Dropdown from "react-bootstrap/Dropdown";
import { IoIosStar } from "react-icons/io";
import { LuStarOff } from "react-icons/lu";
import SelecteProducts from "../upload/SelecteProducts";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { FallingLines } from "react-loader-spinner";
import { pdpApiInstance } from "../../axiosInstances";
import { components } from "react-select";
import Reviewnewvideo from "./Reviewnewvideo";

const Loading = () => (
  <>
    <Helmet>
      <title>SWIRL | Loading...</title>
    </Helmet>
    <div className="loader_comp_main_div mt-5">
      <FallingLines
        color="#EEEE77"
        width="100"
        visible={true}
        ariaLabel="falling-circles-loading"
      />
    </div>
  </>
);
// const customStyles = {
//     option: (provided, state) => ({
//         ...provided,
//         padding: 10,
//         borderBottom: "1px solid rgba(232, 232, 232, 1)",
//         backgroundColor: state.isSelected ? '#e0e0e0' : '',
//         '&:hover': {
//             backgroundColor: '#e0e0e0',
//         }
//     }),
//     control: (provided) => ({
//         ...provided,
//         width: 180,
//         // height: 10,
//         overflowY: 'auto',
//         borderRadius: "7px",
//         display: 'flex',
//         flexDirection: "row",
//         flexWrap: 'nowrap',
//         overflow: 'hidden',
//         whiteSpace: 'nowrap',
//     }),
//     menu: (provided) => ({
//         ...provided,
//         borderRadius: "7px",
//         border: "1px solid rgba(209, 209, 210, 1)",
//     }),
//     singleValue: (provided) => ({
//         ...provided,
//         display: 'flex',
//         alignItems: 'center',
//         whiteSpace: 'nowrap',
//         overflow: 'hidden',
//     }),
//     valueContainer: (provided) => ({
//         ...provided,
//         flexWrap: 'wrap',
//         overflow: 'hidden',
//     }),

// };

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    borderBottom: "1px solid rgba(232, 232, 232, 1)",
    backgroundColor: state.isSelected ? "#e0e0e0" : "",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  }),
  control: (provided) => ({
    ...provided,
    width: 180,
    borderRadius: "7px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    overflow: "hidden",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "7px",
    border: "1px solid rgba(209, 209, 210, 1)",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    whiteSpace: "normal",
    overflow: "visible", // Ensure all content is visible
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
    overflow: "visible", // Ensure all selected items are visible
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: "0px",
    marginRight: "0px",
    fontSize: "15px",
  }),
};
// const customStyles = {
//   option: (provided, state) => ({
//     ...provided,
//     padding: 10,
//     borderBottom: "1px solid rgba(232, 232, 232, 1)",
//     backgroundColor: state.isSelected ? "#e0e0e0" : "",
//     "&:hover": {
//       backgroundColor: "#e0e0e0",
//     },
//   }),
//   control: (provided) => ({
//     ...provided,
//     width: 180,
//     // height: 12,
//     borderRadius: "7px",
//     display: "flex",
//     flexDirection: "row",
//     flexWrap: "nowrap",
//     overflow: "hidden",
//     // margin: '0px'
//   }),
//   menu: (provided) => ({
//     ...provided,
//     borderRadius: "7px",
//     border: "1px solid rgba(209, 209, 210, 1)",
//   }),
//   placeholder: (provided) => ({
//     ...provided,
//     marginLeft: "0px",
//     marginRight: "0px",
//     fontSize: "15px",
//   }),
//   singleValue: (provided) => ({
//     ...provided,
//     display: "flex",
//     alignItems: "center",
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     color: "black",
//     padding: "0 10px",
//   }),
// };

const CustomSingleSelectOptionPRODUCT = (props) => {
  const handleClick = () => {
    props.selectOption(props.data);
  };

  return (
    <div
      {...props.innerProps}
      onClick={handleClick}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        backgroundColor: props.isSelected ? "#e0e0e0" : "white",
        fontSize: "12px",
      }}
    >
      <input
        type="checkbox"
        readOnly
        checked={props.isSelected}
        style={{ marginRight: 10 }}
      />
      {props.data.label}
    </div>
  );
};

const CustomSingleSelectOption = (props) => {
  const handleClick = () => {
    props.selectOption(props.data);
  };

  return (
    <div
      {...props.innerProps}
      onClick={handleClick}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        backgroundColor: props.isSelected ? "#e0e0e0" : "white",
      }}
    >
      <input
        type="checkbox"
        readOnly
        checked={props.isSelected}
        style={{ marginRight: 10 }}
      />
      {props.data.label}
    </div>
  );
};
const CustomMultiValue = ({ getValue, ...props }) => {
  const selectedValues = getValue();
  const lastValue = selectedValues[selectedValues.length - 1]; // Get the last selected value
  // console.log("value", lastValue.label.slice(0, 15));

  // Show only the last selected value in the placeholder area
  if (props.data === lastValue) {
    return <components.MultiValue {...props} />;
    // return (
    //   <components.MultiValue {...props}>
    //     <span title={lastValue.label} style={{ cursor: "pointer" }}>
    //       {lastValue.label.slice(0, 10)}
    //       {lastValue.label.length > 10 && "..."}
    //     </span>
    //   </components.MultiValue>
    // );
  } else {
    return null;
  }
};
const CustomMultiValueproduct = ({ getValue, ...props }) => {
  const selectedValues = getValue();
  const lastValue = selectedValues[selectedValues.length - 1]; // Get the last selected value
  // console.log("value", lastValue.label.slice(0, 15));

  // Show only the last selected value in the placeholder area
  if (props.data === lastValue) {
    // return <components.MultiValue {...props} />;
    return (
      <components.MultiValue {...props}>
        <span title={lastValue.label} style={{ cursor: "pointer" }}>
          {lastValue.label.slice(0, 10)}
          {lastValue.label.length > 10 && "..."}
        </span>
      </components.MultiValue>
    );
  } else {
    return null;
  }
};

const Modrator = ({ fetchNotReviewedVideosCOunr }) => {
  const [selectedStarRating, setSelectedStarRating] = useState(null);
  const [selectedSentiment, setSelectedSentiment] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAll, setSelectedAll] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reviewVideos, setReviewVideos] = useState([]);
  // const [queryText, setQueryText] = useState('');
  const [productoption, setProductoption] = useState([]);
  const [tab, setTab] = useState(""); // Initialize with a default value if needed
  const [playinVideoId, setPlayinVideoId] = useState(null);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Define dropdownOpen
  const [moderatingObj, setModeratingObj] = useState(null);
  const [page, setPage] = useState(1);
  const [totalVideos, setTotalVideos] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleViewMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData2(nextPage, "viewmore");
  };

  const [state, setState] = useState([
    // Define state
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const hasStateChanged = (currentState) => {
    const twoMonthsBefore = new Date();
    twoMonthsBefore.setMonth(twoMonthsBefore.getMonth() - 2);
    const defaultState = [
      {
        startDate: twoMonthsBefore,
        endDate: new Date(),
        key: "selection",
      },
    ];

    // Check if startDate or endDate have changed
    if (
      xstate[0].startDate.getDate() === defaultState[0].startDate.getDate() ||
      xstate[0].endDate.getDate() === defaultState[0].endDate.getDate()
    ) {
      return false;
    }

    // If no changes are detected
    return true;
  };
  const twoMonthsBefore = new Date();
  twoMonthsBefore.setMonth(twoMonthsBefore.getMonth() - 2);
  const [xstate, setxState] = useState([
    {
      startDate: twoMonthsBefore,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const today = new Date(); // Define today
  const yesterday = new Date(today); // Define yesterday
  yesterday.setDate(yesterday.getDate());

  const user = useSelector((state) => state.auth.user);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleApply = () => {
    setDropdownOpen(false); // Close the dropdown
    setState(xstate);
  };

  function extractLabels(arr) {
    return arr.map((item) => item.label);
  }

  const fetchData2 = async (pageNumber, tag) => {
    if (tag !== "viewmore") {
      setLoading(true);
      // Reset the page number to 1 and clear the existing videos if not loading more
      if (pageNumber === 1) {
        setReviewVideos([]); // Clear the existing videos
      }
    }

    try {
      const res = await pdpApiInstance.get(`/api/moderator/videos`, {
        params: {
          ratings: selectedStarRating
            ? selectedStarRating.map((option) => option.value).join(",")
            : "",
          sentimentScores: selectedSentiment
            ? extractLabels(selectedSentiment)
            : "",
          productIds: selectedProduct
            ? selectedProduct.map((item) => item.value)
            : "",
          status: selectedAll ? selectedAll.value : "",
          createdAtStart: format(xstate[0].startDate, "yyyy-MM-dd"),
          createdAtEnd: format(xstate[0].endDate, "yyyy-MM-dd"),
          page: pageNumber ? pageNumber : 1,
          pageSize: pageNumber > 1 ? 12 : 11,
        },

        headers: {
          Authorization: localStorage.getItem("token")
            ? localStorage.getItem("token")
            : "",
        },
      });

      // If loading more videos, append them to the existing list
      // If not, replace the list with the new data
      if (tag === "viewmore") {
        setReviewVideos((prevVideos) => [
          ...prevVideos,
          ...res.data.data.records,
        ]);
      } else {
        setReviewVideos(res.data.data.records);
        setPage(1); // Reset the page number after applying a filter
      }

      setTotalVideos(res.data.data.count);

      if (tag !== "viewmore") {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
      if (tag !== "viewmore") {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      }
    }
  };

  useEffect(() => {
    fetchData2();
    fetchNotReviewedVideosCOunr();
  }, []);
  const handleFilterChange = () => {
    fetchData2(1, "filterChange");
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleaddClick = () => {
    handleShow(); // Show the modal
  };
  useEffect(() => {
    handleFilterChange();
  }, [
    selectedStarRating,
    selectedSentiment,
    selectedProduct,
    selectedAll,
    state,
  ]);

  // useEffect(() => {
  //     fetchData();
  // }, [user.brand_id]);

  const handleCheckboxChange = (id) => {
    // Implement checkbox change logic
  };

  const toggleCheckboxes = () => {
    // Implement toggle checkboxes logic
  };
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await pdpApiInstance.get("/api/products", {
          headers: {
            Authorization: localStorage.getItem("token")
              ? localStorage.getItem("token")
              : "",
          },
        });
        const products = response.data.data.map((product) => ({
          label: product.name,
          value: product.uuid,
        }));
        setProductoption(products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProductData();
  }, []);

  const options = {
    starRating: [
      {
        value: 0,
        label: (
          <>
            <LuStarOff />
          </>
        ),
      },
      {
        value: 1,
        label: <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />,
      },
      {
        value: 2,
        label: (
          <>
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
          </>
        ),
      },
      {
        value: 3,
        label: (
          <>
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
          </>
        ),
      },
      {
        value: 4,
        label: (
          <>
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
          </>
        ),
      },
      {
        value: 5,
        label: (
          <>
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
            <IoIosStar style={{ color: "rgba(11, 137, 92, 1)" }} />
          </>
        ),
      },
    ],

    sentiment: [
      { value: "0-30", label: "0-30" },
      { value: "31-60", label: "31-60" },
      { value: "61-80", label: "61-80" },
      { value: "81-100", label: "81-100" },
    ],
    // products: [
    //     { value: 'product1', label: 'Product 1' },
    //     { value: 'product2', label: 'Product 2' },
    //     { value: 'product3', label: 'Product 3' },
    // ],
    all: [
      { value: "approved", label: "Approved" },
      { value: "rejected", label: "Rejected" },
      { value: "published", label: "Published" },
      { value: "notreviewed", label: "Not reviewed" },
    ],
  };

  return (
    <div className="container-fluid">
      {reviewVideos.length === 0 &&
        !(
          selectedAll ||
          selectedSentiment ||
          selectedStarRating ||
          selectedProduct ||
          hasStateChanged() ||
          loading
        ) ? (
        <>
          <div
            className="mt-2"
            style={{ textAlign: "center", padding: "50px" }}
          >
            <h2 style={{ fontSize: "24px", fontWeight: "450" }}>
              Ready to make your debut on the digital stage?
            </h2>
            <h1 style={{ fontSize: "32px", fontWeight: "700" }}>
              Let's hit upload and unveil your masterpiece to the world!
            </h1>
            <div>
              <svg
                width="256"
                height="226"
                viewBox="0 0 256 226"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2306_26462)">
                  <path
                    d="M42.7756 85.6198C66.3998 85.6198 85.5511 66.4532 85.5511 42.8099C85.5511 19.1667 66.3998 0 42.7756 0C19.1513 0 0 19.1667 0 42.8099C0 66.4532 19.1513 85.6198 42.7756 85.6198Z"
                    fill="#E8E8E8"
                  />
                  <path
                    d="M71.0515 38.9184H46.7239V14.509C46.7239 13.4768 46.3142 12.4869 45.585 11.7571C44.8557 11.0272 43.8666 10.6172 42.8352 10.6172C41.8039 10.6172 40.8148 11.0272 40.0855 11.7571C39.3562 12.4869 38.9466 13.4768 38.9466 14.509V38.9184H14.4971C13.4657 38.9184 12.4766 39.3285 11.7474 40.0583C11.0181 40.7882 10.6084 41.7781 10.6084 42.8102C10.6084 43.8424 11.0181 44.8323 11.7474 45.5622C12.4766 46.292 13.4657 46.702 14.4971 46.702H38.9466V71.1115C38.9466 72.1437 39.3562 73.1335 40.0855 73.8634C40.8148 74.5933 41.8039 75.0033 42.8352 75.0033C43.8666 75.0033 44.8557 74.5933 45.585 73.8634C46.3142 73.1335 46.7239 72.1437 46.7239 71.1115V46.702H71.0515C72.0829 46.702 73.072 46.292 73.8013 45.5622C74.5305 44.8323 74.9402 43.8424 74.9402 42.8102C74.9402 41.7781 74.5305 40.7882 73.8013 40.0583C73.072 39.3285 72.0829 38.9184 71.0515 38.9184Z"
                    fill="#B9B9BB"
                  />
                  <path
                    d="M108.424 165.435C105.86 165.428 103.34 164.766 101.103 163.51C98.7531 162.227 96.7944 160.331 95.4358 158.023C94.0772 155.715 93.3695 153.081 93.3881 150.402V91.2335C93.3985 88.5541 94.1237 85.9262 95.4885 83.6211C96.8533 81.316 98.8083 79.4174 101.152 78.1214C103.495 76.8255 106.141 76.1792 108.818 76.2493C111.494 76.3194 114.103 77.1034 116.375 78.5203L163.836 108.098C165.998 109.442 167.782 111.317 169.019 113.543C170.256 115.77 170.905 118.275 170.905 120.823C170.905 123.371 170.256 125.876 169.019 128.103C167.782 130.329 165.998 132.203 163.836 133.548L116.375 163.126C113.994 164.625 111.24 165.425 108.427 165.432L108.424 165.435ZM108.424 83.9999C107.185 84.003 105.967 84.3227 104.886 84.9288C103.754 85.5446 102.81 86.4562 102.155 87.5665C101.5 88.6768 101.158 89.9441 101.165 91.2335V150.402C101.158 151.691 101.499 152.958 102.154 154.068C102.809 155.179 103.752 156.09 104.883 156.707C106.016 157.346 107.3 157.666 108.6 157.634C109.9 157.601 111.167 157.217 112.266 156.523L159.726 126.945C160.767 126.299 161.626 125.399 162.222 124.328C162.817 123.257 163.13 122.052 163.13 120.827C163.13 119.601 162.817 118.396 162.222 117.326C161.626 116.255 160.767 115.354 159.726 114.709L112.266 85.1311C111.116 84.4056 109.784 84.0189 108.424 84.0155V83.9999Z"
                    fill="#454549"
                  />
                  <path
                    d="M190.45 199.779H63.733C57.1734 199.771 50.8838 197.164 46.2392 192.529C41.5945 187.893 38.9726 181.605 38.9465 175.041V95.9979C38.9465 94.9657 39.3562 93.9758 40.0855 93.2459C40.8148 92.5161 41.8039 92.106 42.8352 92.106C43.8666 92.106 44.8557 92.5161 45.5849 93.2459C46.3142 93.9758 46.7239 94.9657 46.7239 95.9979V175.035C46.7451 179.539 48.5467 183.851 51.7348 187.029C54.9229 190.207 59.2384 191.992 63.7382 191.996H190.445C194.945 191.992 199.26 190.205 202.448 187.026C205.636 183.847 207.436 179.534 207.456 175.03V63.6647C207.435 59.1611 205.634 54.849 202.446 51.6705C199.257 48.4921 194.942 46.7057 190.442 46.7016H95.9806C94.9493 46.7016 93.9602 46.2916 93.2309 45.5617C92.5016 44.8319 92.0919 43.842 92.0919 42.8098C92.0919 41.7776 92.5016 40.7877 93.2309 40.0579C93.9602 39.328 94.9493 38.918 95.9806 38.918H190.445C197.005 38.9268 203.295 41.5342 207.94 46.1703C212.585 50.8064 215.207 57.0942 215.234 63.6595V175.035C215.209 181.601 212.588 187.889 207.944 192.526C203.3 197.163 197.01 199.771 190.45 199.779Z"
                    fill="#454549"
                  />
                  <path
                    d="M255.728 179.4L240.367 75.8783C239.48 69.8941 236.432 64.4427 231.801 60.5546C227.17 56.6665 221.276 54.6111 215.234 54.7769H213.538C214.649 57.6073 215.224 60.6197 215.234 63.6606V175.036C215.209 181.602 212.588 187.89 207.944 192.527C203.299 197.164 197.01 199.772 190.45 199.781H84.3145V205.032C85.3164 211.493 88.8311 217.295 94.0916 221.172C99.352 225.049 105.931 226.686 112.393 225.726L234.89 207.523C241.38 206.558 247.221 203.053 251.129 197.778C255.037 192.503 256.691 185.891 255.728 179.395V179.4Z"
                    fill="#E8E8E8"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2306_26462">
                    <rect width="256" height="226" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-wrap gap-1 justify-content-end text-muted">
            <Select
              options={productoption}
              value={selectedProduct}
              onChange={setSelectedProduct}
              placeholder="Select Product"
              styles={customStyles}
              components={{
                Option: CustomSingleSelectOptionPRODUCT,
                MultiValue: CustomMultiValueproduct,
              }}
              isMulti
              closeMenuOnSelect={false}
              className="mb-2"
              hideSelectedOptions={false}
            />
            <Select
              options={options.all}
              value={selectedAll}
              onChange={setSelectedAll}
              placeholder="Select All Option"
              styles={customStyles}
              // components={{ Option: CustomSingleSelectOption }}
              className="mb-2"
              isClearable={true}
            />
            <Select
              options={options.sentiment}
              value={selectedSentiment}
              onChange={setSelectedSentiment}
              placeholder="Select Sentiment"
              styles={customStyles}
              components={{
                Option: CustomSingleSelectOption,
                MultiValue: CustomMultiValue,
              }}
              isMulti
              closeMenuOnSelect={false}
              className="mb-2"
              hideSelectedOptions={false}
            />
            <Select
              options={options.starRating}
              styles={customStyles}
              onChange={setSelectedStarRating}
              value={selectedStarRating}
              placeholder="Select Star Rating"
              components={{
                Option: CustomSingleSelectOption,
                MultiValue: CustomMultiValue,
              }}
              isMulti
              closeMenuOnSelect={false}
              className="mb-2"
              hideSelectedOptions={false}
            />

            <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="bg-transparent"
                style={{
                  border: "1px solid rgba(209, 209, 210, 1)",
                  color: "rgba(139, 139, 142, 1)",
                  borderRadius: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                <span>
                  {xstate[0].endDate !== null
                    ? `${xstate[0].startDate
                      .toString()
                      .slice(4, 15)} to ${xstate[0].endDate
                        .toString()
                        .slice(4, 15)}`
                    : "Filter by date"}
                  <i
                    className="ri-calendar-line"
                    style={{
                      marginLeft: "20px",
                      marginBottom: "1px",
                      color: "rgba(69, 69, 73, 1)",
                    }}
                  ></i>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <DateRangePicker
                  editableDateInputs={false}
                  onChange={(item) => setxState([item.selection])}
                  moveRangeOnFirstSelection={true}
                  ranges={xstate}
                  months={2}
                  direction="horizontal"
                  maxDate={yesterday}
                />
                <div className="w-100 d-flex justify-content-end mt-2">
                  <button
                    id="dropdown-basic"
                    className="btn btn-sm btn-primary bg-primary border-0 me-2"
                    onClick={handleApply}
                  >
                    Done
                  </button>
                  <button
                    id="dropdown-basic"
                    className="btn btn-sm btn-outline-primary me-2"
                    onClick={() =>
                      setxState([
                        {
                          startDate: new Date(
                            today.getTime() - 7 * 24 * 60 * 60 * 1000
                          ),
                          endDate: yesterday,
                          key: "selection",
                        },
                      ])
                    }
                  >
                    Reset
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <div
                className="d-flex w-100 flex-wrap mt-3 "
                style={{ height: "65vh", overflow: "auto" }}
              >
                {reviewVideos.length === 0 &&
                  (selectedAll ||
                    selectedProduct ||
                    selectedSentiment ||
                    selectedStarRating ||
                    hasStateChanged()) ? (
                  <>
                    <div className="container container_no_videos justify-content-center text-center my-5">
                      <div className="display-6">Nothing found!</div>
                      <div className="lead">
                        Looks like your search went on a video vacation
                      </div>
                      <div className="my-1">
                        <svg
                          width="150"
                          height="226"
                          viewBox="0 0 257 226"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2504_16854)">
                            <path
                              d="M43.2756 85.6198C66.8998 85.6198 86.0511 66.4532 86.0511 42.8099C86.0511 19.1667 66.8998 0 43.2756 0C19.6513 0 0.5 19.1667 0.5 42.8099C0.5 66.4532 19.6513 85.6198 43.2756 85.6198Z"
                              fill="#FF6D40"
                            />
                            <path
                              d="M108.924 165.435C106.36 165.428 103.84 164.766 101.603 163.51C99.2531 162.227 97.2944 160.331 95.9358 158.023C94.5772 155.715 93.8695 153.081 93.8881 150.402V91.2335C93.8985 88.5541 94.6237 85.9262 95.9885 83.6211C97.3533 81.316 99.3083 79.4174 101.652 78.1214C103.995 76.8255 106.641 76.1792 109.318 76.2493C111.994 76.3194 114.603 77.1034 116.875 78.5203L164.336 108.098C166.498 109.442 168.282 111.317 169.519 113.543C170.756 115.77 171.405 118.275 171.405 120.823C171.405 123.371 170.756 125.876 169.519 128.103C168.282 130.329 166.498 132.203 164.336 133.548L116.875 163.126C114.494 164.625 111.74 165.425 108.927 165.432L108.924 165.435ZM108.924 83.9999C107.685 84.003 106.467 84.3227 105.386 84.9288C104.254 85.5446 103.31 86.4562 102.655 87.5665C102 88.6768 101.658 89.9441 101.665 91.2335V150.402C101.658 151.691 101.999 152.958 102.654 154.068C103.309 155.179 104.252 156.09 105.383 156.707C106.516 157.346 107.8 157.666 109.1 157.634C110.4 157.601 111.667 157.217 112.766 156.523L160.226 126.945C161.267 126.299 162.126 125.399 162.722 124.328C163.317 123.257 163.63 122.052 163.63 120.827C163.63 119.601 163.317 118.396 162.722 117.326C162.126 116.255 161.267 115.354 160.226 114.709L112.766 85.1311C111.616 84.4056 110.284 84.0189 108.924 84.0155V83.9999Z"
                              fill="#454549"
                            />
                            <path
                              d="M190.95 199.779H64.233C57.6734 199.771 51.3838 197.164 46.7392 192.529C42.0945 187.893 39.4726 181.605 39.4465 175.041V95.9979C39.4465 94.9657 39.8562 93.9758 40.5855 93.2459C41.3148 92.5161 42.3039 92.106 43.3352 92.106C44.3666 92.106 45.3557 92.5161 46.0849 93.2459C46.8142 93.9758 47.2239 94.9657 47.2239 95.9979V175.035C47.2451 179.539 49.0467 183.851 52.2348 187.029C55.4229 190.207 59.7384 191.992 64.2382 191.996H190.945C195.445 191.992 199.76 190.205 202.948 187.026C206.136 183.847 207.936 179.534 207.956 175.03V63.6647C207.935 59.1611 206.134 54.849 202.946 51.6705C199.757 48.4921 195.442 46.7057 190.942 46.7016H96.4806C95.4493 46.7016 94.4602 46.2916 93.7309 45.5617C93.0016 44.8319 92.5919 43.842 92.5919 42.8098C92.5919 41.7776 93.0016 40.7877 93.7309 40.0579C94.4602 39.328 95.4493 38.918 96.4806 38.918H190.945C197.505 38.9268 203.795 41.5342 208.44 46.1703C213.085 50.8064 215.707 57.0942 215.734 63.6595V175.035C215.709 181.601 213.088 187.889 208.444 192.526C203.8 197.163 197.51 199.771 190.95 199.779Z"
                              fill="#454549"
                            />
                            <path
                              d="M256.228 179.4L240.867 75.8783C239.98 69.8941 236.932 64.4427 232.301 60.5546C227.67 56.6665 221.776 54.6111 215.734 54.7769H214.038C215.149 57.6073 215.724 60.6197 215.734 63.6606V175.036C215.709 181.602 213.088 187.89 208.444 192.527C203.799 197.164 197.51 199.772 190.95 199.781H84.8145V205.032C85.8164 211.493 89.3311 217.295 94.5916 221.172C99.852 225.049 106.431 226.686 112.893 225.726L235.39 207.523C241.88 206.558 247.721 203.053 251.629 197.778C255.537 192.503 257.191 185.891 256.228 179.395V179.4Z"
                              fill="#E8E8E8"
                            />
                            <g clipPath="url(#clip1_2504_16854)">
                              <path
                                d="M43 40.0547L53.3125 29.7422L56.2584 32.688L45.9459 43.0005L56.2584 53.313L53.3125 56.2589L43 45.9464L32.6875 56.2589L29.7417 53.313L40.0542 43.0005L29.7417 32.688L32.6875 29.7422L43 40.0547Z"
                                fill="white"
                              />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_2504_16854">
                              <rect
                                width="256"
                                height="226"
                                fill="white"
                                transform="translate(0.5)"
                              />
                            </clipPath>
                            <clipPath id="clip1_2504_16854">
                              <rect
                                width="50"
                                height="50"
                                fill="white"
                                transform="translate(18 18)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-2 col-4 col-sm-6 mb-3">
                      <div
                        className="video-add h-100 m-2 d-flex flex-column align-items-center justify-content-center"
                        style={{
                          position: "relative",
                          borderRadius: "10px",
                          border: "2px dashed rgba(232, 232, 232, 1)",
                          backgroundColor: "rgba(245, 245, 245, 1)",
                        }}
                      >
                        <div
                          className="w-100 d-flex flex-column align-items-center"
                          style={{
                            borderRadius: "10px 10px 0 0",
                            position: "absolute",
                          }}
                        >
                          <span style={{ fontSize: "30px", color: "gray" }}>
                            <i className="ri-video-add-line"></i>
                          </span>
                        </div>

                        <div className="flex-grow-1"></div>
                        <button
                          className="btn btn-light border-dark d-flex mb-1 justify-content-center"
                          onClick={handleaddClick}
                          style={{
                            width: "90%",
                          }}
                        >
                          + Add video
                        </button>
                      </div>
                    </div>

                    <Reviewnewvideo
                      show={showModal}
                      handleClose={handleClose}
                      productoption={productoption}
                      fetchData2={fetchData2}
                    />
                    {reviewVideos.map((el, index) => (
                      <ReviewCard
                        key={el.id}
                        tab={tab}
                        setTab={setTab}
                        setPlayinVideoId={setPlayinVideoId}
                        playinVideoId={playinVideoId}
                        toggleCheckboxes={toggleCheckboxes}
                        setLoading={setLoading}
                        selectedCards={selectedCards}
                        handleCheckboxChange={handleCheckboxChange}
                        el={el}
                        showCheckboxes={showCheckboxes}
                        index={index}
                        setModeratingObj={setModeratingObj}
                        moderatingObj={moderatingObj}
                        setReviewVideos={setReviewVideos}
                        reviewVideos={reviewVideos}
                        fetchNotReviewedVideosCOunr={
                          fetchNotReviewedVideosCOunr
                        }
                      />
                    ))}
                    {reviewVideos.length < totalVideos && (
                      <>
                        <div className="text-center w-100">
                          <button
                            className="btn btn-light m-auto mt-2 p-2"
                            style={{ color: "rgba(51, 68, 153, 1)" }}
                            onClick={handleViewMore}
                          >
                            View More ({totalVideos - reviewVideos.length}){" "}
                            <FaAngleDown />{" "}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}{" "}
        </>
      )}
    </div>
  );
};

export default Modrator;
