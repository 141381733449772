import React, { useState } from "react";
import Select, { components } from "react-select";
import { MdDragIndicator } from "react-icons/md";
import { IoClose } from "react-icons/io5";

const SelecteProducts = ({
  data,
  selectedItems,
  setSelectedItems,
  isDisabled,
}) => {
  const handleItemSelect = (selectedOption) => {
    setSelectedItems(selectedOption);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const handleDrop = (e, index) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const draggedItem = selectedItems[draggedIndex];

    const newItems = [...selectedItems];
    newItems.splice(draggedIndex, 1);
    newItems.splice(index, 0, draggedItem);

    setSelectedItems(newItems);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveItem = (index) => {
    const newItems = [...selectedItems];
    newItems.splice(index, 1);
    setSelectedItems(newItems);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      backgroundColor: state.isSelected
        ? "white"
        : state.isFocused
        ? "white"
        : null,
      color: "rgba(162, 162, 164, 1)",
      padding: "15px",
      zIndex: 5,
      borderBottom: "1px solid #ddd", // Add horizontal line
      "&:last-child": {
        borderBottom: "none",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  };
  const sortedOptions = [...data].sort((a, b) => {
    const aSelected = selectedItems.some(
      (item) => item.productId === a.productId
    );
    const bSelected = selectedItems.some(
      (item) => item.productId === b.productId
    );
    return bSelected - aSelected;
  });

  // const truncateLabel = (label, maxLength) => {
  //     if (label?.length <= maxLength) {
  //         return label;
  //     }
  //     return label?.substring(0, maxLength) + '...';
  // };

  const customFormatTagsLabel = ({ productTitle }, type) => {
    if (!productTitle) return;
    if (type === "option") {
      return productTitle;
    }
  };

  // const CheckboxOption = (props) => {
  //     return (
  //         <components.Option {...props}>
  //             <div className='d-flex' style={{ alignItems: "flex-start" }}>
  //                 <input
  //                     type="checkbox"
  //                     checked={props.isSelected}
  //                     onChange={() => null}
  //                     style={{ marginTop: "2px", color: "#5D5D60" s}}
  //                     className='me-2'
  //                 />{" "}
  //                 <label>{props.label}</label>
  //             </div>
  //         </components.Option>
  //     );
  // };

  const CheckboxOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div
            className="playlist"
            style={{ position: "relative", width: "18px", height: "18px" }}
          >
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null} // Handle change if needed
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "18px",
                height: "18px",
                opacity: 0,
                cursor: "pointer",
              }}
            />
            <span
              style={{
                display: "block",
                width: "18px",
                height: "18px",
                border: "2px solid rgba(69, 69, 73, 1)",
                borderRadius: "3px",
                backgroundColor: props.isSelected
                  ? "rgba(69, 69, 73, 1)"
                  : "rgba(255, 255, 255, 0.08)",
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 1,
              }}
            >
              {props.isSelected && (
                <span
                  style={{
                    display: "block",
                    width: "5px",
                    height: "9px",
                    border: "solid white",
                    borderWidth: "0 2px 2px 0",
                    transform: "rotate(45deg)",
                    position: "absolute",
                    top: "2px",
                    left: "5px",
                  }}
                />
              )}
            </span>
          </div>
          <label style={{ marginLeft: "8px" }}>{props.label}</label>
        </div>
      </components.Option>
    );
  };

  return (
    <div>
      <div className="mt-3">
        <Select
          options={sortedOptions}
          formatOptionLabel={(e) => customFormatTagsLabel(e, "tag")}
          // formatSelectedOptionLabel={(e) => customFormatTagsLabel(e, 'option')}
          hideSelectedOptions={false}
          className="w-100"
          isMulti={true}
          value={selectedItems}
          getOptionLabel={(option) => option.productTitle}
          getOptionValue={(option) => option.productId}
          onChange={handleItemSelect}
          placeholder="Attach Products"
          closeMenuOnSelect={false}
          components={{ Option: CheckboxOption }}
          styles={customStyles}
          backspaceRemovesValue
          isDisabled={isDisabled}
          isOptionDisabled={(option) =>
            !selectedItems.some(
              (item) => item.productId === option.productId
            ) && selectedItems.length >= 7
          }
        />
      </div>
      <div className="mt-2" style={{ display: "flex", flexWrap: "wrap" }}>
        {selectedItems.map((item, index) => (
          <div
            key={index}
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            draggable
            className="m-1"
            style={{
              background: "#EEEE77",
              color: "#5D5D60",
              padding: "3px 9px",
              borderRadius: "15px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <small
              className="align-items-center"
              title={item.productTitle}
              style={{ flex: 1 }}
            >
              {item.productTitle.slice(0, 20)}
            </small>
            <IoClose
              style={{ verticalAlign: "middle", cursor: "pointer" }}
              onClick={() => handleRemoveItem(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelecteProducts;
