import React, { useState, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { db } from '../firebase-config';
import {
  getDocs,
  collection,
  orderBy,
  query,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  onSnapshot,
} from 'firebase/firestore';

import { useLocation, useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { FallingLines } from 'react-loader-spinner';
import { Cell } from 'recharts';
import { Helmet } from 'react-helmet';

const Table = () => {
  const [filterText, setFilterText] = useState('');
  const chatEndRef = useRef();
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const urlarray = location.pathname.split('/');
  const vData = atob(urlarray[urlarray.length - 1]);
  const parsedData = JSON.parse(vData);
  const vTitle = parsedData.video_title;
  const video_id = parsedData.videoId;

  // const id = videoid[1].split(':');
  // const finalid = id[1].split('}')
  // const commentid = finalid[0];
  const columns = [
    {
      name: 'Sr',
      selector: (row) => row.index,
      // width: "6%"
    },
    {
      name: 'Name',
      selector: (row) => row.uName,
      // maxWidth: "850px"
    },
    {
      name: 'Phone No',
      selector: (row) => (row.uPhone ? row.uPhone : '-'),
      // width: "150px"
    },
    {
      name: 'Message',
      selector: (row) => row.comment,
      cell: (row) => {
        return (
          <p className="p-1 pt-3" style={{ maxWidth: '250px' }}>
            {row.comment}
          </p>
        );
      },
      // width: "200px"
    },
  ];

  // const filteredData = comments.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  const navigate = useNavigate();
  const fetchData = async (video_id) => {
    setLoading(true);
    try {
      const commentRef = collection(
        db,
        'ssv13_comments',
        `v${video_id}`,
        'comments'
      );
      const q = query(commentRef, orderBy('createdAt', 'asc'));
      const snapshot = await getDocs(q);

      const commentsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setComments(commentsData);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false);
    }
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#F1F1EE',
        color: '#000',
        border: '1px solid #E0E0E0',
      },
    },
    cells: {
      style: {
        border: '1px solid #F1F1EE',
        // padding: "10px",
      },
    },
  };
  useEffect(() => {
    fetchData(video_id);
  }, [video_id]);

  if (loading) {
    return (
      <div
        className="w-100 d-grid "
        style={{ placeItems: 'center', height: '70vh' }}
      >
        <FallingLines
          color="#EEEE77"
          width="100"
          visible={true}
          ariaLabel="falling-circles-loading"
        />
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Video Comments </title>
      </Helmet>
      <div>
        <p className="d-flex align-items-center gap-2">
          <BiArrowBack
            title="back"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/admin/svideos')}
            size={20}
          />
          Comment
        </p>
      </div>
      <div className="card p-3">
        <form className="d-flex  align-items-center justify-content-beetween">
          <div>
            <p className="mt-2">Video : {vTitle}</p>
          </div>
          {/* <div className="input-group m-2 ms-auto" style={{ maxWidth: "350px", marginLeft: '10px' }}>
                        <input
                            type='search'
                            placeholder="🔍 Search ..."
                            className="form-control"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                    </div> */}
        </form>
        <DataTable
          className="card mt-3"
          columns={columns}
          data={comments?.map((item, index) => ({ ...item, index }))}
          pagination
          responsive
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default Table;
