import React, { useEffect, useState, useRef } from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import SelecteProducts from "../upload/SelecteProducts";
import axios from "axios";
import { useSelector } from "react-redux";
import { IoClose, IoColorPaletteOutline } from "react-icons/io5";
import { ChromePicker } from "react-color";
import { toast } from "react-toastify";
import { allApiInstance, pdpApiInstance } from "../../axiosInstances";
import Select from "react-select";
const Reviewdetails = ({
  el,
  obj,
  tab,
  colorPickereRef,
  colorPickereRef2,
  setShowTranslation,
  showTranslation,
  handleTranslateClick,
}) => {
  const [inputFields, setInputFields] = useState({
    videoTitle: "",
    videoDescription: "",
    ctaBtn: "",
    imageurl: "",
    buy_btn: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileItm, setSelectedFileItm] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  // Define color states
  const [bk_color_buy_btn, setColor] = useState("#ffffff");
  const [front_color_buy_btn, setColor2] = useState("#ffffff");

  // Define file input ref
  const fileInputRef = useRef(null);

  const handleInputChangeAllFields = (e) => {
    const { name, value } = e.target;
    setInputFields({
      ...inputFields,
      [name]: value,
    });
  };

  const handleColorChange = (newColor) => {
    if (newColor && newColor.hex) {
      setColor(newColor.hex);
    }
  };

  const handleColorChange2 = (newColor) => {
    if (newColor && newColor.hex) {
      setColor2(newColor.hex);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFileItm(file);
      setSelectedFile({
        name: file.name,
        size: (file.size / 1024).toFixed(2), // size in KB
      });
      // Create a preview URL for the selected image
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setImagePreview(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const clearSelection = () => {
    setSelectedFile(null);
    setImagePreview(null);
    fileInputRef.current.value = null; // Clear the file input value
  };

  const popover = (
    <Popover ref={colorPickereRef} style={{ margin: "0", padding: "0" }}>
      <Popover.Body>
        <ChromePicker color={bk_color_buy_btn} onChange={handleColorChange} />
      </Popover.Body>
    </Popover>
  );

  const popover2 = (
    <Popover ref={colorPickereRef2} style={{ margin: "0", padding: "0" }}>
      <Popover.Body>
        <ChromePicker
          color={front_color_buy_btn}
          onChange={handleColorChange2}
        />
      </Popover.Body>
    </Popover>
  );

  // const getProducts = async () => {
  //   try {
  //     await allApiInstance
  //       .get(
  //         `/getProductsByBrandId?brandId=${user.brand_id}`
  //       )
  //       .then((res) => {
  //         if (res.data.products) {
  //           setProducts(res.data.products);
  //         } else {
  //           setProducts([]);
  //         }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   getProducts();
  // }, []);

  const apiCall = async (coverImg) => {
    try {
      await pdpApiInstance
        .put(
          `/api/moderator/video/${obj.id}`,
          {
            status: obj.status, /// valid status: notreviewed, approved, rejected, published, unpublished
            isDeleted: false,
            overlayCtaButton: inputFields.ctaBtn,
            overlayTextColor: bk_color_buy_btn,
            overlayBackgroundColor: front_color_buy_btn,
            coverImage: coverImg,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token")
                ? localStorage.getItem("token")
                : "",
            },
          }
        )
        .then((res) => {
          if (res.data) {
            toast.success("Updated");
          }
        })
        .catch((err) => {
          toast.err("Something went wrong");
        });
    } catch (error) {
      toast.err("Something went wrong");
    }
  };
  const handleSubmitUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (selectedFile) {
      const res = await uploadCoverImg();

      if (res.success) {
        apiCall(res.image);
      } else {
        toast.error("Error during uplaod image");
      }
    } else {
      apiCall(obj.coverImage);
    }
  };
  const user = useSelector((state) => state.auth.user);
  const uploadCoverImg = async () => {
    try {
      const formData = new FormData();
      formData.append("path", obj.assetKey.split("/")[0]);
      formData.append("file", selectedFileItm);
      return await pdpApiInstance
        .post(`api/review/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data) {
            console.log("Image uploaded");
            return {
              image: res.data.metadata.name,
              success: true,
            };
          }
        });
    } catch (error) {
      return {
        image: null,
        success: false,
      };
    }
  };

  const setVideoSettingsData = async () => {
    try {
      await allApiInstance
        .get(`/getSwirlShortCodeInfoByBrandId?brandId=${user.brand_id}`)
        .then((res) => {
          if (res.data) {
            const brandData = res.data.sscResponse.brandCustomization;
            setInputFields({
              ...inputFields,
              imageurl: `${cdnUrl}${obj.coverImage}`,
              videoTitle: obj.title,
              videoDescription: obj.summary,
              ctaBtn: obj.overlayCtaButton,
            });
            setProducts(obj.review.product.name);
            setColor(brandData.bk_color_buy_btn);
            setColor2(brandData.front_color_buy_btn);
          }
        });
    } catch (error) { }
  };
  const cdnUrl = process.env.REACT_APP_S3_CDN
  useEffect(() => {
    if (obj.overlayBackgroundColor && obj.overlayTextColor) {
      setInputFields({
        ...inputFields,
        imageurl: `${cdnUrl}${obj.coverImage}`,
        videoTitle: showTranslation ? obj.translation.title : obj.title,
        videoDescription: showTranslation
          ? obj.translation.summary
          : obj.summary,
        ctaBtn: obj.overlayCtaButton,
      });
      setProducts(obj.review.product.name);
      setColor(obj.overlayBackgroundColor);
      setColor2(obj.overlayTextColor);
    } else {
      setVideoSettingsData();
    }
  }, [obj, tab, showTranslation]);

  return (
    <div style={{ height: "68vh", overflowY: "auto", overflowX: "hidden" }}>
      <Form className="p-2" onSubmit={handleSubmitUpload}>
        <label className="mt-3 titles-uploadvideo">
          Title <sup className="text-danger">*</sup>
        </label>

        <Form.Control
          type="text"
          className="mt-2"
          value={inputFields.videoTitle}
          dir={
            obj.audioLanguage === "Arabic - Saudi Arabia"
              ? !showTranslation
                ? "rtl"
                : "ltr"
              : "ltr"
          }
          placeholder="Enter video title"
          name="videoTitle"
          onChange={handleInputChangeAllFields}
          required
          disabled
        />
        <label className="mt-3 titles-uploadvideo">Description</label>
        <Form.Control
          as="textarea"
          className="mt-2"
          rows={3}
          dir={
            obj.audioLanguage === "Arabic - Saudi Arabia"
              ? !showTranslation
                ? "rtl"
                : ""
              : ""
          }
          value={inputFields.videoDescription}
          placeholder="Enter video description"
          name="videoDescription"
          onChange={handleInputChangeAllFields}
          disabled
        />
        <label className="mt-3 titles-uploadvideo">Overlay CTA button</label>
        <div className="row mt-2">
          <div className="col-4">
            <input
              type="text"
              value={inputFields.ctaBtn}
              placeholder="Enquire now"
              className="form-control"
              name="ctaBtn"
              onChange={handleInputChangeAllFields}
            />
          </div>
          <div className="col-4">
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={popover}
              rootClose
            >
              <div
                className="input-group mb-3"
                style={{
                  border: "1px solid rgba(209, 209, 210, 1)",
                  borderRadius: "8px",
                }}
              >
                <span
                  className="input-group-text"
                  style={{ backgroundColor: "white", border: "none" }}
                >
                  <span
                    className="color_badge"
                    style={{ backgroundColor: bk_color_buy_btn }}
                  ></span>
                </span>
                <input
                  type="text"
                  value={bk_color_buy_btn}
                  className="form-control pointer_cursor"
                  style={{ border: "none" }}
                  readOnly
                />
                <span
                  className="input-group-text pointer_cursor"
                  style={{ backgroundColor: "white", border: "none" }}
                >
                  <IoColorPaletteOutline />
                </span>
              </div>
            </OverlayTrigger>
          </div>
          <div className="col-4">
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={popover2}
              rootClose
            >
              <div
                className="input-group mb-3"
                style={{
                  border: "1px solid rgba(209, 209, 210, 1)",
                  borderRadius: "8px",
                }}
              >
                <span
                  className="input-group-text"
                  style={{ backgroundColor: "white", border: "none" }}
                >
                  <span
                    className="color_badge"
                    style={{ backgroundColor: front_color_buy_btn }}
                  ></span>
                </span>
                <input
                  type="text"
                  value={front_color_buy_btn}
                  className="form-control pointer_cursor"
                  style={{ border: "none" }}
                  readOnly
                />
                <span
                  className="input-group-text pointer_cursor"
                  style={{ backgroundColor: "white", border: "none" }}
                >
                  <IoColorPaletteOutline />
                </span>
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div>
          <label className="titles-uploadvideo">Attached Product</label>

          <div
            className="input-group mb-1 mt-2"
            style={{
              border: "1px solid rgba(209, 209, 210, 1)",
              borderRadius: "8px",
            }}
          >
            <Select
              // type="text"
              // value={products}
              placeholder="Select Products"
              className="w-100"
              style={{ border: "none" }}
              isDisabled
            />
          </div>

          <div
            className="row ms-1 badge badge-success"
            style={{
              color: "rgba(93, 93, 96, 1)",
              backgroundColor: "rgba(238, 238, 119, 1)", // Set background color to yellow
              borderRadius: "50px",
              padding: "5px 10px",
              zIndex: 999,
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            {/* {products?.name} */}
            {products}
          </div>
        </div>
        <label className="mt-3 titles-uploadvideo">Cover Image</label>
        <div
          className="input-group mb-1 mt-2"
          style={{
            border: "1px solid rgba(209, 209, 210, 1)",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <input
            type="text"
            className="form-control"
            style={{ border: "none" }}
            placeholder={
              selectedFile
                ? `${selectedFile.name} (${selectedFile.size} KB)`
                : "No file chosen"
            }
            onClick={() => fileInputRef.current.click()}
            readOnly
          />
          <button
            className="btn"
            type="button"
            style={{
              border: "none",
              backgroundColor: "rgba(232, 232, 232, 1)",
              borderRadius: "8px",
              padding: "0 13px",
              fontSize: "12px",
              margin: "5px",
            }}
            onClick={() => fileInputRef.current.click()}
          >
            Browse
          </button>
        </div>
        <small
          style={{
            fontSize: "12px",
            lineHeight: "16px",
            color: "rgba(69, 69, 73, 1)",
          }}
        >
          File format: .png, .jpg, .webp
        </small>
        {inputFields.imageurl.length > 0 && !selectedFile ? (
          <div className="card p-1 mt-2">
            <div className="d-flex w-100">
              <img
                style={{ height: "50px", width: "35px", borderRadius: "3px" }}
                src={imagePreview || inputFields.imageurl}
                alt="Selected file preview"
              />
              <div className="d-flex flex-column ps-2 pt-1">
                {selectedFile && (
                  <small className="text-muted">{selectedFile.name}</small>
                )}
                {selectedFile && (
                  <small style={{ color: "rgb(162, 162, 164)" }}>
                    {`${selectedFile.size} KB`} <b>.</b>{" "}
                    <span className="text-success">Uploaded</span>
                  </small>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="card p-1 mt-2">
            <div className="d-flex w-100">
              <img
                style={{ height: "50px", width: "35px", borderRadius: "3px" }}
                src={imagePreview || inputFields.imageurl}
                alt="Selected file preview"
              />
              <div className="d-flex flex-column ps-2 pt-1">
                {selectedFile && (
                  <small className="text-muted">{selectedFile.name}</small>
                )}
                {selectedFile && (
                  <small style={{ color: "rgb(162, 162, 164)" }}>
                    {`${selectedFile.size} KB`} <b>.</b>{" "}
                    <span className="text-success">Uploaded</span>
                  </small>
                )}
              </div>
              <div className="ms-auto" onClick={clearSelection}>
                <IoClose className="pointer_cursor" />
              </div>
            </div>
          </div>
        )}

        <div>
          <button className="btn btn-dark mt-3" type="submit">
            Save
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Reviewdetails;
