import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Createreview from "../components/video-review/Createreview";
import Reviewtable from "../components/video-review/Reviewtable";
import Modrator from "../components/video-review/Modrator";
import Setting from "../components/video-review/Setting";
import "./videogallery.css";
const VideoReview = ({
  fetchNotReviewedVideosCOunr,
  setReviewtab,
  reviewtab,
}) => {
  // const [tab, setTab] = useState("moderator");
  const setPersistTabActive = (reviewtab) => {
    localStorage.setItem("selected_tab_review", reviewtab);
    // alert(tab);
  };
  return (
    <>
      <Helmet>
        <title>SWIRL | Short Videos</title>
      </Helmet>
      <div>
        <div className="col">
          <h4
            className="ms-2 p-1"
            style={{ fontSize: "32px", fontWeight: "700" }}
          >
            Video UGC
          </h4>
          <div className="d-flex ">
            <ul
              className=" nav nav-tabs review"
              id=" responsive-tabs-review"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ms-3 ps-0 ${reviewtab === "moderator" ? "active" : ""
                    }`}
                  id="moderator-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#moderator"
                  type="button"
                  role="tab"
                  aria-controls="moderator"
                  aria-selected={reviewtab === "moderator"}
                  onClick={() => {
                    setReviewtab("moderator");
                    setPersistTabActive("moderator");
                  }}
                >
                  Moderator
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ms-3 ps-0 ${reviewtab === "create" ? "active" : ""
                    }`}
                  id="create-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#create"
                  type="button"
                  role="tab"
                  aria-controls="create"
                  aria-selected={reviewtab === "create"}
                  onClick={() => {
                    setReviewtab("create");
                    setPersistTabActive("create");
                  }}
                >
                  Create Video UGC Link
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${reviewtab === "review" ? "active" : ""
                    }`}
                  id="review-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#review"
                  type="button"
                  role="tab"
                  aria-controls="review"
                  aria-selected={reviewtab === "review"}
                  onClick={() => {
                    setReviewtab("review");
                    setPersistTabActive("review");
                  }}
                >
                  Video UGC List
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ms-3 ps-0 ${reviewtab === "settings" ? "active" : ""
                    }`}
                  id="settings-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#settings"
                  type="button"
                  role="tab"
                  aria-controls="settings"
                  aria-selected={reviewtab === "settings"}
                  onClick={() => {
                    setReviewtab("settings");
                    setPersistTabActive("settings");
                  }}
                >
                  Settings
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content p-2 ms-1" id="responsive-tabs-content">
            {reviewtab === "moderator" && (
              <div
                className="tab-pane fade show active h-100"
                id="moderator"
                role="tabpanel"
                aria-labelledby="moderator-tab"
              >
                <Modrator
                  fetchNotReviewedVideosCOunr={fetchNotReviewedVideosCOunr}
                />
              </div>
            )}
            {reviewtab === "create" && (
              <div
                className="tab-pane fade show active h-100"
                id="create"
                role="tabpanel"
                aria-labelledby="create-tab"
              >
                <Createreview />
              </div>
            )}
            {reviewtab === "review" && (
              <div
                className="tab-pane fade show active h-100"
                id="review"
                role="tabpanel"
                aria-labelledby="review-tab"
              >
                <Reviewtable fetchNotReviewedVideosCOunr={fetchNotReviewedVideosCOunr} />
              </div>
            )}
            {reviewtab === "settings" && (
              <div
                className="tab-pane fade show active h-100"
                id="settings"
                role="tabpanel"
                aria-labelledby="settings-tab"
              >
                <Setting />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoReview;
