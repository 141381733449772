export const Whimsicalsms = (templateNumber, { user, selectedOption }) => {
  switch (templateNumber) {
    case 1:
      return `
                Curious minds want to know! 🕵️‍♂️ Was it love at first use? 💖 Share your thoughts on ${selectedOption?.label} in a quirky video review:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value} 🎉
            `;
    case 2:
      return `
                Your feedback is like sprinkles on our cupcake! 🧁🍭 Leave a sweet review on ${selectedOption?.label} here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value} 🎬✨
            `;
    case 3:
      return `
                Did your new find spark joy? 🌈🎁 Don’t keep it a secret! Record a quick video review on ${selectedOption?.label}:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value} 🎥🎉
            `;
    case 4:
      return `
                Was your new ${selectedOption?.label} a total game-changer? 🎮🌟 Let us know in a fun video review! Share your thoughts here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value} 🚀
            `;
    case 5:
      return `
                Is your new gadget a magic wand or a wizard's hat? 🧙✨ Cast your review spell on ${selectedOption?.label} in a fun video here:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value} 🔮🦄
            `;
    case 6:
      return `
                Did your purchase of ${selectedOption?.label} sprinkle a little magic in your life? 🧚‍♂️ Share the enchantment in a video review:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value} ✨🌈
            `;
    case 7:
      return `
                Your new ${selectedOption?.label} a superhero in disguise? 🦸‍♂️🦸‍♀️ Tell us its superpowers in a quirky video review:${process.env.REACT_APP_REVIEW_CLIENT_URL}${selectedOption?.value} 💥🌟
            `;
  }
};
