import React, { useEffect, useRef, useState } from "react";

import SelecteProducts from "../upload/SelecteProducts";
import { BiTrash } from "react-icons/bi";
import { BsFillStarFill } from "react-icons/bs";
import UplaodFileReview from "./UploadReview.js";
import UplaodFile from "../upload/UplaodFIle";
import UplaodfileReview from "./UploadReview.js";
import { pdpApiInstance } from "../../axiosInstances.js";
import Select, { components } from "react-select";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

const Reviewnewvideo = ({ fetchData2, show, handleClose, productoption }) => {
  const modalRef = useRef(null);
  const [moderatingObj, setModeratingObj] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [videoFIle, setvideoFIle] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleChange = (selected) => {
    setSelectedItems(selected);
  };
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const clearPreview = () => {
    setPreviewUrl(null);
    setIsPlaying(true);
    setvideoFIle(null);
  };
  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (show) {
        modalElement.classList.add("slide-down");
        modalElement.classList.remove("slide-up");
      } else {
        modalElement.classList.add("slide-up");
        modalElement.classList.remove("slide-down");
      }
    }
  }, [show, handleClose]);

  const handleCloseed = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.classList.add("slide-up");
      setPreviewUrl("");
      setSelectedItems(null);
      setvideoFIle(null);
      setTimeout(() => {
        setIsClosing(false);
        handleClose();
      }, 500);
    }
  };
  const productNames = productoption.map((product) => ({
    label: product.label,
    value: product.value,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      // height: "10px",
      // overflow: "hidden",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      backgroundColor: state.isSelected
        ? "white"
        : state.isFocused
          ? "white"
          : null,
      color: "rgba(162, 162, 164, 1)",
      padding: "15px",
      zIndex: 5,
      borderBottom: "1px solid #ddd", // Add horizontal line
      "&:last-child": {
        borderBottom: "none",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
      maxHeight: "300px",
      overflow: "hidden",
    }),
  };

  function removeCharacter(inputString) {
    // Replace all characters that are not letters, numbers, or spaces
    return inputString.replace(/[^a-zA-Z0-9 ]/g, "");
  }

  const submitVideo2 = async (assetKey, duration) => {
    try {
      const response = await pdpApiInstance.post(
        "api/reviews/video",
        {
          reviewId: selectedItems.value,
          assetKey: assetKey,
          duration: duration,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      return response.data.data.pipelineId;
    } catch (error) {
      console.error("Video submission error:", error);
      // alert(error)
      setLoading(false);
      throw error;
    }
  };
  const uploadFile = async () => {
    const newFileName = removeCharacter(previewUrl.name);
    const newFile = new File([previewUrl], newFileName, {
      type: previewUrl.type,
    });

    const formData = new FormData();
    formData.append("file", newFile);

    const videoUrl = URL.createObjectURL(previewUrl);
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;

    const getVideoDuration = () => {
      return new Promise((resolve) => {
        videoElement.onloadedmetadata = () => {
          const duration = videoElement.duration;
          localStorage.setItem("duration_of_last_video", duration);
          resolve(duration);
        };
      });
    };

    try {
      // Wait for video duration before uploading
      const duration = await getVideoDuration();

      setDuration(duration ? duration : 0);

      const response = await pdpApiInstance.post(
        "api/review/video",
        formData
      );

      return response.data.metadata.name;
    } catch (error) {
      console.error("File upload error:", error);
      setLoading(false);
      throw error;
    }
  };

  const handleSubmitVideo = async () => {
    try {
      setLoading(true);
      const assetKey = await uploadFile();
      const pipelineId = await submitVideo2(
        assetKey,
        localStorage.getItem("duration_of_last_video")
      );
      if (pipelineId) {
        setTimeout(() => {
          fetchData2();
          handleCloseed();
          setLoading(false);
          localStorage.removeItem("duration_of_last_video");
        }, 2000);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoading(false);
      console.log(error);
    }
  };
  if (!show && !isClosing) return null;

  return (
    <div className="review-modal-overlay">
      <div className="review-modal-content" ref={modalRef}>
        <button className="review-modal-close" onClick={handleCloseed}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 44 44"
            fill="none"
          >
            <rect width="44" height="44" rx="22" fill="white" />
            <g clipPath="url(#clip0_483_11622)">
              <path
                d="M22 20.586L26.95 15.636L28.364 17.05L23.414 22L28.364 26.95L26.95 28.364L22 23.414L17.05 28.364L15.636 26.95L20.586 22L15.636 17.05L17.05 15.636L22 20.586Z"
                fill="#454549"
              />
            </g>
            <defs>
              <clipPath id="clip0_483_11622">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div className="row" style={{ overflow: "auto", height: "100%" }}>
          <div
            className="col-8"
            style={{
              pointerEvents: loading ? "none" : "",
              opacity: loading ? "0.6" : "1",
            }}
          >
            <p className="ps-1" style={{ fontSize: "16px" }}>
              Upload Video
            </p>
            <UplaodfileReview
              setPreviewUrl={setPreviewUrl}
              previewUrl={previewUrl}
              videoFIle={videoFIle}
              setvideoFIle={setvideoFIle}
              clearPreview={clearPreview}
            />
            <p className="ps-1 mt-2" style={{ fontSize: "16px" }}>
              Connect Product
            </p>

            <Select
              options={productoption}
              hideSelectedOptions={false}
              value={selectedItems}
              onChange={handleChange}
              getOptionLabel={(option) => option.label} // Use label for display
              getOptionValue={(option) => option.value}
              backspaceRemovesValue
              isClearable={true}
              styles={customStyles}
            />
            <button
              disabled={
                (selectedItems?.label && previewUrl) || loading ? false : true
              }
              className="btn btn-dark mt-3"
              onClick={handleSubmitVideo}
            >
              Upload {loading && <FaSpinner className="spinning" />}
            </button>
          </div>

          <div className="col-4 h-100">
            <div
              className="card h-100"
              style={{
                padding: "20px 80px 32px",
                borderRadius: "20px",
                overflow: "auto",
                backgroundColor: "#F8F8FF",
                borderRadius: "10px",
              }}
            >
              <div className="h-100">
                <div
                  className="h-100"
                  style={{
                    position: "relative",
                    maxWidth: "100%",
                    overflow: "hidden",
                    borderRadius: "20px",
                  }}
                >
                  {videoFIle ? (
                    <video
                      ref={videoRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        // objectFit: "cover",
                        //   inputFields.videoType == "portrait" ? "cover" : "",
                        backgroundColor: "black",
                      }}
                      src={videoFIle}
                      autoPlay
                      loop
                      muted
                    />
                  ) : (
                    <div
                      className="h-100 w-100"
                      style={{ backgroundColor: "#D9D9D9" }}
                    ></div>
                  )}

                  {previewUrl && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: "10px",
                        color: "white",
                      }}
                      onClick={clearPreview}
                    >
                      <span
                        style={{
                          height: "34px",
                          width: "34px",
                          backgroundColor: "#a2a2a4",
                          cursor: "pointer",
                          borderRadius: "50%",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <BiTrash className=" float-end" />
                      </span>
                    </div>
                  )}

                  {videoFIle && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        color: "white",
                      }}
                      onClick={togglePlayPause}
                    >
                      <div
                        style={{
                          height: "44px",
                          width: "44px",
                          display: "grid",
                          placeItems: "center",
                          borderRadius: "50%",
                          position: "relative", // Make sure the container has position relative
                        }}
                      >
                        {/* The icon will be displayed on hover */}
                        <div
                          style={{
                            position: "absolute",
                            display: isPlaying ? "none" : "flex", // Hide when playing
                            fontSize: "40px",
                            color: "#09121F",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <i className="ri-play-circle-fill"></i>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            display: isPlaying ? "flex" : "none", // Show when playing
                            fontSize: "40px",
                            color: "#09121F",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <i className="ri-pause-circle-fill"></i>
                        </div>
                        {/* Icon becomes visible on hover */}
                        <div
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            display: "grid",
                            placeItems: "center",
                            borderRadius: "50%",

                            opacity: 0,
                            transition: "opacity 0.3s ease",
                            zIndex: 1,
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.opacity = 1;
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.opacity = 0;
                          }}
                        >
                          <div
                            style={{
                              fontSize: "40px",

                              transition: "opacity 0.3s ease",
                            }}
                          >
                            {isPlaying ? (
                              <i className="ri-pause-circle-fill"></i>
                            ) : (
                              <i className="ri-play-circle-fill"></i>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviewnewvideo;
