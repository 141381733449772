import React, { useState, useCallback } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FaArrowUp } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
const Upload = ({
  setCsvData,
  previewUrl,
  setPreviewUrl,
  clearPreview,
  loadingPercent,
  setvideoFIle,
}) => {
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.warning('choose csv file');
    } else {
      const file = acceptedFiles[0];
      setPreviewUrl(file);

      const preview = URL.createObjectURL(file);
      setvideoFIle(preview);
      // Read and parse the CSV file using XLSX
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        // Do something with the JSON data
        // For example, you can set it to a state
        setCsvData(json);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: false,
    accept: {
      'text/csv': ['.csv', '.CSV'],
    },
    multiple: false,
  });

  return (
    <>
      <div>
        <div
          className="mt-2"
          style={{
            border: '2px dashed #eeeeee',
            borderRadius: '4px',
            padding: '80px',
          }}
        >
          <div {...getRootProps()} style={dropzoneStyles} className="mt-2">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div className="d-flex flex-column ">
                <FaArrowUp className="m-auto " />
                <small className="mt-2">
                  Drag & drop your files here or{' '}
                  <u style={{ color: '#334499' }}>Browse</u>
                </small>
              </div>
            )}
          </div>
        </div>
        {previewUrl && (
          <>
            <div className="card p-1 mt-2">
              <div className="d-flex w-100">
                <img
                  style={{ height: '50px', width: '35px', borderRadius: '3px' }}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3CoT43dhDs24KOBENldI_y_OMEpP430XknIzXNGfp2Q&s"
                />
                <div className="d-flex flex-column ps-2 pt-1">
                  <small className="text-muted">{previewUrl?.name}</small>
                  <small style={{ color: 'rgb(162, 162, 164)' }}>
                    {`${previewUrl?.size / 1024 >= 1000 ? (previewUrl?.size / (1024 * 1024)).toFixed(2) + ' MB' : (previewUrl.size / 1024).toFixed(2) + ' KB'}`}{' '}
                    <b>.</b> <span className="text-success">Uploaded</span>
                  </small>
                </div>
                <div className="ms-auto" onClick={clearPreview}>
                  <IoClose className="pointer_cursor" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
const dropzoneStyles = {
  textAlign: 'center',
  cursor: 'pointer',
};

export default Upload;
