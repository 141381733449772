import axios from 'axios';

// Create an instance for general API
// const loginApiInstance = axios.create({
//     baseURL: 'https://api.example.com',
//     timeout: 10000, // 10 seconds timeout
//     headers: { 'Content-Type': 'application/json' }
// });
const allApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_LOGIN,
  timeout: 10000, // 10 seconds timeout
});

// Create another instance for a different API
const pdpApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_PDP,
  timeout: 15000, // 15 seconds timeout
  // headers: { Authorization: localStorage.getItem('token') ? localStorage.getItem('token') : '' },
});

export { pdpApiInstance, allApiInstance };
