import React, { useState, useCallback } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FaArrowUp } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

function UplaodFile({
  videoFIle,
  previewUrl,
  setPreviewUrl,
  clearPreview,
  loadingPercent,
  setvideoFIle,
}) {
  // const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
  //     // Handle rejected files if needed
  //     if (rejectedFiles.length > 0) {
  //         // You can display an error message to the user here
  //     }

  //     if (acceptedFiles.length > 0) {
  //         const file = acceptedFiles[0]; // Assuming single file upload for preview

  //         // Validate file type
  //         if (!file.type.startsWith('video/')) {
  //             console.error('Invalid file type. Only videos allowed.');
  //             return;
  //         }
  //         // const sdf = URL.createObjectURL(file)

  //         // setPreviewUrl(file)
  //         const reader = new FileReader();

  //         reader.onabort = (event) => {
  //             console.log('file reading was aborted')
  //         }

  //         reader.onerror = (event) => {
  //             console.log('file reading has failed')
  //         }
  //         reader.onload = (event) => {
  //             console.log(event.target.result);
  //             setPreviewUrl(event.target.result);
  //         };
  //         reader.readAsDataURL(file);
  //     } else {
  //         setPreviewUrl(null); // Clear preview if no files are dropped
  //     }
  // },

  //    []);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setPreviewUrl(file);
    const preview = URL.createObjectURL(file);
    setvideoFIle(preview);
  };

  // const handleDrop = useCallback((acceptedFiles) => {
  //     const formData = new FormData();
  //     acceptedFiles.forEach(file => {
  //         formData.append('files', file);
  //     });

  //     fetch('YOUR_BACKEND_ENDPOINT', {
  //         method: 'POST',
  //         body: formData,
  //     })
  //         .then(response => response.json())
  //         .then(data => console.log(data))
  //         .catch(error => console.error(error));
  // }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: false,
    accept: { 'video/*': ['.mp4', '.MP4'] },
    multiple: false,
  });

  return (
    <>
      <div
        className="mt-2"
        style={{
          border: '2px dashed #eeeeee',
          borderRadius: '4px',
          padding: '20px',
        }}
      >
        <div {...getRootProps()} style={dropzoneStyles} className="mt-2">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div className="d-flex flex-column ">
              <FaArrowUp
                className="m-auto "
                style={{ color: 'rgba(51, 68, 153, 1)' }}
              />
              <small className="mt-2" style={{ fontSize: '14px' }}>
                Drag & drop your files here{' '}
                <span style={{ fontSize: '12px' }}>
                  or <u style={{ color: ' rgba(51, 68, 153, 1)' }}>Browse</u>
                </span>
              </small>
              <small
                style={{ fontSize: '12px', color: 'rgba(162, 162, 164, 1)' }}
              >
                Supported format: .mp4, Video ration: 9:16, 16:9{' '}
              </small>
              <small
                style={{ fontSize: '12px', color: 'rgba(162, 162, 164, 1)' }}
              >
                {' '}
                Video length: 180 sec max, Maximum size: 150MB
              </small>
            </div>
          )}
        </div>
        {previewUrl && (
          <>
            <div className="card p-1 mt-2">
              <div className="d-flex w-100">
                <video
                  src={videoFIle}
                  style={{ height: '50px', width: '35px', borderRadius: '3px' }}
                ></video>
                {/* <img style={{ height: "50px", width: "35px", borderRadius: "3px" }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3CoT43dhDs24KOBENldI_y_OMEpP430XknIzXNGfp2Q&s' /> */}
                <div className="d-flex flex-column ps-2 pt-1">
                  <small
                    className="text-muted"
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '19vw',
                    }}
                  >
                    {previewUrl?.name}
                  </small>
                  <small style={{ color: 'rgb(162, 162, 164)' }}>
                    {`${previewUrl?.size / 1024 >= 1000 ? (previewUrl?.size / (1024 * 1024)).toFixed(2) + ' MB' : (previewUrl.size / 1024).toFixed(2) + ' KB'}`}{' '}
                    <b>.</b> <span className="text-success">Uploaded</span>
                  </small>
                </div>
                <div className="ms-auto" onClick={clearPreview}>
                  <IoClose className="pointer_cursor" />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <ProgressBar
                animated={parseInt(loadingPercent) < 100 ? true : false}
                variant={parseInt(loadingPercent) < 100 ? 'primary' : 'success'}
                now={loadingPercent}
                label={
                  parseInt(loadingPercent) < 100
                    ? `${loadingPercent}%`
                    : 'uploaded'
                }
              />
            </div>
          </>
        )}
        {/* {previewUrl && (
                    <div className='card p-1 mt-3'>
                        <video src={previewUrl} controls width="100%" />
                    </div>
                )} */}
      </div>
    </>
  );
}

const dropzoneStyles = {
  textAlign: 'center',
  cursor: 'pointer',
};

export default UplaodFile;
