// import React from 'react'

// const Summary = ({ obj }) => {
//     return (
//         <div className='position-relative'>
//             {!obj.audioLanguage?.includes('English') &&
//                 < div
//                     className='badge badge-success'
//                     style={{
//                         position: 'absolute',
//                         top: '-20px',
//                         right: '10px',
//                         color: 'black',
//                         backgroundColor: 'white',
//                         border: '1px solid black',
//                         borderRadius: '50px',
//                         padding: '10px 20px',

//                         fontSize: '13px',
//                         fontWeight: 'bold',
//                     }}
//                 >
//                     <span><i className="ri-translate-2" ></i> Translate to English</span>
//                 </div>}
//             <div className='pt-3' style={{ color: "rgba(69, 69, 73, 1)", fontSize: "18px" }} dir={obj.audioLanguage === 'Arabic - Saudi Arabia' ? 'rtl' : ""}>

//                 <p >{obj.summary ? obj.summary : <span className='text-muted text-center ps-2'>No Summary</span>}</p>
//             </div>
//         </div>
//     )
// }

// export default Summary
import React, { useState } from "react";
import { RiTranslate2 } from "react-icons/ri";

const Summary = ({
  obj,
  handleTranslateClick,
  showTranslation,
  setShowTranslation,
}) => {
  return (
    <div className="position-relative">
      <div
        className="pt-4"
        style={{
          color: "rgba(69, 69, 73, 1)",
          fontSize: "18px",
          maxHeight: "25vh",
          overflow: "auto",
        }}
        dir={
          obj.audioLanguage === "Arabic - Saudi Arabia"
            ? !showTranslation
              ? "rtl"
              : ""
            : ""
        }
      >
        <p>
          {showTranslation
            ? obj?.translation?.summary || (
                <span className="text-muted text-center ps-2" dir="ltr">
                  No Translation Available
                </span>
              )
            : obj?.summary || (
                <span className="text-muted text-center ps-2">No Summary</span>
              )}
        </p>
      </div>
    </div>
  );
};

export default Summary;
