import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

import { useSelector } from 'react-redux';
import { setLoading } from '../../redux/apiSlice';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FallingLines } from 'react-loader-spinner';
// import { Form, InputGroup } from 'react-bootstrap';
import { InputGroup, Form } from 'react-bootstrap'; // Ensure these are imported
import { allApiInstance } from '../../axiosInstances';
const initialVideosToShow = 12;

const EditVideos = ({
  tab,
  setTab,
  setSelectedVideos,
  loadingOverlay,
  selectedVideos,
  fetchData,
  fetchData2,
  videoData,
  filterProduct,
  setFilterProduct,
  queryText,
  isPortrait,
  setQueryText,
  viewMoreLandscape,
  viewMorePortrait,
  setPortraitOffset,
  setLandscapeOffset,
}) => {
  // const [loadingOverlay, setLoadingOverlay] = useState(false);
  // const [isPlaying, setIsPlaying] = useState(new Array(selectedVideos.length).fill(false));
  // const navigate = useNavigate();
  // const [videosToShow, setVideosToShow] = useState(initialVideosToShow);
  // const handleViewMore = () => {
  //     setVideosToShow(prev => prev + 12);
  // };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const playlistDataId = queryParams.get('playlistDataId');

  const [products, setProducts] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const searchParams = new URLSearchParams(location.search);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 220,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
  };
  useEffect(() => {
    fetchDataproduct();
  }, []);

  const fetchDataproduct = async () => {
    // setLoading(true) // Use setLoading here
    try {
      const [productsRes] = await Promise.all([
        allApiInstance.get(
          `/getProductsByBrandId?brandId=${user.brand_id}`
        ),
      ]);
      setProducts(productsRes.data.products || []);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false); // Use setLoading here
    }
  };

  const toggleVideoSelection = (video) => {
    setSelectedVideos((prevSelected) => {
      if (prevSelected.includes(video)) {
        return prevSelected.filter((v) => v !== video);
      } else {
        return [...prevSelected, video];
      }
    });
  };

  const formatVideoDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  return (
    <div>
      <form className="d-flex justify-content-end mb-1">
        <div style={{ zIndex: '5' }}>
          <Select
            className="justify-content-end"
            options={products}
            getOptionLabel={(option) => option.productTitle}
            getOptionValue={(option) => option.productId}
            placeholder="Search by product"
            styles={customStyles}
            isClearable={true}
            onChange={async (e) => {
              setFilterProduct(e);

              if (e === null) {
                // setSelectedVideos(null)
                await fetchData('clear');
              }
            }}
          />
        </div>
        <div
          className="input-group"
          style={{ maxWidth: '230px', marginLeft: '10px' }}
        >
          {/* <input type='search' placeholder="🔍 Search video..." className="form-control" onChange={(e) => {
        setPortraitOffset(1);
        setLandscapeOffset(1);
        setQueryText(e.target.value);
    }} /> */}
          <InputGroup
            className=""
            style={{
              border: '1px solid rgba(209, 209, 210, 1)',
              borderRadius: '7px',
            }}
          >
            <InputGroup.Text
              id="basic-addon1"
              style={{ backgroundColor: 'white', border: 'none' }}
            >
              <i className="ri-search-line"></i>
            </InputGroup.Text>
            <Form.Control // Corrected this line
              style={{ border: 'none' }}
              type="search"
              placeholder=" Search video..."
              onChange={(e) => {
                // setPortraitOffset(1);
                // setLandscapeOffset(1);
                setQueryText(e.target.value);
              }}
            />
          </InputGroup>
        </div>
        {/* <InputGroup className="mb-3" style={{ border: "1px solid rgba(209, 209, 210, 1)", borderRadius: "7px" }}>
                    <InputGroup.Text id="basic-addon1" style={{ backgroundColor: "white", border: "none" }}><i class="ri-search-line"></i></InputGroup.Text>
                    <Form.control
                        style={{ border: 'none' }}
                        type='search'
                        placeholder=" Search video..."
                        onChange={(e) => {
                            setPortraitOffset(1);
                            setLandscapeOffset(1);
                            setQueryText(e.target.value);
                        }}
                    />

                </InputGroup> */}
      </form>

      <div style={{ height: 'calc(100vh - 376px)', overflowY: 'auto' }}>
        {loadingOverlay && (
          <div className="edit col-5">
            <div className="loader_comp_main_div">
              <FallingLines
                color="#EEEE77"
                width="100"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            </div>
          </div>
        )}{' '}
        {videoData.length === 0 &&
          !(filterProduct?.productId || queryText.length > 0) ? (
          <>
            <div
              className="mt-2"
              style={{ textAlign: 'center', padding: '50px' }}
            >
              <h2 style={{ fontSize: '24px', fontWeight: '450' }}>
                Ready to make your debut on the digital stage?
              </h2>
              <h1 style={{ fontSize: '32px', fontWeight: '700' }}>
                Let's hit upload and unveil your masterpiece to the world!
              </h1>

              <button
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  color: '#fff',
                  backgroundColor: '#000',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setTab('Upload')}
              >
                Upload video
              </button>
            </div>
          </>
        ) : (
          <>
            {videoData.length === 0 &&
              (filterProduct?.productId || queryText.length > 0) ? (
              <>
                <div className="container container_no_videos text-center my-5">
                  <div className="display-6">Nothing found!</div>
                  <div className="lead">
                    Looks like your search went on a video vacation
                  </div>
                </div>
              </>
            ) : (
              <>
                {isPortrait ? (
                  <div
                    className="d-flex flex-wrap p-0  "
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px !important',
                    }}
                  >
                    {[...videoData]
                      .sort((a, b) => {
                        const aSelected = selectedVideos?.includes(a);
                        const bSelected = selectedVideos?.includes(b);
                        return bSelected - aSelected; // Moves selected videos to the top
                      })
                      .map((video, index) => (
                        <div
                          key={video.id}
                          className="col-2 mt-3"
                          style={{ position: 'relative' }}
                        >
                          <div className="p-1">
                            <label
                              className="playlist w-100"
                              style={{
                                boxShadow: '0.3px 0.3px 2px 1px #E8E8E8',
                                borderRadius: '15px',
                                borders: '5px 5px 0 5px',
                                border: selectedVideos?.includes(video)
                                  ? '5px solid #334499'
                                  : 'none',
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={selectedVideos?.includes(video)}
                                onChange={() => toggleVideoSelection(video)}
                                style={{ zIndex: '1' }}
                              // style={{
                              //     position: 'absolute',
                              //     top: '4%',
                              //     right: '8%',
                              //     backgroundColor: "rgba(255, 255,255, 0.08)",
                              //     borderRadius: '70%',
                              //     accentColor: "black",
                              //     color: "white",
                              // }}
                              />
                              <div style={{ position: 'relative' }}>
                                <img
                                  className="h-100"
                                  src={video.cover_image}
                                  style={{
                                    borderRadius: '10px 10px 0 0',
                                    objectFit: 'cover',
                                    width: '100%',
                                  }}
                                />
                                <div
                                  className=" badge position-absolute mb-4 m-1 me-2"
                                  style={{
                                    bottom: '20px',
                                    right: '2px',
                                    zIndex: '1',
                                    backgroundColor: 'rgba(23, 23, 28, 0.7)',
                                  }}
                                >
                                  {formatVideoDuration(video.video_len)}
                                </div>
                                <div
                                  className="title_video_select"
                                  style={{
                                    paddingLeft: '10px',
                                    paddingTop: '3px',
                                    paddingBottom: '6px',
                                  }}
                                >
                                  {video.video_title}
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div
                    className="d-flex flex-wrap p-0"
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px !important',
                    }}
                  >
                    {[...videoData]
                      .sort((a, b) => {
                        const aSelected = selectedVideos?.includes(a);
                        const bSelected = selectedVideos?.includes(b);
                        return bSelected - aSelected; // Moves selected videos to the top
                      })
                      .map((video, index) => (
                        <div
                          key={video.id}
                          className="col-3 mt-3"
                          style={{ position: 'relative' }}
                        >
                          <div className="p-1">
                            {/* <label className=' playlist w-100' style={{ boxShadow: '0.3px 0.3px 2px 1px #E8E8E8 ', borderRadius: '15px', borders: '5px 5px 0 5px', border: selectedVideos?.includes(video) ? '5px solid #334499' : 'none' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedVideos?.includes(video)}
                                                    onChange={() => toggleVideoSelection(video)
                                                    }

                                                // style={{
                                                //     position: 'absolute',
                                                //     top: '4%',
                                                //     right: '8%',
                                                //     backgroundColor: "rgba(255, 255,255, 0.08)",
                                                //     borderRadius: '70%',
                                                //     accentColor: "black",
                                                //     color: "white",
                                                // }}
                                                />

                                                <img className='h-100' src={video.cover_image}
                                                    style={{ borderRadius: "10px 10px 0 0", objectFit: 'cover', width: '100%' }} />

                                                <div className='title_video_select' style={{ paddingLeft: '10px', paddingTop: '3px', paddingBottom: '6px' }}>
                                                    <div className=' badge mb-5 m-1 me-2' style={{ zIndex: "1", backgroundColor: "rgba(23, 23, 28, 0.7)" }}>{formatVideoDuration(video.video_len)}</div>
                                                    {video.video_title}
                                                </div>
                                            </label> */}
                            <label
                              className="playlist w-100"
                              style={{
                                boxShadow: '0.3px 0.3px 2px 1px #E8E8E8 ',
                                borderRadius: '15px',
                                border: selectedVideos?.includes(video)
                                  ? '5px solid #334499'
                                  : 'none',
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={selectedVideos?.includes(video)}
                                onChange={() => toggleVideoSelection(video)}
                                style={{ zIndex: '1' }}
                              // style={{
                              //     position: 'absolute',
                              //     top: '4%',
                              //     right: '8%',
                              //     backgroundColor: "rgba(255, 255,255, 0.08)",
                              //     borderRadius: '70%',
                              //     accentColor: "black",
                              //     color: "white",
                              // }}
                              />
                              <div style={{ position: 'relative' }}>
                                <img
                                  className="h-100"
                                  src={video.cover_image}
                                  style={{
                                    borderRadius: '10px 10px 0 0',
                                    objectFit: 'cover',
                                    width: '100%',
                                  }}
                                />
                                <div
                                  className="badge"
                                  style={{
                                    position: 'absolute',
                                    bottom: '10px', // Adjust as needed
                                    right: '10px', // Adjust as needed
                                    zIndex: '1',
                                    backgroundColor: 'rgba(23, 23, 28, 0.7)',
                                  }}
                                >
                                  {formatVideoDuration(video.video_len)}
                                </div>
                              </div>
                              <div
                                className="title_video_select"
                                style={{
                                  paddingLeft: '10px',
                                  paddingTop: '3px',
                                  paddingBottom: '6px',
                                }}
                              >
                                {video.video_title}
                              </div>
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default EditVideos;
